import * as Yup from 'yup'

const schema = Yup.object().shape({
  Name: Yup.string().required('Name is required'),
  DOB: Yup.string().required('DOB is required'),
  SSN: Yup.string().required('SSN is required'),
  DateOfDeath: Yup.string().required('Date Of Death is required'),
  GraveyardName: Yup.string().required('Graveyard Name is required'),
  DateOfBurial: Yup.string().required('Date Of Burial is required'),
})

const funeralValidation = {
  async validator({ field }, value) {
    await schema.validateSyncAt(field, { [field]: value })
  },
}

export { funeralValidation }
