import React, { useEffect, useState } from 'react'
import { Row, Button, List, Dropdown, Input, message } from 'antd'
import {
  AimOutlined,
  DollarCircleFilled,
  BankFilled,
  SendOutlined,
  CloseOutlined,
} from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import { getAddressByZipcode, logout } from 'store/authSlice/authActions'
import { FaUserCircle } from 'react-icons/fa'
import { HiMenu } from 'react-icons/hi'
import { IoMdLogOut } from 'react-icons/io'
import { MdAccountCircle } from 'react-icons/md'
import { useHistory } from 'react-router-dom'
import { authActions, useAuthSelector } from 'store/authSlice/authReducer'

function HeaderTop(props) {
  const { onPress, toggleCollasped } = props

  const dispatch = useDispatch()
  const history = useHistory()
  const { location, user, customLocation } = useAuthSelector()
  const [zipcode, setZipcode] = useState()

  const nameCapitals =
    user?.firstname.charAt(0).toUpperCase() +
    user?.lastname.charAt(0).toUpperCase()

  useEffect(() => {
    if (location?.zipcode) {
      setZipcode(location.zipcode)
    }
  }, [location])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const move = () => {
    history.push('/')
  }
  const menuProfile = (
    <List
      min-width="100%"
      className="header-notifications-dropdown"
      itemLayout="horizontal"
    >
      <List.Item className="t-pt-2 t-px-4 t-m-0 t-pb-6">
        <div className="t-flex t-flex-col t-cursor-pointer">
          <p className="t-font-bold text-3xl t-text-primary-100 t-uppercase">
            {`${user?.firstname} ${user?.lastname}`}
          </p>
          <p className="t-font-bold text-sm">{user?.phone}</p>
          <p className="t-font-bold text-sm">{user?.email}</p>
        </div>
      </List.Item>
      <List.Item className="t-py-0 t-px-4 t-m-0">
        <div
          className="t-flex t-items-center t-space-x-3 t-cursor-pointer"
          onClick={() => history.push('/settings/account')}
        >
          <MdAccountCircle
            fontSize="1.5rem"
            className="t-text-primary-100 t-w-4"
          />
          <p>Account</p>
        </div>
      </List.Item>
      <List.Item className="t-py-1 t-px-4 t-m-0">
        <div
          className="t-flex t-items-center t-space-x-3 t-cursor-pointer"
          onClick={() => history.push('/settings/manage-payments-accounts')}
        >
          <DollarCircleFilled
            fontSize="1.5rem"
            className="t-text-primary-100 t-w-4"
          />
          <p>Payment Accounts</p>
        </div>
      </List.Item>
      <List.Item className="t-py-1 t-px-4 t-m-0">
        <div
          className="t-flex t-items-center t-space-x-3 t-cursor-pointer"
          onClick={() => history.push('/settings/manage-organization')}
        >
          <BankFilled fontSize="1.5rem" className="t-text-primary-100 t-w-4" />
          <p>My Organization</p>
        </div>
      </List.Item>
      <List.Item className="t-py-1 t-px-4 t-m-0">
        <div
          className="t-flex t-items-center t-space-x-3 t-cursor-pointer"
          onClick={() => dispatch(logout(move))}
        >
          <IoMdLogOut fontSize="1.5rem" className="t-text-primary-100 t-w-4" />
          <p>Logout</p>
        </div>
      </List.Item>
    </List>
  )

  const handleLocationChange = () => {
    if (zipcode.length === 5) {
      dispatch(getAddressByZipcode(zipcode))
    } else {
      message.error('Please enter a valid zipcode')
    }
  }

  return (
    <Row justify="space-between" className="t-flex t-items-center t-px-3">
      <Button type="link" className="desktop-toggler" onClick={toggleCollasped}>
        <HiMenu fontSize="1.7rem" className="t-text-primary-100" />
      </Button>
      <Button type="link" className="mobile-toggler" onClick={onPress}>
        <HiMenu fontSize="1.7rem" className="t-text-primary-100" />
      </Button>
      <Row>
        <div className="header-control">
          <Input
            value={zipcode}
            className="header-search"
            placeholder="Fetching location ..."
            prefix={<AimOutlined />}
            suffix={
              customLocation === false ? (
                <SendOutlined
                  className="t-cursor-pointer"
                  onClick={() => handleLocationChange()}
                />
              ) : (
                <CloseOutlined
                  onClick={() => dispatch(authActions.setCustomLocation(false))}
                />
              )
            }
            onChange={(e) => setZipcode(e.target.value)}
          />
        </div>
        <div className="t-flex t-items-center t-ml-2 t-cursor-pointer">
          <Dropdown overlay={menuProfile} trigger={['click']}>
            {false ? (
              <FaUserCircle fontSize="2rem" className="t-text-primary-100" />
            ) : (
              <div className="t-flex t-items-center t-justify-center t-bg-primary-100 t-text-white t-rounded-full t-w-10 t-h-10 t-font-bold t-tracking-wide t-shadow-lg">
                {nameCapitals}
              </div>
            )}
          </Dropdown>
        </div>
      </Row>
    </Row>
  )
}

export { HeaderTop }
