import React, { useEffect, useState } from 'react'
import {
  Row,
  Switch,
  Form,
  Checkbox,
  Button,
  Col,
  message,
  Input,
  Typography,
  // Select,
} from 'antd'
import { useEventSelector } from 'store/eventSlice/eventReducer'
import { usePlaidLink } from 'react-plaid-link'
import {
  donateByStripe,
  getBankAccount,
  getExchangeToken,
  getPlaidToken,
  getUserBanks,
} from 'store/paymentSlice/paymentActions'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { RiErrorWarningFill } from 'react-icons/ri'
import { formatAmount } from 'utils'
import * as Yup from 'yup'

const { Title, Paragraph } = Typography

const numberValidation = new RegExp('^[0-9]+$')

const schema = Yup.object().shape({
  askedAmount: Yup.string()
    .required('Required')
    .matches(numberValidation, 'Invalid amount'),
  includeEventCosts: Yup.string().required('Required'),
  // bank: Yup.string().required("Required"),
  agreeToTerms: Yup.string().required('Required'),
  splitDonation: Yup.string().required('Required'),
  splitnoOfMonths: Yup.string()
    .typeError('Required')
    .required('Required')
    .matches(numberValidation, 'Invalid amount'),
  repeatMonths: Yup.string().required('Required'),
  repeatnoOfMonths: Yup.string()
    .typeError('Required')
    .required('Required')
    .matches(numberValidation, 'Invalid amount'),
})

const bankDebitValidation = {
  async validator({ field }, value) {
    await schema.validateSyncAt(field, { [field]: value })
  },
}

const initialValues = {
  askedAmount: null,
  includeEventCosts: false,
  agreeToTerms: false,
  splitDonation: false,
  splitnoOfMonths: null,
  repeatMonths: false,
  repeatnoOfMonths: null,
  // bank: "no-bank-selected",
}

function BankDebit({ refreshEventData }) {
  const [token, setToken] = useState(null)
  const [banks, setBanks] = useState([])
  const [loading, setLoading] = useState(false)

  const [form] = Form.useForm()

  const { user, location } = useAuthSelector()
  const { event, subscriberPaymentInfo, joinedEventDetails } =
    useEventSelector()

  // Form.useWatch("bank", form);
  Form.useWatch('askedAmount', form)
  Form.useWatch('includeEventCosts', form)
  Form.useWatch('repeatMonths', form)
  Form.useWatch('splitDonation', form)

  const isSplit = form.getFieldValue('splitDonation')
  const isRepeat = form.getFieldValue('repeatMonths')

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event?.OrganizationId])

  useEffect(() => {
    form.setFieldsValue({ askedAmount: event?.CurrentAsk })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event?.CurrentAsk])

  const onSuccess = async (PUBLIC_TOKEN, metadata) => {
    try {
      setLoading(true)
      const exchangeTokenPayload = {
        publicToken: PUBLIC_TOKEN,
        account: metadata?.account,
        user: user?.email,
      }
      const exchangeTokenResponse = await getExchangeToken(exchangeTokenPayload)
      const getBankAccountPayload = {
        accessToken: exchangeTokenResponse?.accessToken,
        account_id: metadata?.account?.id,
        email: user?.email,
        user,
      }
      await getBankAccount(getBankAccountPayload)
      getCustomerBanks()
      setLoading(false)
    } catch (error) {
      message.error(error?.response?.data?.message || 'Something Went Wrong')
      setLoading(false)
    }
  }

  const onExit = async (error) => {
    message.error(error?.message || 'Error Connecting Bank Account')
  }

  const config = {
    token,
    onSuccess,
    onExit,
  }

  const { open } = usePlaidLink(config)

  const updateAskedAmount = () => {
    form.setFieldsValue({ askedAmount: event?.CurrentAsk })
  }

  /* eslint-disable */
  const onFinish = async (values) => {
    const askedAmountValue = Number(values.askedAmount)
    const includeEventCostValue = values.includeEventCosts
    const twoPercentage = (2 / 100) * Number(askedAmountValue)
    const totalDonation = includeEventCostValue
      ? askedAmountValue + twoPercentage
      : askedAmountValue

    const repeatPayment = {
      split: {
        splitDonation: Boolean(values.splitDonation),
        months: values.splitDonation ? values.splitnoOfMonths || null : null,
      },
      repeat: {
        repeatMonths: Boolean(values.repeatMonths),
        months: values.repeatMonths ? values.repeatnoOfMonths || null : null,
      },
    }

    try {
      if (!values.agreeToTerms) {
        return message.error('You must agree to the terms and conditions')
      }

      // checking the allowed split months
      if (repeatPayment.split.splitDonation) {
        if (
          Number(repeatPayment.split.months) > Number(event?.splitPaymentMonths)
        ) {
          return message.error(
            `You can't split the donation for more than ${event?.splitPaymentMonths} months`,
          )
        }
      }

      // checking the allowed repeat months
      if (repeatPayment.repeat.repeatMonths) {
        if (
          Number(repeatPayment.repeat.months) >
          Number(event?.monthlyPaymentMonths)
        ) {
          return message.error(
            `You can't repeat the donation for more than ${event?.monthlyPaymentMonths} months`,
          )
        }
      }

      setLoading(true)
      // if bank account is linked or not
      if (banks.length === 0) {
        message.warn('Link Your Bank Account')
        setLoading(false)
      } else {
        const data = {
          amount: totalDonation,
          card: banks[0].id,
          cardToken: null,
          stripeAccount: subscriberPaymentInfo?.stripe?.stripeId,
          user,
          event,
          joinedEventDetails,
          location,
          includeEventCost: includeEventCostValue,
          commission: subscriberPaymentInfo.markup,
          isBankAccount: true,
          repeatPayment,
        }
        await donateByStripe(data)
        refreshEventData()
        message.success('Your donation is successfully submitted')
        form.resetFields()
        updateAskedAmount()
        setLoading(false)
      }
    } catch (error) {
      console.log('error', error)
      message.error(error?.response?.data?.message || 'Something Went Wrong')
      setLoading(false)
    }
  }

  const getCustomerBanks = async () => {
    try {
      const cards = await getUserBanks(user)
      setBanks(cards)
    } catch (error) {
      console.log('error getCustomerBanks', error)
      setLoading(false)
    }
  }
  const getToken = async () => {
    try {
      const payload = {
        id: user?.id,
        email: user?.email,
        name: `${user?.firstname} ${user?.lastname}`,
      }
      const response = await getPlaidToken(payload)
      setToken(response)
    } catch (error) {
      setLoading(false)
    }
  }
  const fetchData = async () => {
    setLoading(true)
    await getCustomerBanks()
    await getToken()
    setLoading(false)
  }

  // const isNoBank = form.getFieldValue("bank") === "no-bank-selected";

  const askedAmountValue = Number(form.getFieldValue('askedAmount'))
  const includeEventCostValue = form.getFieldValue('includeEventCosts')

  const twoPercentage = (2 / 100) * Number(askedAmountValue)
  const totalDonation = includeEventCostValue
    ? askedAmountValue + twoPercentage
    : askedAmountValue

  return (
    <div>
      {subscriberPaymentInfo?.stripe?.isConnected ? (
        <Form
          form={form}
          layout="vertical"
          key={0}
          onFinish={onFinish}
          name="event"
          initialValues={initialValues}
          scrollToFirstError
        >
          <Row gutter={[24, 0]} className="t-p-3">
            <Col xl={24} md={24} xs={24} sm={24}>
              <Form.Item
                name="askedAmount"
                label="Asked Amount"
                colon={false}
                rules={[bankDebitValidation]}
              >
                <Input disabled={!event?.EditAskedAmount} placeholder="50000" />
              </Form.Item>
            </Col>
            {banks.length > 0 ? (
              <>
                <Col xl={24} md={24} xs={24} sm={24}>
                  <p className="t-font-extrabold t-w-full t-rounded-2xl t-h-12 t-p-3 t-text-primary-100 t-mb-2 t-flex t-items-center t-shadow-md t-justify-between t-bg-yellow-100">{`${banks[0].bank_name}      -      **** **** **** ${banks[0].last4}`}</p>
                </Col>
                {event?.splitPaymentAllowed && (
                  <>
                    <Col
                      xl={isSplit ? 12 : 24}
                      md={isSplit ? 12 : 24}
                      xs={24}
                      sm={24}
                    >
                      <Form.Item
                        name="splitDonation"
                        label="Split Pledge"
                        colon={false}
                        rules={[bankDebitValidation]}
                      >
                        <Switch disabled={isRepeat} />
                      </Form.Item>
                    </Col>
                    {isSplit && (
                      <>
                        <Col
                          xl={isSplit ? 12 : 24}
                          md={isSplit ? 12 : 24}
                          xs={24}
                          sm={24}
                        >
                          <Form.Item
                            name="splitnoOfMonths"
                            label="Number of Months"
                            colon={false}
                            rules={[bankDebitValidation]}
                          >
                            <Input
                              placeholder="Number of Months"
                              disabled={isRepeat}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <Paragraph className="t-font-bold t-text-xs t-text-yellow-600 t-text-right">
                            Event creator allowed only{' '}
                            {event?.splitPaymentMonths} months
                          </Paragraph>
                        </Col>
                      </>
                    )}
                  </>
                )}
                {event?.monthlyPaymentAllowed && (
                  <>
                    <Col
                      xl={isRepeat ? 12 : 24}
                      md={isRepeat ? 12 : 24}
                      xs={24}
                      sm={24}
                    >
                      <Form.Item
                        name="repeatMonths"
                        label="Repeat Months"
                        colon={false}
                        rules={[bankDebitValidation]}
                      >
                        <Switch disabled={isSplit} />
                      </Form.Item>
                    </Col>
                    {isRepeat && (
                      <>
                        <Col
                          xl={isRepeat ? 12 : 24}
                          md={isRepeat ? 12 : 24}
                          xs={24}
                          sm={24}
                        >
                          <Form.Item
                            name="repeatnoOfMonths"
                            label="Number of Months"
                            colon={false}
                            rules={[bankDebitValidation]}
                          >
                            <Input
                              placeholder="Number of Months"
                              disabled={isSplit}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <Paragraph className="t-font-bold t-text-xs t-text-yellow-600 t-text-right">
                            Event creator allowed only{' '}
                            {event?.monthlyPaymentMonths} months
                          </Paragraph>
                        </Col>
                      </>
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                <Col xl={24} md={24} xs={24} sm={24}>
                  <Paragraph className="t-text-justify">
                    We use Plaid’s authentication service to ensure privacy of
                    your banking information.
                  </Paragraph>
                </Col>
                <Col xl={24} md={24} xs={24} sm={24}>
                  <Paragraph className="t-text-justify">
                    You will now be forwarded to Plaid to authenticate with your
                    bank and process your payment.
                  </Paragraph>
                </Col>
                <Col xl={24} md={24} xs={24} sm={24} className="t-mb-6">
                  <Button
                    loading={loading}
                    type="primary"
                    className="px-25"
                    onClick={open}
                  >
                    Link Bank Account
                  </Button>
                </Col>
              </>
            )}
            {/* <Col xl={24} md={24} xs={24} sm={24}>
            <Form.Item
              name="bank"
              label="Select Bank"
              colon={false}
              rules={[bankDebitValidation]}
            >
              <Select placeholder="Select Bank" style={{ width: "100%" }}>
                <Select.Option value={"no-bank-selected"}>
                  {"No Bank Account Selected"}
                </Select.Option>
                {banks.map((item, index) => (
                  <Select.Option
                    value={item.id}
                    key={index}
                    className="t-capitalize"
                  >
                    {`${item.bank_name} - **** **** **** ${item.last4}`}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col> */}

            <Col xl={24} md={24} xs={24} sm={24}>
              <Form.Item
                name="includeEventCosts"
                label="Include Event Cost"
                colon={false}
                rules={[bankDebitValidation]}
              >
                <Switch />
              </Form.Item>
            </Col>
            <Col xl={24} md={24} xs={24} sm={24}>
              <Form.Item
                name="agreeToTerms"
                colon={false}
                rules={[bankDebitValidation]}
              >
                <Checkbox
                  onChange={(e) =>
                    form.setFieldsValue({
                      agreeToTerms: e.target.checked,
                    })
                  }
                >
                  <span className="font-bold text-dark hover:t-text-primary-100 t-cursor-pointer">
                    I hereby agree to pay and adhere to Pledge Garden Terms &
                    Conditions
                  </span>
                </Checkbox>
              </Form.Item>
            </Col>
            <Col xl={24} md={24} xs={24} sm={24}>
              <Row justify="end">
                <Title level={3}>
                  {`Total Donation = ${formatAmount(totalDonation)}`}
                </Title>
              </Row>
            </Col>
            <Col span={24} className="text-right">
              <Button
                loading={loading}
                disabled={banks.length === 0}
                type="primary"
                className="px-25"
                htmlType="submit"
              >
                Donate Via Bank Account
              </Button>
            </Col>
          </Row>
        </Form>
      ) : (
        <div className="t-h-72 t-flex t-items-center t-justify-center t-flex-col">
          <RiErrorWarningFill
            fontSize="2.5rem"
            className="t-text-primary-100"
          />
          <Title level={5}>
            Bank account payments are not configured for this event
          </Title>
          <Paragraph>You can use other payment methods</Paragraph>
        </div>
      )}
    </div>
  )
}

export { BankDebit }
