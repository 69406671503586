import React, { useEffect, useState } from 'react'
import {
  Card,
  Form,
  Input,
  Row,
  Col,
  Button,
  Select,
  Switch,
  Upload,
  message,
  Skeleton,
} from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import {
  getCodeList,
  getOrganization,
  getUserOrganization,
  registerOrganization,
  updateOrganizatoin,
} from 'store/organizationSlice/organizationActions'
import { organizationValidation } from 'validations'
import { useParams, useHistory } from 'react-router-dom'
import { authActions, useAuthSelector } from 'store/authSlice/authReducer'
import { MdSmsFailed } from 'react-icons/md'
// import ReCAPTCHA from "react-google-recaptcha";
import { useDispatch } from 'react-redux'

const { TextArea } = Input

const organizationTypes = [
  'Art Gallery',
  'Auction House',
  'Educational Institution',
  'Employee Benefit Association or Funds',
  'Fraternal Societies',
  'Government Entity',
  'Healthcare Institutions',
  'Labor Organization',
  'Law Enforcement',
  'Political Organization',
  'Religious Organization',
  'Social Clubs',
  'Social Welfare',
  'Sporting Venue',
  'Trade Association',
  'Veterans Organization',
  'Other',
]

const designations = [
  '501(c)(3)',
  '501(c)(4)',
  '501(c)(7)',
  '501(c)(8)',
  '501(c)(9)',
  '501(c)(10)',
  '501(c)(17)',
  '501(c)(19)',
  '501(c)(23)',
  'IRC Section 527',
]

const initialValues = {
  Name: '',
  OrganizationType: '',
  NonProfit: false,
  Designation: '',
  TaxId: '',
  Address1: '',
  Address2: '',
  City: '',
  State: '',
  ZipCode: '',
  PhoneNumber: '',
  Website: '',
  Description: '',
  IdentityType: '',
  IdentityFile: null,
  W9Form: null,
  FundedOutside: false,
}

function RegisterOrganization() {
  const { user } = useAuthSelector()
  const [form] = Form.useForm()
  const params = useParams()
  const history = useHistory()
  const dispatch = useDispatch()

  Form.useWatch('NonProfit', form)
  Form.useWatch('IdentityFile', form)
  Form.useWatch('W9Form', form)

  const [loading, setLoading] = useState(false)
  const [isOrgAlready, setOrgAlready] = useState(false)
  const [buttonLoading, setButtonLoading] = useState(false)
  const [codes, setCodes] = useState([])

  useEffect(() => {
    const getData = async () => {
      setLoading(true)
      if (!params.id) {
        const isOrgAlreadyPresent = await getUserOrganization(user.email)
        if (isOrgAlreadyPresent) {
          setOrgAlready(isOrgAlreadyPresent)
          setLoading(false)
          return
        }
      }
      if (params.id) {
        const org = await getOrganization(params.id)
        if (org) {
          form.setFieldsValue({
            Name: org.Name,
            OrganizationType: org.OrganizationType,
            NonProfit: org.NonProfit,
            Designation: org.Designation,
            TaxId: `${org.TaxId.slice(0, 2)}-${org.TaxId.slice(2)}`,
            Address1: org.Address1,
            Address2: org.Address2,
            City: org.City,
            State: org.State,
            ZipCode: org.ZipCode,
            PhoneNumber: org.PhoneNumber,
            Website: org.Website,
            Description: org.Description,
            IdentityType: org.IdentityType,
            IdentityFile: org.IdentityFile,
            W9Form: org.W9Form,
            FundedOutside: org.FundedOutside,
          })
        } else {
          message.error('Invalid Organization Id')
          history.push('/settings/register-organization/')
        }
      }
      const response = await getCodeList()
      setCodes(response)
      setLoading(false)
    }
    getData()
  }, [params.id, history, form, user])

  const onFinish = async (values) => {
    let data = {
      ...values,
      TaxId: values.TaxId.replace('-', ''),
      Subscriber: user.id,
      Email: user.email,
      CreatedBy: user.email,
      FundedOutside: false,
    }
    delete data.recaptcha
    try {
      setButtonLoading(true)
      if (params.id) {
        data = { id: params.id, ...data }
        await updateOrganizatoin(data)
        message.success(`Organization Detail Updated Successfully`)
        dispatch(authActions.setOrganization(data))
        history.push('/settings/manage-organization/')
        setButtonLoading(false)
      } else {
        await registerOrganization(data)
        message.success('Organization Registration Request Sent')
        setButtonLoading(false)
        form.resetFields()
      }
    } catch (error) {
      message.error('Something Went Wrong')
      setButtonLoading(false)
    }
  }

  const onFinishFailed = () => {}

  const buttonText = buttonLoading
    ? params.id
      ? 'Updating'
      : 'Registering'
    : params.id
    ? 'Update'
    : 'Register'
  return (
    <Card
      className="header-solid"
      bordered={false}
      title={[
        <>
          <h6 className="mb-0 t-text-2xl">
            {params.id ? 'Update Organization' : 'Register Organization'}
          </h6>
          <p className="font-regular">
            {params.id ? params.id : 'Enter Information'}
          </p>
        </>,
      ]}
    >
      <div style={{ minHeight: 'calc(100vh - 220px)' }}>
        <Skeleton loading={loading} active paragraph={{ rows: 18 }}>
          {isOrgAlready ? (
            <div
              className="t-w-full t-flex t-justify-center t-items-center"
              style={{ height: 'calc(74vh)' }}
            >
              <div className="t-flex t-flex-col t-justify-center t-items-center">
                <MdSmsFailed fontSize="3rem" className="t-text-primary-100" />
                <p>Your account has already registered organization</p>
                <p>
                  If you want to edit your organization information{' '}
                  <span
                    className="t-font-semibold t-cursor-pointer t-underline"
                    onClick={() =>
                      history.push(
                        `/settings/register-organization/${isOrgAlready.id}`,
                      )
                    }
                  >
                    Click Here
                  </span>
                </p>
              </div>
            </div>
          ) : (
            <Form
              form={form}
              layout="vertical"
              key={0}
              onFinish={onFinish}
              name="organization"
              initialValues={initialValues}
              onFinishFailed={onFinishFailed}
              scrollToFirstError
            >
              <Row gutter={[24, 0]}>
                <Col xl={12} md={12} xs={24} sm={24}>
                  <Form.Item
                    name="Name"
                    label="Organization Name"
                    colon={false}
                    rules={[organizationValidation]}
                  >
                    <Input placeholder="Al-Noor LTD" />
                  </Form.Item>
                </Col>
                <Col xl={12} md={12} xs={24} sm={24}>
                  <Form.Item
                    name="OrganizationType"
                    label="Organization Type"
                    colon={false}
                    rules={[organizationValidation]}
                  >
                    <Select
                      placeholder="Organization Type"
                      style={{ width: '100%' }}
                    >
                      {organizationTypes.map((item, index) => (
                        <Select.Option value={item} key={index}>
                          {item}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xl={12} md={12} xs={24} sm={24}>
                  <Form.Item
                    name="TaxId"
                    label="Fed Tax ID"
                    colon={false}
                    rules={[organizationValidation]}
                  >
                    <Input placeholder="12-1234567" />
                  </Form.Item>
                </Col>
                <Col xl={12} md={12} xs={24} sm={24}>
                  <Form.Item
                    name="Address1"
                    label="Number And Street"
                    colon={false}
                    rules={[organizationValidation]}
                  >
                    <Input placeholder="Street 11" />
                  </Form.Item>
                </Col>
                <Col xl={12} md={12} xs={24} sm={24}>
                  <Form.Item
                    name="State"
                    label="State"
                    colon={false}
                    rules={[organizationValidation]}
                  >
                    <Input placeholder="Florida" />
                  </Form.Item>
                </Col>
                <Col xl={12} md={12} xs={24} sm={24}>
                  <Form.Item
                    name="PhoneNumber"
                    label="Phone Number"
                    colon={false}
                    rules={[organizationValidation]}
                  >
                    <Input placeholder="1234567891" />
                  </Form.Item>
                </Col>
                <Col xl={8} md={8} xs={24} sm={24}>
                  <Form.Item
                    name="ZipCode"
                    label="Zip"
                    colon={false}
                    rules={[organizationValidation]}
                  >
                    <Input placeholder="36000" />
                  </Form.Item>
                </Col>
                <Col xl={8} md={8} xs={24} sm={24}>
                  <Form.Item
                    name="City"
                    label="City"
                    colon={false}
                    rules={[organizationValidation]}
                  >
                    <Input placeholder="Malan" />
                  </Form.Item>
                </Col>
                <Col xl={8} md={8} xs={24} sm={24}>
                  <Form.Item
                    name="Address2"
                    label="Suite"
                    colon={false}
                    rules={[organizationValidation]}
                  >
                    <Input placeholder="ABC" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label="Website"
                    name="Website"
                    colon={false}
                    rules={[organizationValidation]}
                  >
                    <Input placeholder="https://www.google.com" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="Description"
                    label="Brief Organization Description"
                    colon={false}
                    rules={[organizationValidation]}
                  >
                    <TextArea
                      placeholder="Tell us about the organization"
                      autoSize={{ minRows: 3, maxRows: 5 }}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="NonProfit"
                    label="Non Profit Organization"
                    colon={false}
                    rules={[organizationValidation]}
                  >
                    <Switch />
                  </Form.Item>
                </Col>
                {form.getFieldValue('NonProfit') && (
                  <>
                    <Col xl={12} md={12} xs={24} sm={24}>
                      <Form.Item
                        name="Designation"
                        label="IRS Tax Designations"
                        colon={false}
                        rules={[organizationValidation]}
                      >
                        <Select
                          placeholder="Select IRS Tax Designations"
                          style={{ width: '100%' }}
                        >
                          {designations.map((item, index) => (
                            <Select.Option value={item} key={index}>
                              {item}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xl={12} md={12} xs={24} sm={24}>
                      <Form.Item
                        name="W9Form"
                        label="Upload W9 Form"
                        colon={false}
                        rules={[organizationValidation]}
                      >
                        <Upload
                          maxCount={1}
                          customRequest={(e) =>
                            form.setFieldsValue({ W9Form: e.file })
                          }
                          showUploadList={false}
                        >
                          <Button
                            icon={<UploadOutlined />}
                            style={{ marginRight: '1rem' }}
                          >
                            {form.getFieldValue('W9Form')
                              ? 'Change'
                              : 'Click to Upload'}
                          </Button>
                          {form.getFieldValue('W9Form') &&
                            form.getFieldValue('W9Form').name}
                        </Upload>
                      </Form.Item>
                    </Col>
                  </>
                )}
                {/* Get Options From API Here */}
                <Col xl={12} md={12} xs={24} sm={24}>
                  <Form.Item
                    name="IdentityType"
                    label="Select Government Issued Photo ID"
                    colon={false}
                    rules={[organizationValidation]}
                  >
                    <Select
                      placeholder="Select Government Issued Photo ID"
                      style={{ width: '100%' }}
                    >
                      {codes.map((item, index) => (
                        <Select.Option value={item} key={index}>
                          {item}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xl={12} md={12} xs={24} sm={24}>
                  <Form.Item
                    name="IdentityFile"
                    label="Upload Identity"
                    colon={false}
                    rules={[organizationValidation]}
                  >
                    <Upload
                      maxCount={1}
                      customRequest={(e) =>
                        form.setFieldsValue({ IdentityFile: e.file })
                      }
                      showUploadList={false}
                    >
                      <Button
                        icon={<UploadOutlined />}
                        style={{ marginRight: '1rem' }}
                      >
                        {form.getFieldValue('IdentityFile')
                          ? 'Change'
                          : 'Click to Upload'}
                      </Button>
                      {form.getFieldValue('IdentityFile') &&
                        form.getFieldValue('IdentityFile').name}
                    </Upload>
                  </Form.Item>
                </Col>
                {/* <Col span={24}>
              <Form.Item name="recaptcha" rules={[organizationValidation]}>
                <ReCAPTCHA sitekey="6LdSWKgZAAAAAKrrp6GFXldEv0I4vqO3yWrTkuNV" />
              </Form.Item>
            </Col> */}
                <Col span={24} className="text-left">
                  <Button
                    loading={buttonLoading}
                    type="primary"
                    className="px-25"
                    htmlType="submit"
                  >
                    {buttonText}
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Skeleton>
      </div>
    </Card>
  )
}

export { RegisterOrganization }
