import React, { useEffect, useState } from 'react'
import { message, Divider, Skeleton } from 'antd'
import Api from 'api'
import moment from 'moment'

// const { Title, Paragraph } = Typography;

function ReportView({ id, name, type }) {
  const [loading, setLoading] = useState()
  const [markup, setMarkup] = useState()
  const [report, setReport] = useState()

  useEffect(() => {
    setLoading(true)
    Api.get(`admin/subsrciberMarkUpsByOrg/${id}`)
      .then((res) => {
        setMarkup(res.results)
        if (type === 'sms') {
          Api.get(`admin/subsrciberSMSCosting/${id}`)
            .then((res1) => {
              setReport(res1.results)
              setLoading(false)
            })
            .catch((err) => {
              console.log('Error', err)
              setLoading(false)
            })
        } else if (type === 'email') {
          Api.get(`admin/subsrciberEmailCosting/${id}`)
            .then((res1) => {
              setReport(res1.results)
              setLoading(false)
            })
            .catch((err) => {
              console.log('Error', err)
              setLoading(false)
            })
        }
      })
      .catch(() => {
        setLoading(false)
        message.error('Something went wrong')
      })
  }, [id, type])

  return (
    <Skeleton loading={loading} paragraph={{ rows: 12 }}>
      <div className="t-mt-8">
        <div className="t-text-center">
          <p className="t-text-lg t-font-medium">
            Report For
            {name}
          </p>
          <p>
            Last Updated:
            {moment().format('LLLL')}
          </p>
        </div>
        <Divider />
        {loading === false && report?.length > 0 ? (
          <div className="card-order">
            <div className="mb-10 ant-order-flex">
              <span className="text-muted"> Total Records This Month: </span>
              <span className="text-lg text-dark font-semibold ml-auto">
                {report?.length}
              </span>
            </div>
            <div className="mb-10 ant-order-flex">
              <span className="text-muted">
                {'Cost for '} {type === 'sms' ? 'SMS' : 'Email'}:
              </span>
              <span className="text-lg text-dark font-semibold ml-auto">
                {type === 'sms' ? markup?.smsCost : markup?.emailCost}
              </span>
            </div>
            <div className="mb-10 ant-order-flex">
              <span className="text-muted"> Markup: </span>
              <span className="text-lg text-dark font-semibold ml-auto">
                {type === 'sms' ? markup?.SMSMarkup : markup?.EmailMarkup}%
              </span>
            </div>
            <div className="mb-10 ant-order-flex">
              <h5 className="font-regular text-muted">
                Total Records This Month:
              </h5>
              <h5 className="text-dark font-semibold ml-auto">
                {type === 'sms'
                  ? (
                      report.length * markup.smsCost +
                      markup.SMSMarkup / 100
                    ).toFixed(2)
                  : (
                      report.length * markup.emailCost +
                      markup.EmailMarkup / 100
                    ).toFixed(2)}
              </h5>
            </div>
          </div>
        ) : (
          <div className="t-h-16 t-flex t-justify-center t-items-center">
            No data found
          </div>
        )}
      </div>
    </Skeleton>
  )
}

export { ReportView }
