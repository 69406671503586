import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { AppRoutes } from 'routes'
import { persister, store } from './store/index'
import reportWebVitals from './reportWebVitals'
// import "antd/dist/antd.css";
import 'antd-button-color/dist/css/style.css'
import './styles/AntD.less'
import './styles/index.css'

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persister}>
      <AppRoutes />
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
)

reportWebVitals()
