import React, { useEffect, useState } from 'react'
import { Card, Divider, Typography, Col, Skeleton } from 'antd'
import { getPaymentAccounts } from 'store/authSlice/authActions'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { useDispatch } from 'react-redux'
import { ConnectPaypal, ConnectStripe } from './components'

const { Title } = Typography

function Payment() {
  const { loading, user } = useAuthSelector()
  const [flag, setFlag] = useState(false)
  const dispatch = useDispatch()

  const refreshPaymentData = () => setFlag(!flag)

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    dispatch(getPaymentAccounts(user?.email))
  }, [dispatch, user, flag])

  return (
    <Card
      className="header-solid"
      bordered={false}
      title={[
        <Col>
          <Title level={4}>Manage Payment Accounts</Title>
          <p>
            Connect Your Payment Account To Start Recieving Payments From Donors
          </p>
        </Col>,
      ]}
    >
      <div style={{ minHeight: 'calc(100vh - 220px)' }}>
        <Skeleton loading={loading} active paragraph={{ rows: 18 }}>
          <Col className="t-py-8">
            <ConnectStripe refreshPayment={refreshPaymentData} />
            <Divider />
            <ConnectPaypal refreshPayment={refreshPaymentData} />
          </Col>
        </Skeleton>
      </div>
    </Card>
  )
}

export { Payment }
