import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getEventControlScreenDetails } from 'store/eventSlice/eventActions'

const SECONDS = 15

const useDonations = (id) => {
  const dispatch = useDispatch()
  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(getEventControlScreenDetails(id))
    }, SECONDS * 1000)
    return () => clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return true
}

export { useDonations }
