import { message } from 'antd'
import Api from 'api'
import { programActions } from './programReducer'

export const getPrograms = () => (dispatch) => {
  dispatch(programActions.setLoading(true))
  Api.get('/programServices')
    .then((res) => {
      dispatch(programActions.setPrograms(res?.results))
      dispatch(programActions.setLoading(false))
    })
    .catch((err) => {
      console.log('Error', err)
      dispatch(programActions.setLoading(false))
    })
}

export const addProgram = (payload, actions) => (dispatch) => {
  dispatch(programActions.setLoading(true))
  Api.put('/programServices', payload)
    .then(() => {
      dispatch(programActions.setLoading(false))
      message.success('Program Added Successfully')
      if (actions) {
        actions()
      }
    })
    .catch((err) => {
      console.log('Error', err)
      dispatch(programActions.setLoading(false))
      message.error('An Error Occured')
    })
}

export const getProgramDetail = (id, form) => (dispatch) => {
  dispatch(programActions.setLoading(true))
  Api.get(`/programServices?id=${id}`)
    .then((res) => {
      if (res.results) {
        const program = res.results[0]
        if (form) {
          form.setFieldsValue({
            Title: program.Title,
            Description: program.Description,
            Requirements: program.Requirements,
            Cost: program.Cost,
            LocationType: program.LocationType,
            Notes: program.Notes,
            AllowedRegistration: program.AllowedRegistration,
          })
        }
      }
      dispatch(programActions.setLoading(false))
    })
    .catch((err) => {
      console.log('Error', err)
      dispatch(programActions.setLoading(false))
    })
}

export const updateProgram = (payload, actions) => (dispatch) => {
  dispatch(programActions.setLoading(true))
  Api.put('programServices/updateProgramService', payload)
    .then(() => {
      dispatch(programActions.setLoading(false))
      message.success('Program Updated Successfully')
      if (actions) {
        actions()
      }
    })
    .catch((err) => {
      console.log('Error', err)
      dispatch(programActions.setLoading(false))
      message.error('An Error Occured')
    })
}

export const updateProgramStatus = (id, status, actions) => (dispatch) => {
  dispatch(programActions.setLoading(true))
  if (status === 1) {
    Api.get(`programServices/deactiveService?id=${id}`)
      .then(() => {
        dispatch(programActions.setLoading(false))
        if (actions) {
          actions()
        }
        message.success('Program Deactivated Successfully')
      })
      .catch((error) => {
        console.log('error', error)
        message.error('Something Went Wrong')
        dispatch(programActions.setLoading(false))
      })
  } else if (status === 0) {
    Api.get(`programServices/activeService?id=${id}`)
      .then(() => {
        dispatch(programActions.setLoading(false))
        if (actions) {
          actions()
        }
        message.success('Program Activated Successfully')
      })
      .catch((error) => {
        console.log('error', error)
        message.error('Something Went Wrong')
        dispatch(programActions.setLoading(false))
      })
  }
}

export const getRegisterUsers = (id, setLoading) => (dispatch) => {
  setLoading(true)
  Api.get(`programServices/getRegisteredUsersByProgId?id=${id}`)
    .then((res) => {
      console.log('res', res)
      dispatch(programActions.setRegisteredUsers(res.results))
      setLoading(false)
    })
    .catch((err) => {
      console.log('Error', err)
      setLoading(false)
      message.error('An Error Occured')
    })
}

export const getFeedbackByUsers = (id, setLoading) => (dispatch) => {
  setLoading(true)
  Api.get(`subscriber/feedback/${id}`)
    .then((res) => {
      dispatch(programActions.setFeedbackByUsers(res.result))
      setLoading(false)
    })
    .catch((err) => {
      console.log('Error', err)
      setLoading(false)
      message.error('An Error Occured')
    })
}

export const getSubscriberPaymentInformation = (email) => (dispatch) => {
  dispatch(programActions.setLoading(true))
  Api.get(`/stripe/payment-account-info?email=${email}`)
    .then((res) => {
      if (res.results.length === 0 || !res.results[0]?.stripeID) {
        dispatch(
          programActions.setSubscriberStripeInfo({
            isConnected: false,
            stripeId: null,
          }),
        )
      } else {
        dispatch(
          programActions.setSubscriberStripeInfo({
            isConnected: true,
            stripeId: res.results[0]?.stripeID,
          }),
        )
      }
      if (res.results.length === 0 || !res.results[0]?.paypalID) {
        dispatch(
          programActions.setSubscriberPaypalInfo({
            isConnected: false,
            paypalId: null,
          }),
        )
      } else {
        dispatch(
          programActions.setSubscriberPaypalInfo({
            isConnected: true,
            paypalId: res.results[0]?.paypalID,
          }),
        )
      }
      dispatch(programActions.setLoading(false))
    })
    .catch((error) => {
      console.log('error getSubscriberPaymentInformation', error)
      dispatch(programActions.setLoading(false))
    })
}
