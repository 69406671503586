/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { Card, Button, Skeleton } from 'antd'
import { ArrowRightOutlined, CarryOutFilled } from '@ant-design/icons'
import { IoMdArrowRoundBack } from 'react-icons/io'
import { GrAchievement } from 'react-icons/gr'
import { MdPendingActions, MdCancelPresentation } from 'react-icons/md'
import Api from 'api'

function PledgeConversionReports() {
  const [selected, setSelected] = useState()
  const options = [
    'FY Pledge Conversions',
    'Last Year Pledge Conversions',
    'Historic Trending',
  ]
  return (
    <Card
      id="PledgeConversionReports"
      className="mb-24 header-solid list-settings-sessions"
      bordered={false}
      title={[
        <React.Fragment key={0}>
          {selected === undefined ? (
            <h5 className="font-semibold">Pledge Conversion Reports</h5>
          ) : (
            <div className="t-flex t-space-x-4">
              <IoMdArrowRoundBack
                fontSize="1.5rem"
                className="t-cursor-pointer"
                onClick={() => setSelected()}
              />
              <h5 className="font-semibold">{options[selected]}</h5>
            </div>
          )}
        </React.Fragment>,
      ]}
    >
      {selected === undefined ? (
        <>
          {options.map((item, index) => (
            <>
              {index !== 0 && <hr className="gradient-line my-10" />}
              <div className="ant-row-flex ant-row-flex-middle">
                <div
                  className="text-center ant-col"
                  style={{ minWidth: '40px' }}
                >
                  <CarryOutFilled
                    className="text-gray-6"
                    style={{ fontSize: '18px' }}
                  />
                </div>
                <div className="ant-col pl-15">
                  <p className="mb-0">{item}</p>
                </div>
                <div
                  className="ml-auto ant-col ant-col-24 ant-col-md-12"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Button type="link" onClick={() => setSelected(index)}>
                    See Details <ArrowRightOutlined />
                  </Button>
                </div>
              </div>
            </>
          ))}
        </>
      ) : (
        <Single selected={selected} />
      )}
    </Card>
  )
}

function Single({ selected }) {
  const [loading, setLoading] = useState()
  const [report, setReport] = useState()

  const getPledgeConversion = (type) => {
    Api.put('adminReports/pledgeConversion', { type })
      .then((res) => {
        setReport(res.results)
        setLoading(false)
      })
      .catch((err) => {
        console.log('Error', err)
        setLoading(false)
      })
  }

  const getFYPledgeConversions = () => {
    Api.get('adminReports/pledgeConversionHistory')
      .then((res) => {
        setReport(res.results)
        setLoading(false)
      })
      .catch((err) => {
        console.log('Error', err)
        setLoading(false)
      })
  }

  useEffect(() => {
    setLoading(true)
    if (selected === 2) {
      getFYPledgeConversions()
    } else {
      getPledgeConversion()
    }

    // eslint-disable-next-line
  }, [])

  const getTrend = (fy, ly) => {
    if (fy < ly) {
      return <p className="text-error">Downward</p>
    }
    if (fy > ly) {
      return <p className="text-success">Upward</p>
    }
    return <p className="text-warning">No Change</p>
  }

  return (
    <Skeleton loading={loading} paragraph={{ rows: 12 }}>
      {report ? (
        <div className="t-space-y-4">
          <div className="t-flex t-items-center t-justify-between">
            <div className="t-flex t-items-center t-space-x-2">
              <GrAchievement fontSize="1.2rem" className="t-text-primary-100" />
              <p>Pledges Converted To Donations:</p>
            </div>
            {selected === 2
              ? getTrend(
                  report?.convertToDonationAmountFy,
                  report?.convertToDonationAmountLy,
                )
              : report.convertToDonationAmount}
          </div>
          <div className="t-flex t-items-center t-justify-between">
            <div className="t-flex t-items-center t-space-x-2">
              <MdPendingActions
                fontSize="1.5rem"
                className="t-text-primary-100"
              />
              <p>Pledges Delayed:</p>
            </div>
            {selected === 2
              ? getTrend(
                  report?.pledgesDelayedAmountFy,
                  report?.pledgesDelayedAmountLy,
                )
              : report.convertToDonationAmount}
          </div>
          <div className="t-flex t-items-center t-justify-between">
            <div className="t-flex t-items-center t-space-x-2">
              <MdCancelPresentation
                fontSize="1.2rem"
                className="t-text-primary-100"
              />
              <p>Pledges Canceled:</p>
            </div>
            {selected === 2
              ? getTrend(report?.cancelPledgesFy, report?.cancelPledgesLy)
              : report.convertToDonationAmount}
          </div>
        </div>
      ) : (
        <div>No Data Found</div>
      )}
    </Skeleton>
  )
}

export default PledgeConversionReports
