import React, { useEffect, useState } from 'react'
import {
  Modal,
  Input,
  Button,
  Form,
  Row,
  Col,
  Switch,
  Checkbox,
  Typography,
  message,
} from 'antd'
import { GiReceiveMoney } from 'react-icons/gi'
import { useEventSelector } from 'store/eventSlice/eventReducer'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { addPledgeDonation } from 'store/paymentSlice/paymentActions'
import { useDispatch } from 'react-redux'
import { getEventControlScreenDetails } from 'store/eventSlice/eventActions'
import * as Yup from 'yup'
import { formatAmount } from 'utils'

const { Paragraph, Title } = Typography

const numberValidation = new RegExp('^[0-9]+$')

const schema = Yup.object().shape({
  askedAmount: Yup.string()
    .required('Required')
    .matches(numberValidation, 'Invalid amount'),
  splitPledge: Yup.string().required('Required'),
  splitnoOfMonths: Yup.string()
    .required('Required')
    .matches(numberValidation, 'Invalid amount'),
  repeatMonths: Yup.string().required('Required'),
  repeatnoOfMonths: Yup.string()
    .required('Required')
    .matches(numberValidation, 'Invalid amount'),
  agreeToTerms: Yup.string().required('Required'),
  includeEventCosts: Yup.string().required('Required'),
})

const pledgeValidation = {
  async validator({ field }, value) {
    await schema.validateSyncAt(field, { [field]: value })
  },
}

const initialValues = {
  askedAmount: null,
  splitPledge: false,
  splitnoOfMonths: null,
  repeatMonths: false,
  repeatnoOfMonths: null,
  agreeToTerms: false,
  includeEventCosts: false,
}

function PledgeEvent() {
  const dispatch = useDispatch()

  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(false)

  const { event, joinedEventDetails } = useEventSelector()
  const { user, location } = useAuthSelector()
  const [form] = Form.useForm()

  Form.useWatch('askedAmount', form)
  Form.useWatch('includeEventCosts', form)
  Form.useWatch('splitPledge', form)
  Form.useWatch('repeatMonths', form)
  Form.useWatch('agreeToTerms', form)

  useEffect(() => {
    form.setFieldsValue({ askedAmount: event?.CurrentAsk })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event?.CurrentAsk])

  const refreshEventData = () => {
    dispatch(getEventControlScreenDetails(event?.Id))
  }

  /* eslint-disable */
  const onFinish = async (values) => {
    const askedAmountValue = Number(values.askedAmount)
    const includeEventCostValue = values.includeEventCosts
    const twoPercentage = (2 / 100) * Number(askedAmountValue)
    const totalDonation = includeEventCostValue
      ? askedAmountValue + twoPercentage
      : askedAmountValue

    if (!values.agreeToTerms) {
      return message.error('You must agree to the terms and conditions')
    }
    try {
      setLoading(true)
      const data = {}
      data.eventId = event.Id
      data.EventName = event.Title
      data.fullName = `${user?.firstname} ${user?.lastname}`
      data.donorId = user.id
      data.email = user?.email
      data.DonorPhoneNumber = user.phone

      data.isAttendingOnline = joinedEventDetails?.isRemote
      data.isDonatingAnonymously = joinedEventDetails?.donateAnonymously
      data.table = joinedEventDetails?.tableName || null

      data.zipCode = location?.zipCode
      data.transactionType =
        event.EventType === 'Fundraiser' ? 'event' : 'campaign'
      data.Status = 'Pending'

      // const monthyMaxCount = event?.monthlyPaymentMonths
      // const splitMaxCount = event?.splitPaymentMonths

      // add logic for the max months applied here as well

      data.splitPayment = values.splitPledge
      data.splitPaymentNoMonths = values.splitnoOfMonths
      data.amount = totalDonation
      data.recurringBill = values.repeatMonths
      data.recurringMonths = values.repeatnoOfMonths
      data.contributeToEventCost = values.includeEventCosts

      await addPledgeDonation(data)
      message.success('Your pledge request sent successfully')
      setLoading(false)
      refreshEventData()
    } catch (error) {
      console.log('error', error)
      message.error('Something Went Wrong')
      setLoading(false)
    }
  }

  const isSplit = form.getFieldValue('splitPledge')
  const isRepeat = form.getFieldValue('repeatMonths')

  const askedAmountValue = Number(form.getFieldValue('askedAmount'))
  const includeEventCostValue = form.getFieldValue('includeEventCosts')

  const twoPercentage = (2 / 100) * Number(askedAmountValue)
  const totalDonation = includeEventCostValue
    ? askedAmountValue + twoPercentage
    : askedAmountValue

  return (
    <>
      <div
        className="t-flex t-flex-col t-justify-center t-items-center t-space-y-2 t-cursor-pointer"
        onClick={() => setVisible(true)}
      >
        <GiReceiveMoney
          fontSize="2rem"
          className="t-text-primary-100 t-text-center t-text-lg md:t-text-2xl"
        />
        <h6 className="t-text-md t-font-bold">Pledge</h6>
      </div>
      <Modal
        centered
        keyboard
        title="Pledge Event"
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={null}
      >
        <Form
          form={form}
          layout="vertical"
          key={0}
          onFinish={onFinish}
          name="organization"
          initialValues={initialValues}
          scrollToFirstError
        >
          <Row gutter={[24, 0]}>
            <Col xl={24} md={24} xs={24} sm={24}>
              <Form.Item
                name="askedAmount"
                label="Asked Amount"
                colon={false}
                rules={[pledgeValidation]}
              >
                <Input disabled={!event?.EditAskedAmount} placeholder="50000" />
              </Form.Item>
            </Col>
            {event?.splitPaymentAllowed && false && (
              <>
                <Col xl={24} md={24} xs={24} sm={24}>
                  <Form.Item
                    name="splitPledge"
                    label="Split Pledge"
                    colon={false}
                    rules={[pledgeValidation]}
                  >
                    <Switch />
                  </Form.Item>
                </Col>
                {isSplit ? (
                  <Col xl={12} md={12} xs={24} sm={24}>
                    <Form.Item
                      name="splitnoOfMonths"
                      label="Number of Months"
                      colon={false}
                      rules={[pledgeValidation]}
                    >
                      <Input placeholder="Number of Months" />
                    </Form.Item>
                  </Col>
                ) : (
                  <Col xl={12} md={12} xs={24} sm={24} />
                )}
              </>
            )}
            {event?.monthlyPaymentAllowed && false && (
              <>
                <Col xl={24} md={24} xs={24} sm={24}>
                  <Form.Item
                    name="repeatMonths"
                    label="Repeat Months"
                    colon={false}
                    rules={[pledgeValidation]}
                  >
                    <Switch />
                  </Form.Item>
                </Col>
                {isRepeat ? (
                  <Col xl={12} md={12} xs={24} sm={24}>
                    <Form.Item
                      name="repeatnoOfMonths"
                      label="Number of Months"
                      colon={false}
                      rules={[pledgeValidation]}
                    >
                      <Input placeholder="Number of Months" />
                    </Form.Item>
                  </Col>
                ) : (
                  <Col xl={12} md={12} xs={24} sm={24} />
                )}
              </>
            )}
            <Col xl={24} md={24} xs={24} sm={24}>
              <Form.Item
                name="includeEventCosts"
                label="Include Event Cost"
                colon={false}
                rules={[pledgeValidation]}
              >
                <Switch />
              </Form.Item>
            </Col>
            <Col xl={24} md={24} xs={24} sm={24}>
              <Paragraph className="t-text-justify">
                You have pledged. You also agree to repeat this payment schedule
                for the next undefined year(s). By clicking on the button below,
                you agree to this payment schedule.
              </Paragraph>
            </Col>
            <Col xl={24} md={24} xs={24} sm={24}>
              <Form.Item
                name="agreeToTerms"
                colon={false}
                rules={[pledgeValidation]}
              >
                <Checkbox
                  onChange={(e) =>
                    form.setFieldsValue({
                      agreeToTerms: e.target.checked,
                    })
                  }
                >
                  <span className="font-bold text-dark hover:t-text-primary-100 t-cursor-pointer">
                    I hereby agree to pay and adhere to Pledge Garden Terms &
                    Conditions
                  </span>
                </Checkbox>
              </Form.Item>
            </Col>
            <Col xl={24} md={24} xs={24} sm={24}>
              <Row justify="end">
                <Title level={3}>
                  {`Total Donation = ${formatAmount(totalDonation)}`}
                </Title>
              </Row>
            </Col>
            <Col span={24} className="text-right">
              <Button
                loading={loading}
                type="primary"
                className="px-25"
                htmlType="submit"
              >
                Pledge Event
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  )
}

export { PledgeEvent }
