/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { Layout, Card } from 'antd'
import { Splash } from 'screens'
import { useDispatch } from 'react-redux'
import {
  checkUserExist,
  sendOtp,
  signIn,
  signUp,
} from 'store/authSlice/authActions'
import { useHistory } from 'react-router-dom'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { OTPForm, LoginForm, RegisterForm } from './components'

const { Content } = Layout

function Auth() {
  const { loading, extras, user } = useAuthSelector((state) => state)
  const { userPhone, userTemp } = extras
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    if (user) {
      history.push('/organizations')
    } else {
      history.push('/auth')
    }
  }, [user, history])

  const [isSplash, setIsSplash] = useState(true)
  const [authSetup, setAuthSetup] = useState('signin')

  const goTo = (option) => {
    setAuthSetup(option)
  }

  const navigate = (option) => {
    history.push(option)
  }

  const onSignInSumbit = (values) => {
    const { countryCode, phoneNumber } = values
    // removing zero if it's the first charater in the string
    const trimPhoneNumber = phoneNumber.replace(/^0+/, '')
    const phone = countryCode + trimPhoneNumber
    dispatch(checkUserExist(phone, goTo))
  }

  const onOtpSumbit = (values) => {
    const code = values.optCode
    dispatch(signIn(userTemp, code, navigate))
  }

  const onSignUpSumbit = (values) => {
    if (userPhone) {
      dispatch(
        signUp(
          userPhone,
          {
            family_name: values.lastName,
            given_name: values.firstName,
            email: values.email,
          },
          goTo,
        ),
      )
    }
  }

  const resendOtp = () => {
    if (userPhone) {
      dispatch(sendOtp(userPhone, goTo))
    }
  }

  return (
    <>
      {isSplash ? (
        <Splash text="Elevate Your Cause !" setIsSplash={setIsSplash} />
      ) : (
        <div className="layout-default ant-layout layout-sign-up ">
          <Content className="p-0">
            <div className="sign-up-header">
              <div className="content">
                <h1 className="mb-3 font-bold mbt-5">
                  {authSetup === 'signup' ? 'Sign Up' : 'Sign In'}
                </h1>
                <p className="text-lg" style={{ lineHeight: '24px' }}>
                  Login or Register into the pledge garden for start managing
                  your donations journey easily
                </p>
              </div>
            </div>

            <Card
              className="card-signup header-solid h-full ant-card pt-0"
              bordered="false"
              bodyStyle={{ paddingTop: '0px' }}
            >
              <div className="text-center">
                <h5 className="font-semibold" style={{ marginTop: '1rem' }}>
                  {authSetup === 'signup' ? 'Sign Up' : 'Login / Register'}
                </h5>
                <h6 className="font-regular text-muted t-my-2" level={5}>
                  {authSetup === 'signup'
                    ? 'Please provide your info below'
                    : authSetup === 'otp'
                    ? 'We have texted you the OTP (One Time Password) to your registered number. Please enter the OTP below.'
                    : 'Please provide your mobile phone number'}
                </h6>
              </div>
              <>
                {authSetup === 'signup' ? (
                  <RegisterForm
                    loading={loading}
                    onSubmit={onSignUpSumbit}
                    goTo={goTo}
                  />
                ) : authSetup === 'otp' ? (
                  <OTPForm
                    loading={loading}
                    onSubmit={onOtpSumbit}
                    resendOtp={resendOtp}
                  />
                ) : (
                  <LoginForm loading={loading} onSubmit={onSignInSumbit} />
                )}
              </>
            </Card>
          </Content>
        </div>
      )}
    </>
  )
}

export { Auth }
