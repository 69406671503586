import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Row, Skeleton, Avatar, Spin } from 'antd'
import { AreaChartOutlined } from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import { fetchMoreData, searchRows } from 'utils'
import { config } from 'configs'
import { useHistory } from 'react-router-dom'
import {
  serviceActions,
  useServiceSelector,
} from 'store/serviceSlice/serviceReducer'
import { getService } from 'store/serviceSlice/serviceActions'
import InfiniteScroll from 'react-infinite-scroll-component'

function ServiceList({ searchText }) {
  const dispatch = useDispatch()
  const history = useHistory()

  const { loading, services } = useServiceSelector()
  const [hasMore, setHasMore] = useState(true)
  const [current, setCurrent] = useState([])

  useEffect(() => {
    setCurrent(services.slice(0, config.itemFirstLoad))
    // eslint-disable-next-line
  }, [services])

  useEffect(() => {
    dispatch(getService())
  }, [dispatch])

  const handleServiceSelected = (service) => {
    dispatch(serviceActions.setService(service))
    history.push(`services/${service.id}`)
  }

  return (
    <Skeleton loading={loading} active paragraph={{ rows: 18 }}>
      <div
        className="t-flex t-flex-col t-justify-between t-h-full"
        style={{ minHeight: 'calc(100vh - 250px)' }}
      >
        <InfiniteScroll
          dataLength={searchRows(current, searchText).length}
          next={() => fetchMoreData(current, setCurrent, setHasMore, services)}
          hasMore={hasMore}
          loader={
            <div className="t-flex t-justify-center t-items-center">
              <Spin />
            </div>
          }
        >
          <Row gutter={[24, 24]}>
            {searchRows(current, searchText).length === 0 && (
              <div
                className="t-h-96 t-flex t-justify-center t-items-center t-w-full"
                style={{ minHeight: 'calc(100vh - 300px)' }}
              >
                <div className="t-flex t-flex-col t-items-center t-space-y-2">
                  <AreaChartOutlined
                    style={{ fontSize: '2rem' }}
                    className="t-text-primary-100"
                  />
                  <p>No Services</p>
                </div>
              </div>
            )}
            {searchRows(current, searchText).map((item, index) => (
              <Col span={24} lg={8} className="mb-24" key={index}>
                <Card
                  bodyStyle={{ paddingTop: '14px' }}
                  bordered={false}
                  className="card-project-2 header-solid"
                  title={[
                    <Row gutter={[24, 24]} key={0}>
                      <Col>
                        <Avatar
                          src={<AreaChartOutlined />}
                          style={{
                            width: '25px',
                            height: '25px',
                            lineHeight: '74px',
                            borderRadius: '8px',
                            padding: '16px',
                            backgroundColor: '#103150',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        />
                      </Col>
                      <Col>
                        <h6 className="font-semibold mb-10 t-w-20">
                          {item.Title ? item.Title : 'No Title Found'}
                        </h6>
                      </Col>
                    </Row>,
                  ]}
                >
                  <p className="t-truncate">
                    {item.Description
                      ? item.Description
                      : 'No Description Found'}
                  </p>
                  <hr />
                  <div className="t-flex t-justify-end t-mt-2">
                    <Button
                      type="link"
                      size="small"
                      className="t-rounded-full"
                      onClick={() => handleServiceSelected(item)}
                    >
                      See More
                    </Button>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        </InfiniteScroll>
      </div>
    </Skeleton>
  )
}

export { ServiceList }
