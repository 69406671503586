import * as Yup from 'yup'

const phoneNumberValidation = '^[0-9]{10,11}$'

const schema = Yup.object().shape({
  countryCode: Yup.string().required('Required'),
  phoneNumber: Yup.string()
    .required('Please input your phone number!')
    .matches(phoneNumberValidation, 'Invalid Phone number e.g 3456789123'),
  termsAndCondition: Yup.boolean().oneOf(
    [true],
    'Please accept Terms and Condtions to proceed',
  ),
})

const siginValidation = {
  async validator({ field }, value) {
    await schema.validateSyncAt(field, { [field]: value })
  },
}

export { siginValidation }
