/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { Skeleton } from 'antd'
import Api from 'api'

function EventStatisticsReport({ id }) {
  const [loading, setLoading] = useState()
  const [report, setReport] = useState()

  useEffect(() => {
    setLoading(true)
    Api.get(`adminReports/eventStats/${id}`)
      .then((res) => {
        console.log('reasdsads', res)
        setReport(res.results[0])
        setLoading(false)
      })
      .catch((err) => {
        console.log('Error', err)
        setLoading(false)
      })
    // eslint-disable-next-line
  }, [])

  return (
    <Skeleton loading={loading} paragraph={{ rows: 12 }}>
      {report ? (
        <div className="t-space-y-4">
          <div className="t-flex t-items-center t-justify-between">
            <p>% of Goal Achieved</p>
            {((report?.donatedAmount / report?.goalAmount) * 100).toFixed(2)}%
          </div>
          <div className="t-flex t-items-center t-justify-between">
            <p>Ratio of Donation to Pledges</p>
            {report?.perecentageOfDonations} /{report?.ratioPledges}
          </div>
          <div className="t-flex t-items-center t-justify-between">
            <p>% of Cash Donation</p>
            {((report?.noCash / report?.noDonations) * 100).toFixed(2)}%
          </div>
          <div className="t-flex t-items-center t-justify-between">
            <p>% of Card Donation:</p>
            {((report?.noCredit / report?.noDonations) * 100).toFixed(2)}%
          </div>
          <div className="t-flex t-items-center t-justify-between">
            <p>% of Check Donation</p>
            {((report?.noChecks / report?.noDonations) * 100).toFixed(2)}%
          </div>
          <div className="t-flex t-items-center t-justify-between">
            <p>Average Donation Size</p>
            {report?.donatedAmount / report?.noDonations}
          </div>
          <div className="t-flex t-items-center t-justify-between">
            <p>% of Remote Donation</p>
            {(
              (report?.onlineStats?.donors / report?.noDonations) *
              100
            ).toFixed(2)}{' '}
            %
          </div>
        </div>
      ) : (
        <div>No Data Found</div>
      )}
    </Skeleton>
  )
}

export default EventStatisticsReport
