/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { Card, Button, Skeleton, Pagination } from 'antd'
import { ArrowRightOutlined, CarryOutFilled } from '@ant-design/icons'
import Api from 'api'
import { IoMdArrowRoundBack } from 'react-icons/io'
import { config } from 'configs'
import { paginate } from 'utils'

function YtdEarningReports() {
  const [loading, setLoading] = useState()
  const [report, setReport] = useState()
  const [selected, setSelected] = useState()

  useEffect(() => {
    setLoading(true)
    Api.get('adminReports/ytdEarningReport')
      .then((res) => {
        setReport({ ...res.results, programs: 10 })
        setLoading(false)
      })
      .catch((err) => {
        console.log('Error', err)
        setLoading(false)
      })
  }, [])

  return (
    <Card
      id="YtdEarningReports"
      className="mb-24 header-solid list-settings-sessions"
      bordered={false}
      title={[
        <React.Fragment key={0}>
          {selected === undefined ? (
            <h5 className="font-semibold">YTD Earning Reports</h5>
          ) : (
            <div className="t-flex t-space-x-4">
              <IoMdArrowRoundBack
                fontSize="1.5rem"
                className="t-cursor-pointer"
                onClick={() => setSelected()}
              />
              <h5 className="font-semibold">
                Ytd Earning Reports -{' '}
                <span className="t-capitalize">{selected}</span>
              </h5>
            </div>
          )}
          {/* <p className="font-regular">
            This is a list of devices that have logged into your account. Remove
            those that you do not recognize.
          </p> */}
        </React.Fragment>,
      ]}
    >
      {selected === undefined ? (
        <Skeleton loading={loading} paragraph={{ rows: 12 }}>
          {['events', 'campaigns', 'programs', 'services'].map(
            (item, index) => (
              <>
                {index !== 0 && <hr className="gradient-line my-10" />}
                <div className="ant-row-flex ant-row-flex-middle">
                  <div
                    className="text-center ant-col"
                    style={{ minWidth: '40px' }}
                  >
                    <CarryOutFilled
                      className="text-gray-6"
                      style={{ fontSize: '18px' }}
                    />
                  </div>
                  <div className="ant-col pl-15">
                    <p className="mb-0 t-capitalize">{item}</p>
                  </div>
                  <div
                    className="ml-auto ant-col ant-col-24 ant-col-md-12"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <span className="ml-5">
                      {'$ '}
                      {report && report[item] ? report[item] : '0'}
                    </span>
                    <Button
                      type="link"
                      className={`${
                        report &&
                        report[item] === 0 &&
                        't-opacity-30 t-cursor-default'
                      }`}
                      onClick={() =>
                        report && report[item] !== 0 && setSelected(item)
                      }
                    >
                      See More <ArrowRightOutlined />
                    </Button>
                  </div>
                </div>
              </>
            ),
          )}
        </Skeleton>
      ) : (
        <Single selected={selected} />
      )}
    </Card>
  )
}

function Single({ selected }) {
  const url = {
    events: 'adminReports/ytdEarningReportByEvent',
    campaigns: 'adminReports/ytdEarningReportByCampaign',
    programs: 'adminReports/ytdEarningReportByProgram',
    services: 'adminReports/ytdEarningReportByService',
  }
  const [loading, setLoading] = useState()
  const [report, setReport] = useState()
  const [page, setPage] = useState(1)

  useEffect(() => {
    setLoading(true)
    Api.get(url[selected])
      .then((res) => {
        setReport(res.results.Items)
        setLoading(false)
      })
      .catch((err) => {
        console.log('Error', err)
        setLoading(false)
      })
    // eslint-disable-next-line
  }, [])

  return (
    <Skeleton loading={loading} paragraph={{ rows: 12 }}>
      {report && report.length > 0 ? (
        <>
          {paginate(report, page).map((item, index) => (
            <>
              {index !== 0 && <hr className="gradient-line my-10" />}
              <div className="ant-row-flex ant-row-flex-middle">
                <div
                  className="text-center ant-col"
                  style={{ minWidth: '40px' }}
                >
                  <CarryOutFilled
                    className="text-gray-6"
                    style={{ fontSize: '18px' }}
                  />
                </div>
                <div className="ant-col pl-15">
                  <p className="mb-0">{item?.Title}</p>
                  <small className="text-dark">Friday, 12/03/2021</small>
                </div>
                <div
                  className="ml-auto ant-col ant-col-24 ant-col-md-12"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                  }}
                >
                  <span className="ml-5">
                    {'$ '}
                    {item?.Cost}
                  </span>
                </div>
              </div>
            </>
          ))}
          <div className="t-flex t-justify-center t-items-center t-mt-4">
            <Pagination
              showSizeChanger={false}
              defaultPageSize={config.rowsPerPage}
              current={page}
              total={report.length}
              onChange={(current) => setPage(current)}
            />
          </div>
        </>
      ) : (
        <div>No Data Found</div>
      )}
    </Skeleton>
  )
}
export default YtdEarningReports
