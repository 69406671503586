import React, { useState } from 'react'
import { Input, Button, Card, Form, Row, Col, Select, message } from 'antd'
import { useEventSelector } from 'store/eventSlice/eventReducer'
import { useParams } from 'react-router-dom'
import { submitManualCheckEntry } from 'store/eventSlice/eventActions'
import * as Yup from 'yup'

const numberValidation = new RegExp('^[0-9]+$')
const zipCodeRegex = '^[0-9]{5}(?:-[0-9]{4})?$'

const schema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  emailAddress: Yup.string()
    .required('Email address is required')
    .email('Invalid Email'),
  accountNumber: Yup.string()
    .required('Account number is required')
    .matches(numberValidation, 'Invalid account number'),
  routingNumber: Yup.string()
    .required('Routing number is required')
    .matches(numberValidation, 'Invalid routing number'),
  checkType: Yup.string().required('Check type is required'),
  city: Yup.string().required('City is required'),
  address: Yup.string().required('Address is required'),
  state: Yup.string().required('State is required'),
  zipCode: Yup.string()
    .required('Zipcode is required')
    .matches(zipCodeRegex, 'Invalid Zip code e.g 12345'),
})

const checkEntryValidation = {
  async validator({ field }, value) {
    await schema.validateSyncAt(field, { [field]: value })
  },
}

const initialValues = {}

function CheckEntry() {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const { event } = useEventSelector()
  const { id } = useParams()

  const onFinish = async (values) => {
    setLoading(true)
    try {
      const data = {
        ...values,
        eventId: id,
        eventName: event?.Title,
      }
      await submitManualCheckEntry(data)
      form.resetFields()
      message.success('Check Entry Added Successfully')
      setLoading(false)
    } catch (error) {
      setLoading(false)
      message.error('Something Went Wrong')
    }
  }

  return (
    <Card>
      <Form
        form={form}
        layout="vertical"
        key={0}
        onFinish={onFinish}
        name="organization"
        initialValues={initialValues}
        scrollToFirstError
      >
        <Row gutter={[24, 0]}>
          <Col xl={12} md={12} xs={24} sm={24}>
            <Form.Item
              name="firstName"
              label="First name"
              colon={false}
              rules={[checkEntryValidation]}
            >
              <Input placeholder="John" />
            </Form.Item>
          </Col>
          <Col xl={12} md={12} xs={24} sm={24}>
            <Form.Item
              name="lastName"
              label="Last name"
              colon={false}
              rules={[checkEntryValidation]}
            >
              <Input placeholder="Micheal" />
            </Form.Item>
          </Col>
          <Col xl={12} md={12} xs={24} sm={24}>
            <Form.Item
              name="emailAddress"
              label="Email"
              colon={false}
              rules={[checkEntryValidation]}
            >
              <Input placeholder="john.micheal@gmail.com" />
            </Form.Item>
          </Col>
          <Col xl={12} md={12} xs={24} sm={24}>
            <Form.Item
              name="accountNumber"
              label="Account number"
              colon={false}
              rules={[checkEntryValidation]}
            >
              <Input placeholder="143499344" />
            </Form.Item>
          </Col>

          <Col xl={12} md={12} xs={24} sm={24}>
            <Form.Item
              name="routingNumber"
              label="Routing Number"
              colon={false}
              rules={[checkEntryValidation]}
            >
              <Input placeholder="232323" />
            </Form.Item>
          </Col>
          <Col xl={12} md={12} xs={24} sm={24}>
            <Form.Item
              name="checkType"
              label="Check Type"
              colon={false}
              rules={[checkEntryValidation]}
            >
              <Select placeholder="Check Type" style={{ width: '100%' }}>
                <Select.Option value="Personal Check">
                  Personal Check
                </Select.Option>
                <Select.Option value="Corporate Check">
                  Corporate Check
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xl={12} md={12} xs={24} sm={24}>
            <Form.Item
              name="city"
              label="City"
              colon={false}
              rules={[checkEntryValidation]}
            >
              <Input placeholder="London" />
            </Form.Item>
          </Col>
          <Col xl={12} md={12} xs={24} sm={24}>
            <Form.Item
              name="address"
              label="Address"
              colon={false}
              rules={[checkEntryValidation]}
            >
              <Input placeholder="Bottom City, Alaska" />
            </Form.Item>
          </Col>

          <Col xl={12} md={12} xs={24} sm={24}>
            <Form.Item
              name="state"
              label="State"
              colon={false}
              rules={[checkEntryValidation]}
            >
              <Input placeholder="Alaska" />
            </Form.Item>
          </Col>
          <Col xl={12} md={12} xs={24} sm={24}>
            <Form.Item
              name="zipCode"
              label="Zipcode"
              colon={false}
              rules={[checkEntryValidation]}
            >
              <Input placeholder="03292" />
            </Form.Item>
          </Col>
          <Col span={24} className="text-left">
            <Button
              loading={loading}
              type="primary"
              className="px-25"
              htmlType="submit"
            >
              {loading ? 'Submitting ...' : 'Submit'}
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  )
}

export { CheckEntry }
