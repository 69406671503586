import React from 'react'
import {
  // DashboardOutlined,
  BankFilled,
  CarryOutFilled,
  BookFilled,
  ContactsFilled,
  SettingFilled,
  ApiOutlined,
  AreaChartOutlined,
} from '@ant-design/icons'
import config from 'configs/config'

const menuItems = {
  items: [
    {
      id: 'docs',
      title: 'Pages',
      type: 'group',
      children: [
        // {
        //   id: "dashboard",
        //   title: "Dashboard",
        //   type: "item",
        //   icon: (isSelected, color) => (
        //     <DashboardOutlined
        //       className="icon"
        //       style={{
        //         background: isSelected ? color : "",
        //       }}
        //     />
        //   ),
        //   url: "/dashboard",
        //   roles: [
        //     config.roles.admin,
        //     config.roles.donor,
        //     config.roles.presenter,
        //     config.roles.subscriber,
        //   ],
        // },
        {
          id: 'organizations',
          title: 'Organizations',
          type: 'item',
          icon: (isSelected, color) => (
            <BankFilled
              className="icon"
              style={{
                background: isSelected ? color : '',
              }}
            />
          ),
          url: '/organizations',
          roles: [
            config.roles.admin,
            config.roles.donor,
            config.roles.presenter,
            config.roles.subscriber,
          ],
        },
        {
          id: 'events',
          title: 'Events',
          type: 'item',
          icon: (isSelected, color) => (
            <CarryOutFilled
              className="icon "
              style={{
                background: isSelected ? color : '',
              }}
            />
          ),
          url: '/events',
          roles: [
            config.roles.admin,
            config.roles.donor,
            config.roles.presenter,
            config.roles.subscriber,
          ],
        },
        {
          id: 'campaigns',
          title: 'Campaigns',
          type: 'item',
          icon: (isSelected, color) => (
            <ApiOutlined
              className="icon "
              style={{
                background: isSelected ? color : '',
              }}
            />
          ),
          url: '/campaigns',
          roles: [
            config.roles.admin,
            config.roles.donor,
            config.roles.presenter,
            config.roles.subscriber,
          ],
        },
        {
          id: 'programs',
          title: 'Programs',
          type: 'item',
          icon: (isSelected, color) => (
            <BookFilled
              className="icon "
              style={{
                background: isSelected ? color : '',
              }}
            />
          ),
          url: '/programs',
          roles: [
            config.roles.admin,
            config.roles.donor,
            config.roles.presenter,
            config.roles.subscriber,
          ],
        },
        {
          id: 'services',
          title: 'Services',
          type: 'item',
          icon: (isSelected, color) => (
            <AreaChartOutlined
              className="icon "
              style={{
                background: isSelected ? color : '',
              }}
            />
          ),
          url: '/services',
          roles: [
            config.roles.admin,
            config.roles.donor,
            config.roles.presenter,
            config.roles.subscriber,
          ],
        },
        {
          id: 'volunteer',
          title: 'Volunteer',
          type: 'item',
          icon: (isSelected, color) => (
            <ContactsFilled
              className="icon "
              style={{
                background: isSelected ? color : '',
              }}
            />
          ),
          url: '/volunteer',
          roles: [
            config.roles.admin,
            config.roles.donor,
            config.roles.presenter,
            config.roles.subscriber,
          ],
        },
      ],
    },
    {
      id: 'settings-pages',
      title: 'Settings',
      className: 'd-none',
      type: 'group',
      children: [
        {
          id: 'das',
          title: <span className="label">Settings</span>,
          type: 'collapse',
          icon: (isSelected, color) => (
            <SettingFilled
              className="icon"
              style={{
                background: isSelected ? color : '',
              }}
            />
          ),
          children: [
            {
              id: 'account',
              title: 'My Account',
              type: 'item',
              url: '/settings/account',
              roles: [
                config.roles.admin,
                config.roles.presenter,
                config.roles.donor,
                config.roles.subscriber,
              ],
            },
            {
              id: 'invites',
              title: 'My Invites',
              type: 'item',
              url: '/settings/invites',
              roles: [config.roles.subscriber],
            },
            {
              id: 'register-organization',
              title: 'Register Organization',
              type: 'item',
              url: '/settings/register-organization',
              roles: [config.roles.donor, config.roles.subscriber],
            },
            {
              id: 'create-event',
              title: 'Create Event',
              type: 'item',
              url: '/settings/create-event',
              roles: [config.roles.subscriber],
            },
            {
              id: 'create-program',
              title: 'Create Program',
              type: 'item',
              url: '/settings/create-program',
              roles: [config.roles.subscriber],
            },
            {
              id: 'create-a-service',
              title: 'Create Service',
              type: 'item',
              url: '/settings/create-a-service',
              roles: [config.roles.subscriber],
            },
            {
              id: 'create-volunteer',
              title: 'Create Volunteer',
              type: 'item',
              url: '/settings/create-volunteer',
              roles: [config.roles.subscriber],
            },
            {
              id: 'manage-organization',
              title: 'My Organization',
              type: 'item',
              url: '/settings/manage-organization',
              roles: [config.roles.subscriber],
            },
            {
              id: 'manage-payments-accounts',
              title: 'Receive Payments',
              type: 'item',
              url: '/settings/manage-payments-accounts',
              roles: [
                config.roles.subscriber,
                config.roles.donor,
                config.roles.presenter,
              ],
            },
            {
              id: 'manage-gov-issued-docs',
              title: 'Manage Gov Docs',
              type: 'item',
              url: '/settings/manage-gov-issued-docs',
              roles: [config.roles.admin],
            },
          ],
        },
      ],
    },
  ],
}

export { menuItems }
