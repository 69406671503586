import React from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import { Auth, PageNotFound, Splash } from 'screens'
import { Main } from 'components/layouts'
import { useAuthSelector } from 'store/authSlice/authReducer'

export function AppRoutes() {
  const { user } = useAuthSelector((state) => state)

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/auth" exact component={Auth} />
        <Route path="/welcome" exact component={Splash} />
        <Route path="/404" exact component={PageNotFound} />
        <Route path="/:id" component={Main} />
        {user ? (
          <Redirect to={{ pathname: '/organizations' }} />
        ) : (
          <Redirect to={{ pathname: '/auth' }} />
        )}
      </Switch>
    </BrowserRouter>
  )
}
