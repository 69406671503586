import React, { useState } from 'react'
import { Modal, Row, Tabs } from 'antd'
import { FaDonate } from 'react-icons/fa'
import { useEventSelector } from 'store/eventSlice/eventReducer'
import { useDispatch } from 'react-redux'
import { getEventControlScreenDetails } from 'store/eventSlice/eventActions'
import { BankDebit, CreditCard, Cash, ECheck, Paypal } from './components'

function DonateEvent() {
  const [visible, setVisible] = useState(false)
  const dispatch = useDispatch()
  const { event, joinedEventDetails } = useEventSelector()

  const handleOk = () => {}

  const refreshEventData = () => {
    dispatch(getEventControlScreenDetails(event?.Id))
  }

  return (
    <>
      <div
        className="t-flex t-flex-col t-justify-center t-items-center t-space-y-2 t-cursor-pointer"
        onClick={() => setVisible(true)}
      >
        <FaDonate
          // fontSize={"2rem"}
          className="t-text-primary-100 t-text-center t-text-lg md:t-text-2xl"
        />
        <h6 className="t-text-sm md:t-text-md t-font-bold">Donate</h6>
      </div>
      <Modal
        centered
        title="Donate Event"
        visible={visible}
        onCancel={() => setVisible(false)}
        onOk={handleOk}
        okText="Donate"
        footer={null}
      >
        <Row className="modal_height_mobile md:modal_height_desktop t-flex t-justify-center t-overflow-scroll">
          <Row className="t-w-full">
            <Tabs
              destroyInactiveTabPane
              className="t-w-full sliding-tab"
              defaultActiveKey="1"
              animated={false}
            >
              <Tabs.TabPane tab="Credit Card" key="1">
                <CreditCard refreshEventData={refreshEventData} />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Bank Debit" key="2">
                <BankDebit refreshEventData={refreshEventData} />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Paypal" key="3">
                <Paypal refreshEventData={refreshEventData} />
              </Tabs.TabPane>
              <Tabs.TabPane tab="eCheck" key="4">
                <ECheck refreshEventData={refreshEventData} />
              </Tabs.TabPane>
              {!joinedEventDetails?.isRemote && (
                <Tabs.TabPane tab="Cash" key="5">
                  <Cash refreshEventData={refreshEventData} />
                </Tabs.TabPane>
              )}
            </Tabs>
          </Row>
        </Row>
      </Modal>
    </>
  )
}

export { DonateEvent }
