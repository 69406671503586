import { createSlice } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'

const initialState = {
  loading: false,
  organization: null,
  organizations: [],
}

export const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      const alteredState = { ...state, loading: payload }
      return alteredState
    },
    setOrganization: (state, { payload }) => {
      const alteredState = { ...state, organization: payload }
      return alteredState
    },
    setOrganizations: (state, { payload }) => {
      const alteredState = { ...state, organizations: payload }
      return alteredState
    },
  },
})

export const useOrganizationSelector = () =>
  useSelector((state) => state.organization)
export const organizationActions = organizationSlice.actions
export const organizationReducer = organizationSlice.reducer
