import React, { useState, useEffect } from 'react'
import { Typography, Row, Button, message, Popconfirm } from 'antd'
import { AiFillCheckCircle } from 'react-icons/ai'
import { RiErrorWarningFill } from 'react-icons/ri'
import { authActions, useAuthSelector } from 'store/authSlice/authReducer'
import {
  LinkStripeAccount,
  UnlinkStripeAccount,
  saveStripeID,
} from 'store/paymentSlice/paymentActions'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { DeleteFilled } from '@ant-design/icons'

const { Title, Paragraph } = Typography

function ConnectStripe({ refreshPayment }) {
  const history = useHistory()
  const dispatch = useDispatch()
  const { payment, user } = useAuthSelector()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const storeStripeID = async (ACCOUNT_ID) => {
      try {
        dispatch(authActions.setLoading(true))
        await saveStripeID(ACCOUNT_ID, user.email)
        refreshPayment()
      } catch (error) {
        message.error('Something Went Wrong')
      }
    }
    const urlSearchParams = new URLSearchParams(window.location.search)
    const params = Object.fromEntries(urlSearchParams.entries())
    if (Object.entries(params).length > 0) {
      if (params.account_id) {
        storeStripeID(params.account_id)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, user])

  const linkStripe = async () => {
    try {
      setLoading(true)
      const response = await LinkStripeAccount()
      window.open(response, '_self')
    } catch (error) {
      setLoading(false)
      message.error('Something Went Wrong')
    }
  }

  const unlinkStripe = async () => {
    try {
      setLoading(true)
      await UnlinkStripeAccount(payment.stripe.id, user.email)
      refreshPayment()
      setLoading(false)
      message.success('Stripe Account Disconnectd')
    } catch (error) {
      setLoading(false)
      message.error('Something Went Wrong')
    }
  }

  return (
    <>
      <Row className="t-flex t-items-center t-space-x-3">
        <Title level={5}>Stripe Account</Title>
        {payment?.stripe?.isConnected ? (
          <AiFillCheckCircle
            fontSize="1.7rem"
            className="t-text-green-600 t-cursor-pointer t-mb-2"
          />
        ) : (
          <RiErrorWarningFill
            fontSize="1.7rem"
            className="t-text-gray-400 t-cursor-pointer t-mb-2"
          />
        )}
      </Row>
      <Paragraph>
        You can accept credit cards payment through Stripe. Click Below Button
        to connect with Stripe Account
      </Paragraph>
      <Row className="t-space-x-4 t-flex t-items-center">
        {payment?.stripe?.isConnected ? (
          <Popconfirm
            title="Are you sure to Disconnect Your Stripe Account？"
            placement="top"
            icon={
              <DeleteFilled
                className="t-text-red-500"
                style={{ fontSize: '1rem' }}
              />
            }
            loading={loading}
            onConfirm={unlinkStripe}
            okText="Delete"
            cancelText="Cancel"
          >
            <Button
              type="primary"
              size="small"
              className="t-bg-red-500 t-border-0"
              loading={loading}
            >
              Disconnect Stripe Account
            </Button>
          </Popconfirm>
        ) : (
          <Button
            size="small"
            type="primary"
            onClick={linkStripe}
            loading={loading}
          >
            Link Stripe Account
          </Button>
        )}
      </Row>
    </>
  )
}

export { ConnectStripe }
