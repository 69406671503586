/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Tabs, Card, Row, Col, Anchor, Affix } from 'antd'
import { AppstoreFilled } from '@ant-design/icons'
import YtdEarningReports from './reports/YtdEarningReports'
import LastYearEarningsReport from './reports/LastYearEarningsReport'
import PledgeConversionReports from './reports/PledgeConversionReports'
import FYEarningsForecastReport from './reports/FYEarningsForecastReport'
import AnnualCosts from './reports/AnnualCosts'
import MasterDonorListReport from './reports/MasterDonorListReport'
import ActiveDonorsReport from './reports/ActiveDonorsReport'
import DormantDonorsReport from './reports/DormantDonorsReport'
import Top50DonorsReport from './reports/Top50DonorsReport'
import EventReports from './reports/EventReports'

const { TabPane } = Tabs

function callback() {
  // console.log(key);
}

const { Link } = Anchor

function Reports() {
  return (
    <>
      <Tabs
        className="tabs-sliding"
        // animated={false}
        defaultActiveKey="1"
        onChange={callback}
      >
        <TabPane tab="Financial Report" key="1">
          <Row gutter={[24, 24]}>
            <Col lg={8} span={24}>
              <Affix offsetTop={10}>
                <Card className="header-solid" bordered={false}>
                  <Anchor className="fixed" affix={false}>
                    <Link
                      href="#YtdEarningReports"
                      title={[
                        <div className="ant-anchor-link-menu" key={0}>
                          <AppstoreFilled className="text-lg text-gray-6" />
                          <h4>YTD Earnings Reports</h4>
                        </div>,
                      ]}
                    />
                    <Link
                      href="#FYEarningsForecastReport"
                      title={[
                        <div className="ant-anchor-link-menu" key={0}>
                          <AppstoreFilled className="text-lg text-gray-6" />
                          <h4>Current Year Forecast</h4>
                        </div>,
                      ]}
                    />
                    <Link
                      href="#LastYearEarningsReport"
                      title={[
                        <div className="ant-anchor-link-menu" key={0}>
                          <AppstoreFilled className="text-lg text-gray-6" />
                          <h4>Last Year Earnings Report</h4>
                        </div>,
                      ]}
                    />
                    <Link
                      href="#PledgeConversionReports"
                      title={[
                        <div className="ant-anchor-link-menu" key={0}>
                          <AppstoreFilled className="text-lg text-gray-6" />
                          <h4>Pledge Conversion Reports</h4>
                        </div>,
                      ]}
                    />
                    <Link
                      href="#AnnualCosts"
                      title={[
                        <div className="ant-anchor-link-menu" key={0}>
                          <AppstoreFilled className="text-lg text-gray-6" />
                          <h4>Annual Costs</h4>
                        </div>,
                      ]}
                    />
                  </Anchor>
                </Card>
              </Affix>
            </Col>
            <Col span={24} lg={16} id="my-scroll-layout">
              <YtdEarningReports />
              <FYEarningsForecastReport />
              <LastYearEarningsReport />
              <PledgeConversionReports />
              <AnnualCosts />
            </Col>
          </Row>
        </TabPane>
        <TabPane tab="Donor Reports" key="2">
          <Row gutter={[24, 24]}>
            <Col lg={8} span={24}>
              <Affix offsetTop={10}>
                <Card className="header-solid" bordered={false}>
                  <Anchor className="fixed" affix={false}>
                    <Link
                      href="#MasterDonorListReport"
                      title={[
                        <div className="ant-anchor-link-menu" key={0}>
                          <AppstoreFilled className="text-lg text-gray-6" />
                          <h4>Master Donor List Report</h4>
                        </div>,
                      ]}
                    />
                    <Link
                      href="#ActiveDonorsReport"
                      title={[
                        <div className="ant-anchor-link-menu" key={0}>
                          <AppstoreFilled className="text-lg text-gray-6" />
                          <h4>Active Donors Report</h4>
                        </div>,
                      ]}
                    />
                    <Link
                      href="#DormantDonorsReport"
                      title={[
                        <div className="ant-anchor-link-menu" key={0}>
                          <AppstoreFilled className="text-lg text-gray-6" />
                          <h4>Dormant Donors Report</h4>
                        </div>,
                      ]}
                    />
                    <Link
                      href="#Top50DonorsReport"
                      title={[
                        <div className="ant-anchor-link-menu" key={0}>
                          <AppstoreFilled className="text-lg text-gray-6" />
                          <h4>Top 50 Donors Report</h4>
                        </div>,
                      ]}
                    />
                  </Anchor>
                </Card>
              </Affix>
            </Col>
            <Col span={24} lg={16} id="my-scroll-layout">
              <MasterDonorListReport />
              <ActiveDonorsReport />
              <DormantDonorsReport />
              <Top50DonorsReport />
            </Col>
          </Row>
        </TabPane>
        <TabPane tab="Events Reports" key="3">
          <EventReports />
        </TabPane>
      </Tabs>
    </>
  )
}

export { Reports }
