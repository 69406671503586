import React, { useEffect, useState } from 'react'
import { Card, Row, Col, Descriptions, Button, Skeleton } from 'antd'
import {
  EditFilled,
  CloseOutlined,
  CheckOutlined,
  UndoOutlined,
} from '@ant-design/icons'
import { getUserOrganization } from 'store/organizationSlice/organizationActions'
import { useHistory } from 'react-router-dom'
import { useAuthSelector } from 'store/authSlice/authReducer'

const renderStatus = (type) => {
  switch (type) {
    case 'Active':
      return (
        <>
          <Button
            shape="circle"
            size="small"
            className="btn-status border-success mr-10"
          >
            <CheckOutlined
              className="text-success"
              style={{ fontSize: '10px' }}
            />
          </Button>
          <span style={{ verticalAlign: 'bottom' }}>Active</span>
        </>
      )
    case 'Disabled':
      return (
        <>
          <Button
            shape="circle"
            size="small"
            className="btn-status t-border-1 t-border-yellow-400 mr-10"
          >
            <CloseOutlined
              className="text-warning"
              style={{ fontSize: '10px' }}
            />
          </Button>
          <span style={{ verticalAlign: 'bottom' }}>Disabled</span>
        </>
      )
    case 'Suspended':
      return (
        <>
          <Button
            shape="circle"
            size="small"
            className="btn-status border-danger mr-10"
          >
            <CloseOutlined
              className="text-danger"
              style={{ fontSize: '10px' }}
            />
          </Button>
          <span style={{ verticalAlign: 'bottom' }}>Suspended</span>
        </>
      )
    case 'Pending':
      return (
        <>
          <Button
            shape="circle"
            size="small"
            className="btn-status border-muted mr-10"
          >
            <UndoOutlined className="text-muted" style={{ fontSize: '10px' }} />
          </Button>
          <span style={{ verticalAlign: 'bottom' }}>Pending</span>
        </>
      )
    default:
      return (
        <>
          <Button
            shape="circle"
            size="small"
            className="btn-status border-success mr-10"
          >
            <CheckOutlined
              className="text-success"
              style={{ fontSize: '10px' }}
            />
          </Button>
          <span style={{ verticalAlign: 'bottom' }}>Active</span>
        </>
      )
  }
}

function MyOrganizations() {
  const { user } = useAuthSelector()
  const [org, setOrganization] = useState(null)
  const [loading, setLoading] = useState(false)
  const history = useHistory()

  useEffect(() => {
    const getData = async () => {
      setLoading(true)
      const response = await getUserOrganization(user?.email)
      setOrganization(response)
      setLoading(false)
    }
    getData()
  }, [user])

  return (
    <Skeleton loading={loading} active paragraph={{ rows: 12 }}>
      {!org && (
        <Card className="t-w-full t-h-72 t-flex t-justify-center t-items-center">
          No Organization Added
        </Card>
      )}
      <Card
        title={[
          <Row
            style={{ display: 'flex', justifyContent: 'space-between' }}
            className="t-space-y-2"
          >
            <Col className="t-space-y-2">
              <h6 className="mb-0 font-bold">{org?.Name}</h6>
              <div>{renderStatus(org?.OrgStatus)}</div>
            </Col>
            <EditFilled
              className="t-cursor-pointer t-text-primary-100"
              style={{ fontSize: '1.5rem' }}
              onClick={() =>
                history.push(`/settings/register-organization/${org?.id}`)
              }
            />
          </Row>,
        ]}
        className="t-mb-5"
      >
        <Descriptions>
          <Descriptions.Item label="Organiation Id" span={3}>
            {org?.id}
          </Descriptions.Item>
          <Descriptions.Item label="Type of Organization" span={3}>
            {org?.OrganizationType}
          </Descriptions.Item>
          <Descriptions.Item label="Email" span={3}>
            {org?.Email}
          </Descriptions.Item>
          <Descriptions.Item label="Phone Number" span={3}>
            {org?.PhoneNumber}
          </Descriptions.Item>
          <Descriptions.Item label="Number and Street" span={3}>
            {org?.Address1}
          </Descriptions.Item>
          <Descriptions.Item label="Suite" span={3}>
            {org?.Address2}
          </Descriptions.Item>
          <Descriptions.Item label="City" span={3}>
            {org?.City}
          </Descriptions.Item>
          <Descriptions.Item label="ZipCode" span={3}>
            {org?.ZipCode}
          </Descriptions.Item>
          <Descriptions.Item label="Tax-ID" span={3}>
            {org?.TaxId}
          </Descriptions.Item>
          <Descriptions.Item label="Non-Profit" span={3}>
            {org?.NonProfit ? 'True' : 'False'}
          </Descriptions.Item>
          <Descriptions.Item label="Funded Outside" span={3}>
            {org?.FundedOutside ? 'True' : 'False'}
          </Descriptions.Item>
          <Descriptions.Item label="Website" span={3}>
            {org?.Website}
          </Descriptions.Item>
          <Descriptions.Item
            label="Description"
            span={3}
            className="t-text-justify"
          >
            {org?.Description}
          </Descriptions.Item>
        </Descriptions>
      </Card>
    </Skeleton>
  )
}

export { MyOrganizations }
