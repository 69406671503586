import * as Yup from 'yup'

const zipCodeRegex = '^[0-9]{5}(?:-[0-9]{4})?$'
const websiteRegex = new RegExp(
  '^(https?:\\/\\/)?' +
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
    '((\\d{1,3}\\.){3}\\d{1,3}))' +
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
    '(\\?[;&a-z\\d%_.~+=-]*)?' +
    '(\\#[-a-z\\d_]*)?$',
  'i',
)
const phoneNumberValidation = '^[0-9]{10}$'

const numberValidation = new RegExp('^[0-9]+$')

const schema = Yup.object().shape({
  Title: Yup.string().required('Event title is required'),
  EventType: Yup.string().required('Event type is required'),
  zipCode: Yup.string()
    .required('Zip code is required')
    .matches(zipCodeRegex, 'Invalid Zip code e.g 12345'),
  TargetAmount: Yup.string()
    .required('Target amount is required')
    .matches(numberValidation, 'Invalid amount'),
  YoutubeLink: Yup.string()
    .required('Youtube link is required')
    .matches(
      websiteRegex,
      'Invalid website url e.g https://www.youtube.com/watch?v=Ke90Tje7VS0',
    ),
  SpeakerName: Yup.string().required('Speaker name is required'),
  SpeakerBio: Yup.string()
    .required('Speaker bio is required')
    .min(50, 'Minimum 50 character'),
  monthlyPaymentAllowed: Yup.boolean().required('Field is Required'),
  monthlyPaymentMonths: Yup.string()
    .required('Amount is Required')
    .test((val) => parseInt(val, 10) > 1 && parseInt(val, 10) <= 12)
    .matches(numberValidation, 'Invalid amount'),
  splitPaymentAllowed: Yup.boolean().required('Field is Required'),
  splitPaymentMonths: Yup.string()
    .required('Amount is Required')
    .test((val) => parseInt(val, 10) > 1 && parseInt(val, 10) <= 12)
    .matches(numberValidation, 'Invalid amount'),
  EditAskedAmount: Yup.string().required('Edit asked amount is required'),
  Details: Yup.string()
    .required('Event Description is required')
    .min(50, 'Minimum 50 character'),
  Presenter: Yup.string().required('Presenter is required'),
  PresenterName: Yup.string().required('Presenter name is required'),
  PresenterPhoneCode: Yup.string().required('Presenter phone code is required'),
  PresenterMobileNumber: Yup.string().matches(
    phoneNumberValidation,
    'Invalid Phone number e.g 1234567891',
  ),
  PresenterEmail: Yup.string()
    .required('Presenter email is required')
    .email('Email is invalid'),
  OrganizationId: Yup.string().required('Organization id is required'),
  refLink: Yup.string().required('Ref link is required'),
  Flyer: Yup.mixed().typeError('Flyer is not a file'),
  Presentation: Yup.mixed().typeError('Presentation File is not a file'),
  StartDate: Yup.date()
    .typeError('Invalid Date')
    .required('StartDate is required'),
  EndDate: Yup.date().typeError('Invalid Date').required('EndDate is required'),
  recaptcha: Yup.string()
    .typeError('Please complete recaptcha to proceed')
    .required('Please complete recaptcha to proceed'),
})

const eventValidation = {
  async validator({ field }, value) {
    await schema.validateSyncAt(field, { [field]: value })
  },
}

export { eventValidation }
