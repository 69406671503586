import React, { useEffect, useState } from 'react'
import { Input, Button, Card, Form, Row, Col, DatePicker } from 'antd'
import { volunteerValidation } from 'validations'
import { useVolunteerSelector } from 'store/volunteerSlice/volunteerReducer'
import { useDispatch } from 'react-redux'
import {
  addVolunteer,
  getVolunteerDetail,
  updateVolunteer,
} from 'store/volunteerSlice/volunteerActions'
import { useHistory, useParams } from 'react-router-dom'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { EditOutlined } from '@ant-design/icons'

const { TextArea } = Input

const initialValues = {
  Title: '',
  Description: '',
  Schedule: '',
  ZipCode: '',
  City: '',
  LocationState: '',
  Address: '',
  Notes: '',
}

function CreateVolunteer() {
  const { organization } = useAuthSelector()
  const { loading } = useVolunteerSelector()
  const dispatch = useDispatch()
  const history = useHistory()
  const [form] = Form.useForm()
  const { id } = useParams()
  const [editZipcode, setEditZipcode] = useState(false)

  useEffect(() => {
    if (organization && organization?.ZipCode) {
      form.setFieldsValue({ ZipCode: organization.ZipCode })
    }
    // eslint-disable-next-line
  }, [organization])

  useEffect(() => {
    if (id) {
      dispatch(getVolunteerDetail(id, form))
    }
    // eslint-disable-next-line
  }, [dispatch, id])

  const resetForm = () => {
    form.resetFields()
  }

  const navigation = () => {
    history.push('/volunteer')
  }

  const onFinish = (values) => {
    if (id) {
      dispatch(updateVolunteer({ id, ...values }, navigation))
    } else {
      dispatch(addVolunteer(values, resetForm))
    }
  }

  return (
    <Card
      className="header-solid"
      bordered={false}
      title={[
        <>
          <h6 className="mb-0">{id ? 'Update Volunteer' : 'Add Volunteer'}</h6>
          <p className="font-regular">{id || 'Enter Information'}</p>
        </>,
      ]}
    >
      <div style={{ minHeight: 'calc(100vh - 220px)' }}>
        <Form
          form={form}
          layout="vertical"
          key={0}
          onFinish={onFinish}
          name="organization"
          initialValues={initialValues}
          scrollToFirstError
        >
          <Row gutter={[24, 0]}>
            <Col xl={12} md={12} xs={24} sm={24}>
              <Form.Item
                name="Title"
                label="Title"
                colon={false}
                rules={[volunteerValidation]}
              >
                <Input placeholder="Ente Title" />
              </Form.Item>
            </Col>
            <Col xl={12} md={12} xs={24} sm={24}>
              <Form.Item
                name="Schedule"
                label="Schedule"
                colon={false}
                rules={[volunteerValidation]}
              >
                <DatePicker
                  format="DD-MM-YYYY hh:mm A"
                  showTime
                  className="t-w-full"
                />
              </Form.Item>
            </Col>
            <Col xl={8} md={8} xs={24} sm={24}>
              <Form.Item
                name="ZipCode"
                label="Zipcode"
                colon={false}
                rules={[volunteerValidation]}
              >
                <Input
                  placeholder="Enter Zipcode"
                  suffix={
                    <EditOutlined
                      style={{ color: '#000000' }}
                      onClick={() => setEditZipcode(!editZipcode)}
                    />
                  }
                  disabled={!editZipcode}
                />
              </Form.Item>
            </Col>
            <Col xl={8} md={8} xs={24} sm={24}>
              <Form.Item
                name="City"
                label="City"
                colon={false}
                rules={[volunteerValidation]}
              >
                <Input placeholder="Enter City Name" />
              </Form.Item>
            </Col>
            <Col xl={8} md={8} xs={24} sm={24}>
              <Form.Item
                name="LocationState"
                label="State"
                colon={false}
                rules={[volunteerValidation]}
              >
                <Input placeholder="Enter State Name" />
              </Form.Item>
            </Col>
            <Col xl={24} md={24} xs={24} sm={24}>
              <Form.Item
                name="Address"
                label="Address"
                colon={false}
                rules={[volunteerValidation]}
              >
                <Input placeholder="Enter Address" />
              </Form.Item>
            </Col>
            <Col xl={24} md={24} xs={24} sm={24}>
              <Form.Item
                name="Description"
                label="Description"
                colon={false}
                rules={[volunteerValidation]}
              >
                <TextArea
                  placeholder="Enter Description"
                  autoSize={{ minRows: 3, maxRows: 5 }}
                />
              </Form.Item>
            </Col>

            <Col xl={24} md={24} xs={24} sm={24}>
              <Form.Item
                name="Notes"
                label="Notes"
                colon={false}
                rules={[volunteerValidation]}
              >
                <TextArea
                  placeholder="Enter Notes"
                  autoSize={{ minRows: 3, maxRows: 5 }}
                />
              </Form.Item>
            </Col>
            <Col span={24} className="text-left">
              <Button
                loading={loading}
                type="primary"
                className="px-25"
                htmlType="submit"
              >
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </Card>
  )
}

export { CreateVolunteer }
