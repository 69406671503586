import { Auth } from 'aws-amplify'
import config from 'configs/config'

const awsconfig = {
  identityPoolId: 'us-east-2:38f662ef-ccd6-4d03-97c8-1205ab88785b',
  region: 'us-east-2',
  paymentHashUrl:
    'https://eq8wcmr7jb.execute-api.us-east-2.amazonaws.com/TestStage/getpaymenthash',
  signUrl:
    'https://eq8wcmr7jb.execute-api.us-east-2.amazonaws.com/TestStage/presignedurl',
  uploadUrl: 'https://s3.us-east-2.amazonaws.com/pledgegardentest',
  imageUrl: 'https://d2vns28tgp6o27.cloudfront.net/',
  userPoolId: 'us-east-2_1f8YQ1brF',
  userPoolWebClientId: '6ro8i91j19l1fvc42q13jqvltv',
  aws_appsync_graphqlEndpoint:
    'https://oo2nnwzy2fh4znwgsa6cxmha3e.appsync-api.us-east-2.amazonaws.com/graphql',
  aws_appsync_region: 'us-east-2',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_appsync_apiKey: 'da2-4kiftsd6cfdtrn3lyc72vhbefa',
  oauth: {
    domain: 'dev-pledgarden.auth.us-east-2.amazoncognito.com',
    scope: [
      'phone',
      'email',
      'profile',
      'openid',
      'aws.cognito.signin.user.admin',
    ],
    redirectSignIn: 'pledge://', // or 'exp://127.0.0.1:19000/--/', 'myapp://main/'
    redirectSignOut: 'pledge://', // or 'exp://127.0.0.1:19000/--/', 'myapp://main/'
  },
  API: {
    endpoints: [
      {
        name: 'pg-api-v1',
        endpoint: config.endpoint,
        custom_header: async () => ({
          Authorization: `Bearer ${(await Auth.currentSession())
            .getAccessToken()
            .getJwtToken()}`,
          idtoken: `${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
        }),
      },
    ],
  },
}

export { awsconfig }
