import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Row, Skeleton, Avatar, Spin } from 'antd'
import { BankFilled } from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import { getNearbyOrganizationsByZip } from 'store/organizationSlice/organizationActions'
import { fetchMoreData, searchRows } from 'utils'
import { config } from 'configs'
import {
  organizationActions,
  useOrganizationSelector,
} from 'store/organizationSlice/organizationReducer'
import { useHistory } from 'react-router-dom'
import { useAuthSelector } from 'store/authSlice/authReducer'
import InfiniteScroll from 'react-infinite-scroll-component'

function OrganizationList({ searchText }) {
  const dispatch = useDispatch()
  const history = useHistory()

  const { location } = useAuthSelector()
  const { loading, organizations } = useOrganizationSelector()
  const [hasMore, setHasMore] = useState(true)
  const [current, setCurrent] = useState([])

  useEffect(() => {
    setCurrent(organizations.slice(0, config.itemFirstLoad))
    // eslint-disable-next-line
  }, [organizations])

  useEffect(() => {
    const zipCode = location?.zipcode
    if (zipCode) {
      dispatch(getNearbyOrganizationsByZip(zipCode))
    }
  }, [dispatch, location])

  const handleOrganizationSelected = (organization) => {
    dispatch(organizationActions.setOrganization(organization))
    history.push(`organizations/${organization.id}`)
  }

  return (
    <Skeleton loading={loading} active paragraph={{ rows: 18 }}>
      <div
        className="t-flex t-flex-col t-justify-between t-h-full"
        style={{ minHeight: 'calc(100vh - 270px)' }}
      >
        <InfiniteScroll
          dataLength={searchRows(current, searchText).length}
          next={() =>
            fetchMoreData(current, setCurrent, setHasMore, organizations)
          }
          hasMore={hasMore}
          loader={
            <div className="t-flex t-justify-center t-items-center">
              <Spin />
            </div>
          }
        >
          <Row gutter={[24, 24]} className="t-h-full">
            {searchRows(current, searchText).length === 0 && (
              <div
                className="t-h-96 t-flex t-justify-center t-items-center t-w-full"
                style={{ minHeight: 'calc(100vh - 300px)' }}
              >
                <div className="t-flex t-flex-col t-items-center t-space-y-2">
                  <BankFilled
                    style={{ fontSize: '2rem' }}
                    className="t-text-primary-100"
                  />
                  <p>No organizations</p>
                </div>
              </div>
            )}
            {searchRows(current, searchText).map((item, index) => (
              <Col span={24} lg={8} className="mb-24" key={index}>
                <Card
                  bodyStyle={{ paddingTop: '14px' }}
                  className="card-project-2 header-solid"
                  title={[
                    <Row gutter={[24, 24]} key={0}>
                      <Col>
                        <Avatar
                          src={<BankFilled />}
                          style={{
                            width: '40px',
                            height: '40px',
                            lineHeight: '74px',
                            borderRadius: '8px',
                            padding: '16px',
                            backgroundColor: '#103150',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        />
                      </Col>
                      <Col className="t-flex t-justify-between t-flex-col">
                        <h6 className="t-font-bold t-text-2lg t-capitalize t-text-primary-100">
                          {item?.Name || 'No Title Found'}
                        </h6>
                        <h6 className="t-font-normal t-text-xs t-capitalize">
                          {`${item.Address1}, ${item.City}, ${item.State}`}
                        </h6>
                      </Col>
                    </Row>,
                  ]}
                >
                  <p className="t-line-clamp-2 t-text-sm t-h-10">
                    {item?.Description}
                  </p>
                  <hr />
                  <div className="t-flex t-justify-end t-mt-2">
                    <Button
                      type="link"
                      size="small"
                      className="t-rounded-full"
                      onClick={() => handleOrganizationSelected(item)}
                    >
                      See More
                    </Button>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        </InfiniteScroll>
      </div>
    </Skeleton>
  )
}

export { OrganizationList }
