import * as Yup from 'yup'

const numberValidation = new RegExp('^[0-9]+$')
const zipCodeRegex = '^[0-9]{5}(?:-[0-9]{4})?$'

const schema = Yup.object().shape({
  Title: Yup.string().required('Title is required'),
  Description: Yup.string()
    .required('Description is required')
    .min(10, 'Minimum 10 character'),
  Requirements: Yup.string(),
  Cost: Yup.string()
    .required('Program Dues is required')
    .matches(numberValidation, 'Invalid cost'),
  LocationType: Yup.string().required('Location Type is required'),
  ZipCode: Yup.string()
    .matches(zipCodeRegex, 'Invalid Zip code e.g 12345')
    .required('Zip Code is required'),
  City: Yup.string().required('City is required'),
  LocationState: Yup.string().required('State is required'),
  Address: Yup.string().required('Address is required'),
  Notes: Yup.string(),
  AllowedRegistration: Yup.string()
    .required('Allowed Registration is required')
    .matches(numberValidation, 'Invalid number'),
})

const programValidation = {
  async validator({ field }, value) {
    await schema.validateSyncAt(field, { [field]: value })
  },
}

export { programValidation }
