import React, { useEffect } from 'react'
import { Card, Col, Row, Dropdown, Menu, Skeleton, Tag } from 'antd'
import { IoIosArrowDropdownCircle } from 'react-icons/io'
import { useDispatch } from 'react-redux'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { useEventSelector } from 'store/eventSlice/eventReducer'
import {
  acceptEventInvite,
  getNearByEvents,
  rejectEventInvite,
} from 'store/eventSlice/eventActions'

function MyInvites() {
  const dispatch = useDispatch()

  const { loading, events } = useEventSelector()
  const { user, location } = useAuthSelector()
  const positionData = {
    lat: location?.lat,
    lon: location?.lng,
    zipCode: location?.zipcode,
  }

  useEffect(() => {
    dispatch(getNearByEvents(positionData))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onAcceptClick = (eventId) => {
    dispatch(acceptEventInvite(eventId, positionData))
  }

  const onRejectClick = (eventId) => {
    dispatch(rejectEventInvite(eventId, positionData))
  }

  const customMenu = (eventId) => (
    <Menu>
      <Menu.Item key={1} onClick={() => onAcceptClick(eventId)}>
        Accept Invitation
      </Menu.Item>
      <Menu.Item key={2} onClick={() => onRejectClick(eventId)}>
        Reject Invitation
      </Menu.Item>
    </Menu>
  )

  const PRESENTER_EVENTS = events.filter(
    (event) =>
      event?.EventType === 'Fundraiser' && event?.PresenterEmail === user.email,
  )

  return (
    <Card
      className="header-solid"
      bordered={false}
      title={[
        <>
          <h6 className="mb-0 t-text-2xl">My Invites</h6>
          <h6 className="font-regular">
            You are invited to host the following events
          </h6>
        </>,
      ]}
    >
      <div style={{ minHeight: 'calc(100vh - 250px)' }}>
        <Skeleton loading={loading} active paragraph={{ rows: 18 }}>
          <Col span={24}>
            {PRESENTER_EVENTS.length === 0 && (
              <Card className="t-h-96 t-w-full t-flex t-justify-center t-items-center">
                <div>No Invites</div>
              </Card>
            )}
            {PRESENTER_EVENTS.map((item, index) => (
              <Card key={index} className="w-full t-my-2 t-p-2">
                <Row gutter={[24, 0]} className="t-flex t-justify-between">
                  <div className="font-bold t-flex t-space-x-4">
                    <div>
                      Organization{' '}
                      <span className="t-italic t-text-primary-100">
                        {item?.OrganizationName}
                      </span>{' '}
                      invited you to present the event{' '}
                      <span className="t-italic t-text-primary-100">
                        {item?.Title}
                      </span>
                    </div>
                    <div>
                      {item?.AcceptanceStatus === 'Accepted' && (
                        <Tag color="green" className="font-bold">
                          Accepted
                        </Tag>
                      )}
                      {item?.AcceptanceStatus === 'Rejected' && (
                        <Tag color="red" className="font-bold">
                          Rejected
                        </Tag>
                      )}
                      {(item?.AcceptanceStatus === 'Pending' ||
                        !item?.AcceptanceStatus) && (
                        <Tag color="gray" className="font-bold">
                          Pending
                        </Tag>
                      )}
                    </div>
                  </div>

                  <Dropdown overlay={customMenu(item?.Id)}>
                    <IoIosArrowDropdownCircle
                      fontSize="1.5rem"
                      className="t-text-primary-100 t-cursor-pointer"
                    />
                  </Dropdown>
                </Row>
              </Card>
            ))}
          </Col>
        </Skeleton>
      </div>
    </Card>
  )
}

export { MyInvites }
