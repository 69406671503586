import * as Yup from 'yup'

const zipCodeRegex = '^[0-9]{5}(?:-[0-9]{4})?$'
const taxIdRegex =
  '^((?!11-1111111)(?!22-2222222)(?!33-3333333)(?!44-4444444)(?!55-5555555)(?!66-6666666)(?!77-7777777)(?!88-8888888)(?!99-9999999)(?!12-3456789)(?!00-[0-9]{7})([0-9]{2}-[0-9]{7}))*$'
const websiteRegex = new RegExp(
  '^(https?:\\/\\/)?' +
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
    '((\\d{1,3}\\.){3}\\d{1,3}))' +
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
    '(\\?[;&a-z\\d%_.~+=-]*)?' +
    '(\\#[-a-z\\d_]*)?$',
  'i',
)
const phoneNumberValidation = '^[0-9]{10,11}$'

const schema = Yup.object().shape({
  Name: Yup.string().required('Name is required'),
  OrganizationType: Yup.string().required('Organization type is required'),
  NonProfit: Yup.string().required('Non profit is required'),
  Designation: Yup.string().required('Designation is required'),
  TaxId: Yup.string()
    .matches(taxIdRegex, 'Invalid TaxId e.g 12-1234567')
    .required('Tax ID is required'),
  Address1: Yup.string().required('Number and street is required'),
  Address2: Yup.string().required('Suite is required'),
  City: Yup.string().required('City is required'),
  State: Yup.string().required('State is required'),
  ZipCode: Yup.string()
    .matches(zipCodeRegex, 'Invalid Zipcode e.g 12345')
    .required('Zip code is required'),
  PhoneNumber: Yup.string()
    .matches(phoneNumberValidation, 'Invalid Phone number e.g 1234567891')
    .required('Phone number is required'),
  Website: Yup.string()
    .matches(websiteRegex, 'Invalid website url e.g https://www.google.com')
    .required('Website URL is required'),
  Description: Yup.string()
    .min(50, 'Description should be atleast 50 character')
    .required('Description is required'),
  IdentityType: Yup.string().required('Identity Type is required'),
  W9Form: Yup.mixed()
    .typeError('Identity File is not a file')
    .required('W9Form is required'),
  FundedOutside: Yup.string().required('Required'),
  IdentityFile: Yup.mixed()
    .typeError('Identity File is not a file')
    .required('Identity File is required'),
  recaptcha: Yup.string()
    .typeError('Please complete recaptcha to proceed')
    .required('Please complete recaptcha to proceed'),
})

const organizationValidation = {
  async validator({ field }, value) {
    await schema.validateSyncAt(field, { [field]: value })
  },
}

export { organizationValidation }
