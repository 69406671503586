import React, { useState } from 'react'
import { Button, Form, Input, Select, Checkbox, Modal } from 'antd'
import { siginValidation } from 'validations'
import { TermsAndConditions } from './TermsAndConditions'

function LoginForm({ loading, onSubmit }) {
  const [form] = Form.useForm()
  const [isModalVisible, setIsModalVisible] = useState(false)

  const handleInputChange = (value) => {
    const val = value.replace(/\D/g, '')
    form.setFieldsValue({ phoneNumber: val })
  }

  const onFinish = (values) => {
    onSubmit(values)
  }

  return (
    <Form
      form={form}
      name="signIn"
      initialValues={{ countryCode: '+1', termsAndCondition: false }}
      onFinish={onFinish}
      className="row-col"
    >
      <Form.Item name="countryCode" rules={[siginValidation]}>
        <Select placeholder="Select Country Code" style={{ width: '100%' }}>
          <Select.Option value="+1">United States/Canada (+1)</Select.Option>
          <Select.Option value="+54">Argentina (+54)</Select.Option>
          <Select.Option value="+92">Pakistan (+92)</Select.Option>
          <Select.Option value="+58">
            Venezuela, Bolivarian Republic of Venezuela (+58)
          </Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name="phoneNumber" rules={[siginValidation]}>
        <Input
          placeholder="Phone Number"
          onChange={(e) => handleInputChange(e.target.value)}
        />
      </Form.Item>
      <p className="text-right t-font-semibold">
        * Message And Data Rates May Apply
      </p>
      <Form.Item
        name="termsAndCondition"
        valuePropName="checked"
        rules={[siginValidation]}
      >
        <Checkbox
          onChange={(e) =>
            form.setFieldsValue({
              termsAndCondition: e.target.checked,
            })
          }
        >
          <h6 className="t-text-sm font-regular text-muted t-my-2" level={5}>
            You hereby provide your consent to our
            {'  '}
            <span
              className="font-bold t-text-primary-100 hover:t-underline t-cursor-pointer"
              onClick={() => setIsModalVisible(true)}
            >
              Terms and Conditions
            </span>
          </h6>
        </Checkbox>
        <Modal
          title="Terms Of Use Policy"
          visible={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          footer={null}
        >
          <TermsAndConditions />
        </Modal>
      </Form.Item>
      <Form.Item>
        <Button
          style={{ width: '100%' }}
          type="primary"
          htmlType="submit"
          loading={loading}
        >
          Continue
        </Button>
      </Form.Item>
    </Form>
  )
}

export { LoginForm }
