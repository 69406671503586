/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { Card, Button, Skeleton } from 'antd'
import { ArrowRightOutlined, CarryOutFilled } from '@ant-design/icons'
import { IoMdArrowRoundBack } from 'react-icons/io'
import Api from 'api'
import PerformanceSummary from './events/PerformanceSummary'
import PerformanceDetails from './events/PerformanceDetails'
import EventStatisticsReport from './events/EventStatisticsReport'
import PledgeCommittments from './events/PledgeCommittments'
import DonorContributionsReport from './events/DonorContributionsReport'
import DonationStatistics from './events/DonationStatistics'

function EventReports() {
  const [loading, setLoading] = useState()
  const [events, setEvents] = useState()
  const [selected, setSelected] = useState()
  const [action, setAction] = useState()

  useEffect(() => {
    setLoading(true)
    Api.get('adminReports/getEventsBySubscriber')
      .then((res) => {
        setEvents(res.results)
        setLoading(false)
      })
      .catch((err) => {
        console.log('Error', err)
        setLoading(false)
      })
  }, [])

  const data = [
    'Performance Summary',
    'Performance Details',
    'Event Statistics Report',
    'Pledge Committments',
    'Donor Contributions Report',
    'Donation Statistics',
  ]

  return (
    <Card
      id="LastYearEarningsReport"
      className="mb-24 header-solid list-settings-sessions"
      bordered={false}
      title={[
        <React.Fragment key={0}>
          {selected === undefined ? (
            <h5 className="font-semibold">Event List</h5>
          ) : (
            <div className="t-flex t-space-x-4">
              <IoMdArrowRoundBack
                fontSize="1.5rem"
                className="t-cursor-pointer"
                onClick={() =>
                  action === undefined ? setSelected() : setAction()
                }
              />
              <h5 className="font-semibold">
                <span className="t-capitalize">
                  {action === undefined
                    ? selected?.name
                    : `${selected?.name} - ${data[action]}`}
                </span>
              </h5>
            </div>
          )}
        </React.Fragment>,
      ]}
    >
      {selected === undefined ? (
        <Skeleton loading={loading} paragraph={{ rows: 12 }}>
          {events &&
            events.length > 0 &&
            events.map((item, index) => (
              <>
                {index !== 0 && <hr className="gradient-line my-10" />}
                <div className="ant-row-flex ant-row-flex-middle">
                  <div
                    className="text-center ant-col"
                    style={{ minWidth: '40px' }}
                  >
                    <CarryOutFilled
                      className="text-gray-6"
                      style={{ fontSize: '18px' }}
                    />
                  </div>
                  <div className="ant-col pl-15">
                    <p className="mb-0 t-capitalize">{item.Title}</p>
                  </div>
                  <div
                    className="ml-auto ant-col ant-col-24 ant-col-md-12"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Button
                      type="link"
                      onClick={() =>
                        setSelected({ id: item.Id, name: item.Title })
                      }
                    >
                      See More <ArrowRightOutlined />
                    </Button>
                  </div>
                </div>
              </>
            ))}
        </Skeleton>
      ) : (
        <>
          {action !== undefined ? (
            <Single id={selected?.id} action={action} />
          ) : (
            <>
              {data.map((item, index) => (
                <>
                  {index !== 0 && <hr className="gradient-line my-10" />}
                  <div className="ant-row-flex ant-row-flex-middle">
                    <div
                      className="text-center ant-col"
                      style={{ minWidth: '40px' }}
                    >
                      <CarryOutFilled
                        className="text-gray-6"
                        style={{ fontSize: '18px' }}
                      />
                    </div>
                    <div className="ant-col pl-15">
                      <p className="mb-0 t-capitalize">{item}</p>
                    </div>
                    <div
                      className="ml-auto ant-col ant-col-24 ant-col-md-12"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <Button type="link" onClick={() => setAction(index)}>
                        See More <ArrowRightOutlined />
                      </Button>
                    </div>
                  </div>
                </>
              ))}
            </>
          )}
        </>
      )}
    </Card>
  )
}

function Single({ id, action }) {
  const data = [
    <PerformanceSummary id={id} />,
    <PerformanceDetails id={id} />,
    <EventStatisticsReport id={id} />,
    <PledgeCommittments id={id} />,
    <DonorContributionsReport id={id} />,
    <DonationStatistics id={id} />,
  ]
  return <>{data[action]}</>
}

export default EventReports
