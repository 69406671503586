import React, { useEffect, useState } from 'react'
import { Menu } from 'antd'
import { NavLink, withRouter } from 'react-router-dom'
import { menuItems } from 'configs'
import NavIcon from './NavIcon'
import NavBadge from './NavBadge'

const routes = [
  'dashboard',
  'organizations',
  'campaigns',
  'events',
  'programs',
  'services',
  'volunteer',
  'services',
  'campaigns',
]

const debounce = (callback, delay) => {
  let timer
  return (...args) => {
    clearTimeout(timer)
    timer = setTimeout(() => callback(...args), delay)
  }
}

const NavContent = (props) => {
  const [openKeys, setOpenKeys] = useState([])
  const [rootKeys, setRootKeys] = useState([])
  const [windowWidth, setWindowWidth] = useState(window?.innerWidth)

  const handleResize = () => {
    debounce(() => setWindowWidth(window.innerWidth), 100)
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    let updatedrootKeys = menuItems.items.map((item) => {
      if (item.children) {
        return [...item.children.map((child) => child.id)]
      }
      return false
    })
    updatedrootKeys = [].concat.apply([], updatedrootKeys)

    if (props.location) {
      const keys = props.location.pathname.split('/')
      if (!routes.includes(keys[keys.length - 1])) {
        keys.pop()
      }
      keys.splice(0, 1)
      setOpenKeys(keys)
    }
    setRootKeys(updatedrootKeys)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (windowWidth > 992) {
      if (openKeys.length === 0 && windowWidth > 992) {
        let updatedrootKeys = menuItems.items.map((item) => {
          if (item.children) {
            return [...item.children.map((child) => child.id)]
          }
          return false
        })
        updatedrootKeys = [].concat.apply([], updatedrootKeys)
        if (props.location) {
          const keys = props.location.pathname.split('/')
          if (!routes.includes(keys[keys.length - 1])) {
            keys.pop()
          }
          keys.splice(0, 1)
          setOpenKeys(keys)
        }
        setRootKeys(updatedrootKeys)
      }
    }
    if (windowWidth <= 992) {
      if (openKeys.length) {
        setOpenKeys([])
      }
    }
    // eslint-disable-next-line
  }, [openKeys.length, windowWidth])

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1)
    if (rootKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys)
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : [])
    }
  }

  const renderItem = (navigation) => {
    if (!navigation) return <div></div>
    return navigation.map((item, index) => {
      switch (item.type) {
        case 'group':
          return (
            <React.Fragment key={index}>
              <Menu.Item
                className={`menu-item-header ${
                  item.className ? item.className : ''
                }`}
                key={item.id || index}
              >
                {item.title}
              </Menu.Item>
              {renderItem(item.children)}
            </React.Fragment>
          )
        case 'collapse':
          return (
            <Menu.SubMenu
              key={item.id || index}
              icon={
                item.icon
                  ? item.icon(
                      openKeys.includes(item.id),
                      window.sessionStorage.getItem('color_theam'),
                    )
                  : ''
              }
              title={item.title}
            >
              <Menu.ItemGroup key={`${item.id}1`}>
                {renderItem(item.children)}
              </Menu.ItemGroup>
            </Menu.SubMenu>
          )
        case 'item':
          if (item.target) {
            return (
              <Menu.Item key={item.id} className="catlog">
                <a
                  href={item.url}
                  className="nav-link"
                  target="_blank"
                  rel="noreferrer"
                >
                  <NavIcon items={item} />
                  <span className="label">{item.title}</span>
                  <NavBadge items={item} />
                </a>
              </Menu.Item>
            )
          }
          return (
            <Menu.Item key={item.id}>
              <NavLink to={item.url} className="nav-link" exact>
                <NavIcon items={item} />
                <span className="label">{item.title}</span>
                <NavBadge items={item} />
              </NavLink>
            </Menu.Item>
          )
        default:
          return false
      }
    })
  }

  return (
    <div className="navbar-content datta-scroll">
      <Menu
        theme="light"
        mode="inline"
        openKeys={openKeys}
        onOpenChange={onOpenChange}
      >
        {renderItem(props.navigation)}
      </Menu>
      <div style={{ padding: '4rem 0rem' }} />
    </div>
  )
}

// class NavContent extends Component {
//   constructor(props) {
//     super(props)
//     this.state = {
//       openKeys: [],
//       rootKeys: [],
//       windowWidth: window.innerWidth,
//     }
//   }

//   /* eslint-disable */
//   debouncedLog = debounce(
//     () => this.setState({ windowWidth: window.innerWidth }),
//     100,
//   )
//   /* eslint-disable */
//   handleResize = (e) => {
//     this.debouncedLog()
//   }

//   componentWillUnmount() {
//     window.removeEventListener('resize', this.handleResize)
//   }

//   componentDidMount() {
//     window.addEventListener('resize', this.handleResize)
//     let rootKeys = menuItems.items.map((item) => {
//       if (item.children) {
//         return [...item.children.map((child) => child.id)]
//       }
//       return false
//     })
//     rootKeys = [].concat.apply([], rootKeys)

//     if (this.props.location) {
//       const keys = this.props.location.pathname.split('/')
//       if (!routes.includes(keys[keys.length - 1])) {
//         keys.pop()
//       }
//       keys.splice(0, 1)
//       this.setState({ openKeys: keys })
//     }
//     this.setState({ rootKeys })
//   }

//   /* eslint-disable */
//   onOpenChange = (keys) => {
//     const { openKeys } = this.state
//     const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1)

//     if (this.state.rootKeys.indexOf(latestOpenKey) === -1) {
//       this.setState({ openKeys: keys })
//     } else {
//       this.setState({ openKeys: latestOpenKey ? [latestOpenKey] : [] })
//     }
//   }

//   /* eslint-disable */

//   navItems = this.props.navigation.map((item, index) => {
//     switch (item.type) {
//       case 'group':
//         return <NavGroup group={item} key={index} />
//       default:
//         return false
//     }
//   })

//   componentDidUpdate(prevProps, prevState) {
//     if (
//       prevState.openKeys.length !== this.state.openKeys.length ||
//       this.state.windowWidth > 992
//     ) {
//       if (this.state.openKeys.length === 0 && this.state.windowWidth > 992) {
//         let rootKeys = menuItems.items.map((item) => {
//           if (item.children) {
//             return [...item.children.map((child) => child.id)]
//           }
//           return false
//         })
//         rootKeys = [].concat.apply([], rootKeys)
//         if (this.props.location) {
//           const keys = this.props.location.pathname.split('/')
//           if (!routes.includes(keys[keys.length - 1])) {
//             keys.pop()
//           }
//           keys.splice(0, 1)
//           this.setState({ openKeys: keys })
//         }
//         this.setState({ rootKeys })
//       }
//     }

//     if (
//       prevState.windowWidth !== this.state.windowWidth &&
//       this.state.windowWidth <= 992
//     ) {
//       if (this.state.openKeys.length) {
//         this.setState({ openKeys: [] })
//       }
//     }
//   }

//   renderItem = (navigation) => {
//     if (!navigation) return
//     return navigation.map((item, index) => {
//       switch (item.type) {
//         case 'group':
//           return (
//             <React.Fragment key={index}>
//               <Menu.Item
//                 className={`menu-item-header ${
//                   item.className ? item.className : ''
//                 }`}
//                 key={item.id || index}
//               >
//                 {item.title}
//               </Menu.Item>
//               {this.renderItem(item.children)}
//             </React.Fragment>
//           )
//         case 'collapse':
//           return (
//             <Menu.SubMenu
//               key={item.id || index}
//               icon={
//                 item.icon
//                   ? item.icon(
//                       this.state.openKeys.includes(item.id),
//                       window.sessionStorage.getItem('color_theam'),
//                     )
//                   : ''
//               }
//               title={item.title}
//             >
//               <Menu.ItemGroup key={`${item.id}1`}>
//                 {this.renderItem(item.children)}
//               </Menu.ItemGroup>
//             </Menu.SubMenu>
//           )
//         case 'item':
//           if (item.target) {
//             return (
//               <Menu.Item key={item.id} className="catlog">
//                 <a
//                   href={item.url}
//                   className="nav-link"
//                   target="_blank"
//                   rel="noreferrer"
//                 >
//                   <NavIcon items={item} />
//                   <span className="label">{item.title}</span>
//                   <NavBadge items={item} />
//                 </a>
//               </Menu.Item>
//             )
//           }
//           return (
//             <Menu.Item key={item.id}>
//               <NavLink to={item.url} className="nav-link" exact>
//                 <NavIcon items={item} />
//                 <span className="label">{item.title}</span>
//                 <NavBadge items={item} />
//               </NavLink>
//             </Menu.Item>
//           )
//         default:
//           return false
//       }
//     })
//   }

//   render() {
//     const mainContent = (
//       <div className="navbar-content datta-scroll">
//         <Menu
//           theme="light"
//           mode="inline"
//           openKeys={this.state.openKeys}
//           onOpenChange={this.onOpenChange}
//         >
//           {this.renderItem(this.props.navigation)}
//         </Menu>
//         <div style={{ padding: '4rem 0rem' }} />
//       </div>
//     )

//     return <div>{mainContent}</div>
//   }
// }

export default withRouter(NavContent)
