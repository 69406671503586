import React, { useEffect, useReducer, useState } from 'react'
import {
  Card,
  Table,
  Tag,
  Skeleton,
  Space,
  Dropdown,
  Menu,
  // Input,
  Modal,
  Input,
} from 'antd'
import { useOrganizationSelector } from 'store/organizationSlice/organizationReducer'
import {
  getAllOrganizations,
  updateOrganizationByAdmin,
} from 'store/organizationSlice/organizationActions'
import { useDispatch } from 'react-redux'
import { EllipsisOutlined, SearchOutlined } from '@ant-design/icons'
import { config } from 'configs'
import { searchRows } from 'utils'
import { ReportView } from './components/ReportView'
import { UpdateMarkup } from './components/UpdateMarkup'
// import { SearchOutlined } from "@ant-design/icons";

const initialState = {
  isReportVisible: false,
  isEditMarkupVisible: false,
  id: '',
  name: '',
  type: '',
}

function reducer(state, action) {
  switch (action.type) {
    case 'sms':
      return {
        ...state,
        isReportVisible: true,
        id: action.data.id,
        name: action.data.name,
        type: action.type,
      }
    case 'email':
      return {
        ...state,
        isReportVisible: true,
        id: action.data.id,
        name: action.data.name,
        type: action.type,
      }
    case 'markup':
      return {
        ...state,
        isEditMarkupVisible: true,
        id: action.data.id,
        name: action.data.name,
      }
    case 'cancel':
      return initialState
    default:
      return state
  }
}

function AdminOrganizationsView() {
  const dispatch = useDispatch()
  const [searchText, setSearchText] = useState('')
  const { loading, organizations } = useOrganizationSelector()
  const [modalState, setModalState] = useReducer(reducer, initialState)

  const handleActions = (key, data) => {
    let payload
    switch (key) {
      case 1:
        payload = {
          ...data,
          OrgStatus: 'Active',
        }
        dispatch(updateOrganizationByAdmin(payload))
        break
      case 2:
        payload = {
          ...data,
          OrgStatus: 'Disabled',
        }
        dispatch(updateOrganizationByAdmin(payload))
        break
      case 3:
        payload = {
          ...data,
          OrgStatus: 'Suspended',
        }

        dispatch(updateOrganizationByAdmin(payload))
        break
      case 4:
        window.open(
          `${config.bucketUrl + data?.IdentityFile.fileName}`,
          '_newtab',
        )
        break
      case 5:
        setModalState({ type: 'sms', data: { id: data.id, name: data.Name } })
        break
      case 6:
        setModalState({
          type: 'email',
          data: { id: data.id, name: data.Name },
        })
        break
      case 7:
        setModalState({
          type: 'markup',
          data: { id: data.id, name: data.Name },
        })
        break
      case 8:
        window.open(`${config.bucketUrl + data?.W9Form.fileName}`, '_newtab')
        break
      default:
        break
    }
  }
  function CustomMenu(organization) {
    return (
      <Menu>
        {organization.OrgStatus !== 'Active' && (
          <Menu.Item key={1} onClick={() => handleActions(1, organization)}>
            Active
          </Menu.Item>
        )}
        {organization.OrgStatus !== 'Disabled' && (
          <Menu.Item key={2} onClick={() => handleActions(2, organization)}>
            Disable
          </Menu.Item>
        )}
        {organization.OrgStatus !== 'Disabled' &&
          organization.OrgStatus !== 'Suspended' && (
            <Menu.Item key={3} onClick={() => handleActions(3, organization)}>
              Suspended
            </Menu.Item>
          )}
        {organization.IdentityFile && (
          <Menu.Item key={4} onClick={() => handleActions(4, organization)}>
            Download Identity
          </Menu.Item>
        )}
        {organization.OrgStatus === 'Active' && (
          <Menu.Item key={5} onClick={() => handleActions(5, organization)}>
            SMS Report
          </Menu.Item>
        )}
        {organization.OrgStatus === 'Active' && (
          <Menu.Item key={6} onClick={() => handleActions(6, organization)}>
            Email Report
          </Menu.Item>
        )}
        {organization.OrgStatus === 'Active' && (
          <Menu.Item key={7} onClick={() => handleActions(7, organization)}>
            Markups and Costing
          </Menu.Item>
        )}
        {organization.W9Form && (
          <Menu.Item key={8} onClick={() => handleActions(8, organization)}>
            Download W9Form
          </Menu.Item>
        )}
      </Menu>
    )
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'Name',
      key: 'Name',
      render: (text) => <div>{text}</div>,
      sorter: (a, b) => {
        if (a.Name > b.Name) {
          return -1
        }
        if (b.Name > a.Name) {
          return 1
        }
        return 0
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Email',
      dataIndex: 'Email',
      key: 'Email',
    },
    {
      title: 'Tax ID',
      dataIndex: 'TaxId',
      key: 'TaxId',
    },
    {
      title: 'Status',
      dataIndex: 'OrgStatus',
      key: 'OrgStatus',
      render: (_, { OrgStatus }) => (
        <>
          {OrgStatus === 'Active' && <Tag color="green">Active</Tag>}
          {OrgStatus === 'Disabled' && <Tag color="orange">Disabled</Tag>}
          {OrgStatus === 'Pending' && <Tag color="gray">Pending</Tag>}
          {OrgStatus === 'Suspended' && <Tag color="red">Suspended</Tag>}
        </>
      ),
      sorter: (a, b) => {
        if (a.OrgStatus > b.OrgStatus) {
          return -1
        }
        if (b.OrgStatus > a.OrgStatus) {
          return 1
        }
        return 0
      },
      sortDirections: ['descend', 'ascend'],
    },

    {
      title: 'Non Profit',
      dataIndex: 'NonProfit',
      key: 'NonProfit',
      render: (_, { NonProfit }) => <>{NonProfit ? 'Yes' : 'No'}</>,
      sorter: (a, b) => {
        if (a.NonProfit > b.NonProfit) {
          return -1
        }
        if (b.NonProfit > a.NonProfit) {
          return 1
        }
        return 0
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Foreign Funded',
      dataIndex: 'FundedOutside',
      key: 'FundedOutside',
      render: (_, { FundedOutside }) => <>{FundedOutside ? 'Yes' : 'No'}</>,
      sorter: (a, b) => {
        if (a.FundedOutside > b.FundedOutside) {
          return -1
        }
        if (b.FundedOutside > a.FundedOutside) {
          return 1
        }
        return 0
      },
      sortDirections: ['descend', 'ascend'],
    },

    {
      title: 'State',
      dataIndex: 'State',
      key: 'State',
    },
    {
      title: 'Zipcode',
      dataIndex: 'ZipCode',
      key: 'ZipCode',
    },
    {
      title: 'City',
      dataIndex: 'City',
      key: 'City',
    },

    {
      title: 'Address',
      dataIndex: 'Address1',
      key: 'Address1',
    },
    {
      title: 'Organization Type',
      dataIndex: 'OrganizationType',
      key: 'OrganizationType',
      // width: 300,
      sorter: (a, b) => {
        if (a.OrganizationType > b.OrganizationType) {
          return -1
        }
        if (b.OrganizationType > a.OrganizationType) {
          return 1
        }
        return 0
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Actions',
      key: 'id',
      // fixed: "right",
      width: 300,
      align: 'center',
      render: (_, organization) => (
        <Space size="middle">
          <Dropdown overlay={CustomMenu(organization)}>
            <EllipsisOutlined />
          </Dropdown>
        </Space>
      ),
    },
  ]

  useEffect(() => {
    dispatch(getAllOrganizations())
  }, [dispatch])

  return (
    <>
      <Card
        bordered={false}
        className="header-solid mb-24"
        style={{ minHeight: '85vh' }}
        title={
          <div className="t-flex t-flex-wrap t-justify-between">
            <div>
              <h6 className="font-semibold t-text-2xl">Organizations</h6>
              <p>All organizations are listed here</p>
            </div>
            <div className="t-mt-4 sm:t-mt-0">
              <div className="header-control t-mt-0">
                <Input
                  className="header-search"
                  placeholder="Search Organization"
                  value={searchText}
                  prefix={<SearchOutlined />}
                  onChange={(e) => {
                    setSearchText(e.target.value)
                  }}
                />
              </div>
            </div>
          </div>
        }
      >
        <Skeleton loading={loading} active paragraph={{ rows: 12 }}>
          <Table
            size="middle"
            tableLayout="auto"
            scroll={{
              x: 'max-content',
              // y: 240,
            }}
            columns={columns}
            dataSource={searchRows(organizations, searchText, 'Title') || []}
            bordered={false}
            pagination={{
              pageSize: 10,
            }}
            style={{ width: 'auto', minWidth: 'unset !important' }}
          />
        </Skeleton>
      </Card>
      <Modal
        visible={modalState.isReportVisible || modalState.isEditMarkupVisible}
        onCancel={() => setModalState({ type: 'cancel' })}
        footer={null}
      >
        {modalState.isReportVisible ? (
          <ReportView
            id={modalState.id}
            name={modalState.name}
            type={modalState.type}
          />
        ) : (
          <UpdateMarkup
            id={modalState.id}
            name={modalState.name}
            onCancel={() => setModalState({ type: 'cancel' })}
          />
        )}
      </Modal>
    </>
  )
}

export { AdminOrganizationsView }
