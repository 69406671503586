/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { Skeleton } from 'antd'
import Api from 'api'
import moment from 'moment'

function DonorContributionsReport({ id }) {
  const [loading, setLoading] = useState()
  const [report, setReport] = useState()

  useEffect(() => {
    setLoading(true)
    Api.get(`adminReports/eventStats/${id}`)
      .then((res) => {
        console.log('reasdsads', res)
        setReport(res.results[0])
        setLoading(false)
      })
      .catch((err) => {
        console.log('Error', err)
        setLoading(false)
      })
    // eslint-disable-next-line
  }, [])

  return (
    <Skeleton loading={loading} paragraph={{ rows: 12 }}>
      {report ? (
        <div className="t-space-y-4">
          <div className="t-flex t-items-center t-justify-between">
            <p>First Name</p>
            {report.firstName}
          </div>
          <div className="t-flex t-items-center t-justify-between">
            <p>Last Name</p>
            {report.lastName}
          </div>
          <div className="t-flex t-items-center t-justify-between">
            <p>Phone Number</p>
            {report.phoneNumber}
          </div>
          <div className="t-flex t-items-center t-justify-between">
            <p>Email Address</p>
            {report?.email}
          </div>
          <div className="t-flex t-items-center t-justify-between">
            <p>Zip or Postal Code</p>
            {report.zipCode}
          </div>
          <div className="t-flex t-items-center t-justify-between">
            <p>Amount Donated</p>
            {report.amount}
          </div>
          <div className="t-flex t-items-center t-justify-between">
            <p>Thank You Email Sent</p>
            {moment(report.paymentDate).format(
              'dddd[,] DD/MM/YYYY [at] hh:mm A',
            )}
          </div>
          <div className="t-flex t-items-center t-justify-between">
            <p>Thank You SMS Sent</p>
            {moment(report.paymentDate).format(
              'dddd[,] DD/MM/YYYY [at] hh:mm A',
            )}
          </div>
        </div>
      ) : (
        <div>No Data Found</div>
      )}
    </Skeleton>
  )
}

export default DonorContributionsReport
