import React, { useEffect, useState } from 'react'
import { Skeleton } from 'antd'
import { useDispatch } from 'react-redux'
import { eventActions, useEventSelector } from 'store/eventSlice/eventReducer'
import {
  getSubscriberMarkupInfo,
  getSubscriberPaymentInformation,
} from 'store/eventSlice/eventActions'
import { BeforeJoinForm, EventDetail } from './components'

function JoinEvent() {
  const [isJoined, setJoined] = useState(false)
  const { event } = useEventSelector()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getSubscriberPaymentInformation(event?.CreatedBy))
    dispatch(getSubscriberMarkupInfo(event?.OrganizationId))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event])

  const moveNext = (values) => {
    dispatch(eventActions.setJoinedEventDetails(values))
    setJoined(true)
  }

  return (
    <Skeleton loading={false} active paragraph={{ rows: 10 }}>
      {!isJoined ? <BeforeJoinForm moveNext={moveNext} /> : <EventDetail />}
    </Skeleton>
  )
}

export { JoinEvent }
