import React, { useEffect, useReducer, useState } from 'react'
import { Button, message, Upload } from 'antd'
import Api from 'api'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { getUserOrganization } from 'store/organizationSlice/organizationActions'

const initialState = {
  download: false,
  upload: false,
  reminder: false,
}
// The reducer function
function reducer(state, action) {
  switch (action) {
    case 'download':
      return {
        ...state,
        download: !state.download,
      }
    case 'upload':
      return {
        ...state,
        upload: !state.upload,
      }
    case 'reminder':
      return {
        ...state,
        reminder: !state.reminder,
      }
    default:
      return state
  }
}

function ManageDonors() {
  const [loading, setLoading] = useReducer(reducer, initialState)
  const { user } = useAuthSelector()
  const [org, setOrganization] = useState(null)

  useEffect(() => {
    const getData = async () => {
      setLoading(true)
      const response = await getUserOrganization(user?.email)
      setOrganization(response)
    }
    getData()
  }, [user])

  const downloadFile = (data, type, filename) => {
    const blob = new Blob([data], { type: type.toString() })
    const blobURL = window.URL.createObjectURL(blob)
    const anchor = document.createElement('a')
    anchor.download = filename
    anchor.href = blobURL
    anchor.click()
  }

  const getFile = () => {
    setLoading('download')
    Api.get('subscriber/downloadSampleFile')
      .then((res) => {
        downloadFile(res, 'application/ms-excel', 'Sample-DonorList.csv')
        setLoading('download')
      })
      .catch((err) => {
        console.log('Error', err)
        setLoading('download')
      })
  }

  const sendReminder = () => {
    if (org && org.Name) {
      setLoading('reminder')
      Api.get(`subscriber/sendReminder?name=${org.Name}`)
        .then((res) => {
          message.success(res.message)
          setLoading('reminder')
        })
        .catch((err) => {
          console.log('Error', err)
          message.error(err.message)
          setLoading('reminder')
        })
    } else {
      message.error('Cound not get Organization data')
    }
  }

  const onFileUpload = (file) => {
    const data = new FormData()
    data.append('uploadfile', file)
    console.log('file', file)
    if (file.size < 2 * 1048576) {
      setLoading('upload')
      Api.put('subscriber/uploadDonorList', data)
        .then((res) => {
          console.log('res upload', res)
          message.success(res.message)
          setLoading('upload')
        })
        .catch((err) => {
          console.log('Error', err)
          message.error('File cannot be processed due to an error')
          setLoading('upload')
        })
    } else {
      message.error('File size should be less than 20 MB to upload')
    }
  }

  return (
    <div className="t-flex t-flex-1 t-justify-between t-space-x-4">
      <Button
        className="t-w-full font-bold px-25"
        onClick={() => getFile()}
        loading={loading.download}
      >
        Dowload Sample Donor List
      </Button>
      <Upload
        customRequest={(e) => onFileUpload(e.file)}
        maxCount={1}
        showUploadList={false}
        accept=".csv"
      >
        <Button className="t-w-full">Upload Donor List</Button>
      </Upload>
      <Button
        className="t-w-full font-bold px-25"
        loading={loading.reminder}
        onClick={() => sendReminder()}
      >
        Send Reminder
      </Button>
    </div>
  )
}

export { ManageDonors }
