import React, { useEffect, useState } from 'react'
import {
  Row,
  Form,
  Checkbox,
  Button,
  Col,
  message,
  Input,
  Typography,
  Switch,
  Select,
} from 'antd'
import { useEventSelector } from 'store/eventSlice/eventReducer'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { addECheckDonation } from 'store/paymentSlice/paymentActions'
import { formatAmount } from 'utils'
import * as Yup from 'yup'

const { Title } = Typography

const numberValidation = new RegExp('^[0-9]+$')
const zipCodeRegex = '^[0-9]{5}(?:-[0-9]{4})?$'

const schema = Yup.object().shape({
  agreeToTerms: Yup.string().required('Required'),
  includeEventCosts: Yup.string().required('Required'),
  askedAmount: Yup.string()
    .required('Required')
    .matches(numberValidation, 'Invalid amount'),
  // check form entries starts here,
  accountholder_name: Yup.string().required('First name is required'),
  check_number: Yup.string().required('Check number is required'),
  check_type: Yup.string().required('Check type is required'),
  account_number: Yup.string().required('Account number is required'),
  routing_number: Yup.string().required('Routing number is required'),
  client_email: Yup.string()
    .required('Email address is required')
    .email('Invalid Email'),
  customer_ref: Yup.string().required('Customer Ref is required'),
  // check address
  street: Yup.string().required('Street is required'),
  city: Yup.string().required('City is required'),
  state_province: Yup.string().required('State province is required'),
  zip_postal_code: Yup.string()
    .required('Zipcode is required')
    .matches(zipCodeRegex, 'Invalid Zip code e.g 12345'),
  country: Yup.string().required('Country is required'),
})

const eCheckValidation = {
  async validator({ field }, value) {
    await schema.validateSyncAt(field, { [field]: value })
  },
}

const initialValues = {
  agreeToTerms: false,
  includeEventCosts: false,
  askedAmount: null,
}

function ECheck({ refreshEventData }) {
  const [loading, setLoading] = useState(false)
  const { event, joinedEventDetails } = useEventSelector()
  const { user, location } = useAuthSelector()
  const [form] = Form.useForm()

  Form.useWatch('agreeToTerms', form)
  Form.useWatch('askedAmount', form)
  Form.useWatch('includeEventCosts', form)

  useEffect(() => {
    form.setFieldsValue({ askedAmount: event?.CurrentAsk })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event?.CurrentAsk])

  /* eslint-disable */
  const onFinish = async (values) => {
    const askedAmountValue = Number(values.askedAmount)
    const includeEventCostValue = values.includeEventCosts
    const twoPercentage = (2 / 100) * Number(askedAmountValue)
    const totalDonation = includeEventCostValue
      ? askedAmountValue + twoPercentage
      : askedAmountValue

    if (!values.agreeToTerms) {
      return message.error('You must agree to the terms and conditions')
    }
    try {
      setLoading(true)
      const data = {}

      data.eventId = event.Id
      data.EventName = event.Title
      data.fullName = `${user?.firstname} ${user?.lastname}`
      data.donorId = user.id
      data.email = user?.email
      data.DonorPhoneNumber = user.phone

      data.isAttendingOnline = joinedEventDetails?.isRemote
      data.isDonatingAnonymously = joinedEventDetails?.donateAnonymously
      data.table = joinedEventDetails?.tableName || null

      data.zipCode = location?.zipCode

      data.donorId = user?.id
      data.payType = 'Echeck'
      data.Status = 'Pending'
      data.transactionType =
        event.EventType === 'Fundraiser' ? 'event' : 'campaign'

      data.splitPayment = values?.splitPledge
      data.splitPaymentNoMonths = values?.splitnoOfMonths
      data.amount = totalDonation
      data.recurringBill = values?.repeatMonths
      data.recurringMonths = values?.repeatnoOfMonths
      data.contributeToEventCost = values?.includeEventCosts

      data.accountholder_name = values.accountholder_name
      data.check_number = values.check_number
      data.check_type = values.check_type
      data.account_number = values.account_number
      data.routing_number = values.routing_number
      data.client_email = values.client_email

      data.address = {
        street: values.street,
        city: values.city,
        state_province: values.state_province,
        zip_postal_code: values.zip_postal_code,
        country: values.country,
      }
      await addECheckDonation(data)
      form.resetFields()
      message.success('Echeck submitted successfully')
      refreshEventData()
      setLoading(false)
    } catch (error) {
      console.log('error', error?.response?.data?.message)
      message.error(
        error?.response?.data?.message || 'Echeck Data Is Unprocessable',
      )
      setLoading(false)
    }
  }

  const askedAmountValue = Number(form.getFieldValue('askedAmount'))
  const includeEventCostValue = form.getFieldValue('includeEventCosts')

  const twoPercentage = (2 / 100) * Number(askedAmountValue)
  const totalDonation = includeEventCostValue
    ? askedAmountValue + twoPercentage
    : askedAmountValue

  return (
    <div>
      <Form
        form={form}
        layout="vertical"
        key={0}
        onFinish={onFinish}
        name="event"
        initialValues={initialValues}
        scrollToFirstError
      >
        <Row gutter={[24, 0]} className="t-p-3">
          <Col xl={24} md={24} xs={24} sm={24}>
            <Form.Item
              name="askedAmount"
              label="Asked Amount"
              colon={false}
              rules={[eCheckValidation]}
            >
              <Input disabled={!event?.EditAskedAmount} placeholder="50000" />
            </Form.Item>
          </Col>
          <Col xl={24} md={24} xs={24} sm={24}>
            <Form.Item
              name="includeEventCosts"
              label="Include Event Cost"
              colon={false}
              rules={[eCheckValidation]}
            >
              <Switch />
            </Form.Item>
          </Col>
          <Col xl={24} md={24} xs={24} sm={24}>
            <Form.Item
              name="accountholder_name"
              label="Account Holder name"
              colon={false}
              rules={[eCheckValidation]}
            >
              <Input placeholder="Micheal John" />
            </Form.Item>
          </Col>
          <Col xl={12} md={12} xs={24} sm={24}>
            <Form.Item
              name="check_number"
              label="Check Number"
              colon={false}
              rules={[eCheckValidation]}
            >
              <Input placeholder="Check number ..." />
            </Form.Item>
          </Col>
          <Col xl={12} md={12} xs={24} sm={24}>
            <Form.Item
              name="check_type"
              label="Check Type"
              colon={false}
              rules={[eCheckValidation]}
            >
              <Select placeholder="Check Type" style={{ width: '100%' }}>
                <Select.Option value="P">Personal Check</Select.Option>
                <Select.Option value="C">Corporate Check</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xl={12} md={12} xs={24} sm={24}>
            <Form.Item
              name="account_number"
              label="Account number"
              colon={false}
              rules={[eCheckValidation]}
            >
              <Input placeholder="143499344" />
            </Form.Item>
          </Col>
          <Col xl={12} md={12} xs={24} sm={24}>
            <Form.Item
              name="routing_number"
              label="Routing Number"
              colon={false}
              rules={[eCheckValidation]}
            >
              <Input placeholder="232323" />
            </Form.Item>
          </Col>
          <Col xl={12} md={12} xs={24} sm={24}>
            <Form.Item
              name="client_email"
              label="Client Email"
              colon={false}
              rules={[eCheckValidation]}
            >
              <Input placeholder="micheal.john@gmail.com" />
            </Form.Item>
          </Col>
          <Col xl={12} md={12} xs={24} sm={24}>
            <Form.Item
              name="customer_ref"
              label="Customer Reference"
              colon={false}
              rules={[eCheckValidation]}
            >
              <Input placeholder="232323" />
            </Form.Item>
          </Col>

          <Col xl={12} md={12} xs={24} sm={24}>
            <Form.Item
              name="street"
              label="Street"
              colon={false}
              rules={[eCheckValidation]}
            >
              <Input placeholder="London" />
            </Form.Item>
          </Col>

          <Col xl={12} md={12} xs={24} sm={24}>
            <Form.Item
              name="city"
              label="City"
              colon={false}
              rules={[eCheckValidation]}
            >
              <Input placeholder="London" />
            </Form.Item>
          </Col>
          <Col xl={12} md={12} xs={24} sm={24}>
            <Form.Item
              name="state_province"
              label="State Province"
              colon={false}
              rules={[eCheckValidation]}
            >
              <Input placeholder="Bottom City, Alaska" />
            </Form.Item>
          </Col>
          <Col xl={12} md={12} xs={24} sm={24}>
            <Form.Item
              name="zip_postal_code"
              label="Zipcode"
              colon={false}
              rules={[eCheckValidation]}
            >
              <Input placeholder="03292" />
            </Form.Item>
          </Col>
          <Col xl={24} md={24} xs={24} sm={24}>
            <Form.Item
              name="country"
              label="Country"
              colon={false}
              rules={[eCheckValidation]}
            >
              <Input placeholder="Canada" />
            </Form.Item>
          </Col>
          <Col xl={24} md={24} xs={24} sm={24}>
            <Form.Item
              name="agreeToTerms"
              colon={false}
              rules={[eCheckValidation]}
            >
              <Checkbox
                onChange={(e) =>
                  form.setFieldsValue({
                    agreeToTerms: e.target.checked,
                  })
                }
              >
                <span className="font-bold text-dark hover:t-text-primary-100 t-cursor-pointer">
                  I hereby agree to pay and adhere to Pledge Garden Terms &
                  Conditions
                </span>
              </Checkbox>
            </Form.Item>
          </Col>
          <Col xl={24} md={24} xs={24} sm={24}>
            <Row justify="end">
              <Title level={3}>
                {`Total Donation = ${formatAmount(totalDonation)}`}
              </Title>
            </Row>
          </Col>
          <Col span={24} className="text-right">
            <Button
              loading={loading}
              type="primary"
              className="px-25"
              htmlType="submit"
            >
              Donate Via ECheck
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export { ECheck }
