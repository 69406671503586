import React, { useEffect, useState } from 'react'
import {
  Button,
  Col,
  Form,
  Input,
  message,
  Row,
  Select,
  Typography,
} from 'antd'
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import { config } from 'configs'
import { loadStripe } from '@stripe/stripe-js'
import { donateByStripe, getUserCards } from 'store/paymentSlice/paymentActions'
import { useProgramSelector } from 'store/programSlice/programReducer'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { RiErrorWarningFill } from 'react-icons/ri'
import { getSubscriberPaymentInformation } from 'store/programSlice/programActions'
import { useDispatch } from 'react-redux'

const initialValues = {
  card: 'no-card-selected',
  askedAmount: null,
}

function RegisterForm({ close }) {
  const dispatch = useDispatch()
  const { user, location } = useAuthSelector()
  const { program, subscriberPaymentInfo } = useProgramSelector()
  const [loading, setLoading] = useState(false)
  const [cards, setCards] = useState([])

  const [form] = Form.useForm()
  Form.useWatch('card', form)

  const stripe = useStripe()
  const elements = useElements()

  const getCustomerCards = async () => {
    setLoading(true)
    try {
      const userCards = await getUserCards(user)
      setCards(userCards)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  useEffect(() => {
    getCustomerCards()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    dispatch(getSubscriberPaymentInformation(program?.CreatedBy))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [program])

  const onFinish = async (values) => {
    console.log('values', values)

    try {
      setLoading(true)
      if (values.card === 'no-card-selected') {
        // elements are not loaded yet
        if (elements === null) {
          return
        }
        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: 'card',
          card: elements.getElement(CardElement),
        })
        // if there is an error
        if (error) {
          message.error(error?.message || 'Invalid Card Information')
          setLoading(false)
        } else {
          const data = {
            Title: program.Title,
            email: user.email,
            fullName: `${user.firstname} ${user.lastname}`,
            id: user.id,
            zipCode: location.zipcode,
            amount: Number(program.Cost),
            card: null,
            cardToken: paymentMethod.id,
            stripeAccount: subscriberPaymentInfo?.stripe?.stripeId,
            commission: subscriberPaymentInfo.markup,
          }
          await donateByStripe(data)
          message.success('Your donation is successfully submitted')
          form.resetFields()
          elements.getElement(CardElement).clear()
          console.log('data', data)
          setLoading(false)
        }
      } else {
        const data = {
          Title: program.Title,
          email: user.email,
          fullName: `${user.firstname} ${user.lastname}`,
          id: user.id,
          zipCode: location.zipcode,
          amount: Number(program.Cost),
          card: values.card,
          cardToken: null,
          stripeAccount: subscriberPaymentInfo?.stripe?.stripeId,
          commission: subscriberPaymentInfo.markup,
        }
        await donateByStripe(data)
        message.success('Your donation is successfully submitted')
        form.resetFields()

        console.log('data', data)
        setLoading(false)
      }
      close()
    } catch (error) {
      console.log('error', error)
      message.error(error?.response?.data?.message || 'Something Went Wrong')
      setLoading(false)
    }
  }

  const isNoCard = form.getFieldValue('card') === 'no-card-selected'
  return (
    <div>
      {subscriberPaymentInfo?.stripe?.isConnected ? (
        <Form
          form={form}
          layout="vertical"
          key={0}
          onFinish={onFinish}
          name="pay-cust"
          initialValues={initialValues}
          scrollToFirstError
        >
          <Row gutter={[24, 0]} className="t-p-1">
            <Col xl={24} md={24} xs={24} sm={24}>
              <Form.Item
                name="programCost"
                label="Program Cost"
                colon={false}
                // rules={[creditCardValidation]}
              >
                <Input disabled placeholder={program.Cost} />
              </Form.Item>
            </Col>
            <Col xl={24} md={24} xs={24} sm={24}>
              <Form.Item
                name="card"
                label="Select Card"
                colon={false}
                // rules={[creditCardValidation]}
              >
                <Select placeholder="Select Card" style={{ width: '100%' }}>
                  <Select.Option value="no-card-selected">
                    No Card Selected
                  </Select.Option>
                  {cards.map((item, index) => (
                    <Select.Option
                      value={item.cardId}
                      key={index}
                      className="t-capitalize"
                    >
                      {`${item.brand} - **** **** **** ${item.last4}`}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {isNoCard && (
              <Col xl={24} md={24} xs={24} sm={24} className="t-mb-6">
                <h1 className="t-text-sm t-my-2 t-font-semibold">
                  Enter Card Detail
                </h1>
                <CardElement
                  options={{
                    style: {
                      base: {
                        fontSize: '15px',
                        fontSmoothing: 'antialiased',
                      },
                    },
                  }}
                />
              </Col>
            )}
            <Col span={24} className="text-right">
              <Button
                type="primary"
                className="px-25"
                htmlType="submit"
                loading={loading}
                // disabled={!stripe || !elements}
              >
                Pay
              </Button>
            </Col>
          </Row>
        </Form>
      ) : (
        <div className="t-h-72 t-flex t-items-center t-justify-center t-flex-col">
          <RiErrorWarningFill
            fontSize="2.5rem"
            className="t-text-primary-100"
          />
          <Typography.Title level={5}>
            Credit card payments are not configured for this event
          </Typography.Title>
          <Typography.Paragraph>
            You can use other payment methods
          </Typography.Paragraph>
        </div>
      )}
    </div>
  )
}

const stripePromise = loadStripe(config.stripe_publish_key)

function StripeProvider({ close }) {
  return (
    <Elements stripe={stripePromise}>
      <RegisterForm close={close} />
    </Elements>
  )
}

const Register = StripeProvider
export default Register
