import { message } from 'antd'
import Api from 'api'
import moment from 'moment'
import { volunteerActions } from './volunteerReducer'

export const getVolunteer = () => (dispatch) => {
  dispatch(volunteerActions.setLoading(true))
  Api.get('/volunteer')
    .then((res) => {
      dispatch(volunteerActions.setVolunteers(res?.results))
      dispatch(volunteerActions.setLoading(false))
    })
    .catch((err) => {
      console.log('Error', err)
      dispatch(volunteerActions.setLoading(false))
    })
}

export const getVolunteerDetail = (id, form) => (dispatch) => {
  dispatch(volunteerActions.setLoading(true))
  Api.get(`/volunteer?id=${id}`)
    .then((res) => {
      if (res.results) {
        const volunteer = res.results[0]
        if (form) {
          form.setFieldsValue({
            Title: volunteer?.Title,
            Description: volunteer?.Description,
            Schedule: volunteer.Schedule && moment(volunteer.Schedule),
            ZipCode: volunteer?.ZipCode,
            City: volunteer?.City,
            LocationState: volunteer?.LocationState,
            Address: volunteer?.Address,
            Notes: volunteer?.Notes,
          })
        }
      }
      dispatch(volunteerActions.setLoading(false))
    })
    .catch((err) => {
      console.log('Error', err)
      dispatch(volunteerActions.setLoading(false))
    })
}

export const addVolunteer = (payload, actions) => (dispatch) => {
  dispatch(volunteerActions.setLoading(true))
  Api.put('/volunteer', payload)
    .then(() => {
      dispatch(volunteerActions.setLoading(false))
      message.success('Volunteer Added Successfully')
      if (actions) {
        actions()
      }
    })
    .catch((err) => {
      console.log('Error', err)
      dispatch(volunteerActions.setLoading(false))
      message.error('An Error Occured')
    })
}

export const updateVolunteer = (payload, actions) => (dispatch) => {
  dispatch(volunteerActions.setLoading(true))
  Api.put('volunteer/updateVolunteer', payload)
    .then(() => {
      dispatch(volunteerActions.setLoading(false))
      message.success('Volunteer Updated Successfully')
      if (actions) {
        actions()
      }
    })
    .catch((err) => {
      console.log('Error', err)
      dispatch(volunteerActions.setLoading(false))
      message.error('An Error Occured')
    })
}

export const updateVolunteerStatus = (id, status, actions) => (dispatch) => {
  dispatch(volunteerActions.setLoading(true))
  if (status === 1) {
    Api.get(`volunteer/deactive?id=${id}`)
      .then(() => {
        dispatch(volunteerActions.setLoading(false))
        if (actions) {
          actions()
        }
        message.success('Volunteer Deactivated Successfully')
      })
      .catch((error) => {
        console.log('error', error)
        message.error('Something Went Wrong')
        dispatch(volunteerActions.setLoading(false))
      })
  } else if (status === 0) {
    Api.get(`volunteer/active?id=${id}`)
      .then(() => {
        dispatch(volunteerActions.setLoading(false))
        if (actions) {
          actions()
        }
        message.success('Volunteer Activated Successfully')
      })
      .catch((error) => {
        console.log('error', error)
        message.error('Something Went Wrong')
        dispatch(volunteerActions.setLoading(false))
      })
  }
}

export const registerForVolunteer = (payload, actions) => (dispatch) => {
  dispatch(volunteerActions.setLoading(true))
  Api.put('volunteer/registerForVolunteer', payload)
    .then((res) => {
      dispatch(volunteerActions.setLoading(false))
      if (res.statusCode === 208) {
        message.error(res.error)
      } else {
        message.success('Registered Successfully')
        if (actions) {
          actions()
        }
      }
    })
    .catch((err) => {
      console.log('Error', err)
      dispatch(volunteerActions.setLoading(false))
      message.error('An Error Occured')
    })
}

export const getRegisterVolunteers = (id, setLoading) => (dispatch) => {
  setLoading(true)
  Api.get(`volunteer/volunteerList/${id}`)
    .then((res) => {
      console.log('res', res)
      dispatch(volunteerActions.setRegisteredVolunteers(res.results))
      setLoading(false)
    })
    .catch((err) => {
      console.log('Error', err)
      setLoading(false)
      message.error('An Error Occured')
    })
}

export const getFeedbackByVolunteers = (id, setLoading) => (dispatch) => {
  setLoading(true)
  Api.get(`subscriber/feedback/${id}`)
    .then((res) => {
      dispatch(volunteerActions.setFeedbackByVolunteers(res.result))
      setLoading(false)
    })
    .catch((err) => {
      console.log('Error', err)
      setLoading(false)
      message.error('An Error Occured')
    })
}
