import LOGO_TEXT from './logo/logo_text.png'
import LOGO_ICON from './logo/logo_icon.png'

import SAMPLE1 from './sample/sample1.png'

export const icons = {
  logo_text: LOGO_TEXT,
  logo_icon: LOGO_ICON,
  sample1: SAMPLE1,
}
