import { createSlice } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'

const initialState = {
  loading: false,
  event: null,
  campaign: null,
  events: [],
  inactiveEvents: [],
  inactiveCampaigns: [],
  campaigns: [],
  eventDetail: {
    status: null,
    donations: [],
    tableTasks: [],
  },
  subscriberPaymentInfo: {
    stripe: {
      isConnected: false,
      stripeId: null,
    },
    paypal: {
      isConnected: false,
      paypalId: null,
    },
    markup: 2.9,
  },
  joinedEventDetails: null,
}

export const eventSlice = createSlice({
  name: 'event',
  initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      const alteredState = { ...state, loading: payload }
      return alteredState
    },
    setEvent: (state, { payload }) => {
      const alteredState = { ...state, event: payload }
      return alteredState
    },
    setCampaign: (state, { payload }) => {
      const alteredState = { ...state, campaign: payload }
      return alteredState
    },
    setEvents: (state, { payload }) => {
      const filteredEvent = payload.filter(
        (item) => item.EventType === 'Fundraiser',
      )
      const activeEvents = filteredEvent.filter((item) => item.ActiveInd === 1)
      const inactiveEvents = filteredEvent.filter(
        (item) => item.ActiveInd === 0,
      )
      const alteredState = {
        ...state,
        events: activeEvents,
        inactiveEvents,
      }
      return alteredState
    },
    setCampaigns: (state, { payload }) => {
      const filteredEvent = payload.filter(
        (item) => item.EventType === 'Campaign',
      )
      const activeCampaigns = filteredEvent.filter(
        (item) => item.ActiveInd === 1,
      )
      const inactiveCampaigns = filteredEvent.filter(
        (item) => item.ActiveInd === 0,
      )
      const alteredState = {
        ...state,
        campaigns: activeCampaigns,
        inactiveCampaigns,
      }
      return alteredState
    },
    setEventStatus: (state, { payload }) => {
      const alteredState = {
        ...state,
        eventDetail: { ...state.eventDetail, status: payload },
      }
      return alteredState
    },
    setEventDonations: (state, { payload }) => {
      const alteredState = {
        ...state,
        eventDetail: { ...state.eventDetail, donations: payload },
      }
      return alteredState
    },
    setEventTableTask: (state, { payload }) => {
      const alteredState = {
        ...state,
        eventDetail: { ...state.eventDetail, tableTasks: payload },
      }
      return alteredState
    },
    setJoinedEventDetails: (state, { payload }) => {
      const alteredState = {
        ...state,
        joinedEventDetails: payload,
      }
      return alteredState
    },
    setSubscriberStripeInfo: (state, { payload }) => {
      const alteredState = {
        ...state,
        subscriberPaymentInfo: {
          ...state.subscriberPaymentInfo,
          stripe: {
            isConnected: payload.isConnected,
            stripeId: payload.stripeId,
          },
        },
      }
      return alteredState
    },
    setSubscriberPaypalInfo: (state, { payload }) => {
      const alteredState = {
        ...state,
        subscriberPaymentInfo: {
          ...state.subscriberPaymentInfo,
          paypal: {
            isConnected: payload.isConnected,
            paypalId: payload.paypalId,
          },
        },
      }
      return alteredState
    },
    setSubscriberMarkupInfo: (state, { payload }) => {
      const alteredState = {
        ...state,
        subscriberPaymentInfo: {
          ...state.subscriberPaymentInfo,
          markup: payload,
        },
      }
      return alteredState
    },
  },
})

export const useEventSelector = () => useSelector((state) => state.event)
export const eventActions = eventSlice.actions
export const eventReducer = eventSlice.reducer
