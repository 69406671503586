import { message } from 'antd'
import Api from 'api'
import { serviceActions } from './serviceReducer'

export const getService = () => (dispatch) => {
  dispatch(serviceActions.setLoading(true))
  Api.get('/programServices')
    .then((res) => {
      dispatch(serviceActions.setServices(res?.results))
      dispatch(serviceActions.setLoading(false))
    })
    .catch((err) => {
      console.log('Error', err)
      dispatch(serviceActions.setLoading(false))
    })
}

export const addService = (payload, actions) => (dispatch) => {
  dispatch(serviceActions.setLoading(true))
  Api.put('/programServices', payload)
    .then(() => {
      dispatch(serviceActions.setLoading(false))
      message.success('Service Added Successfully')
      if (actions) {
        actions()
      }
    })
    .catch((err) => {
      console.log('Error', err)
      dispatch(serviceActions.setLoading(false))
      message.error('An Error Occured')
    })
}

export const getServiceDetail = (id, form) => (dispatch) => {
  dispatch(serviceActions.setLoading(true))
  Api.get(`/programServices?id=${id}`)
    .then((res) => {
      if (res.results) {
        const service = res.results[0]
        if (form) {
          form.setFieldsValue({
            Title: service.Title,
            Description: service.Description,
            Requirements: service.Requirements,
            Cost: service.Cost,
            LocationType: service.LocationType,
            Notes: service.Notes,
            ServiceSubtype: service.ServiceSubtype,
          })
        }
      }
      dispatch(serviceActions.setLoading(false))
    })
    .catch((err) => {
      console.log('Error', err)
      dispatch(serviceActions.setLoading(false))
    })
}

export const updateService = (payload, actions) => (dispatch) => {
  dispatch(serviceActions.setLoading(true))
  Api.put('programServices/updateProgramService', payload)
    .then(() => {
      dispatch(serviceActions.setLoading(false))
      message.success('Service Updated Successfully')
      if (actions) {
        actions()
      }
    })
    .catch((err) => {
      console.log('Error', err)
      dispatch(serviceActions.setLoading(false))
      message.error('An Error Occured')
    })
}

export const updateServiceStatus = (id, status, actions) => (dispatch) => {
  dispatch(serviceActions.setLoading(true))
  if (status === 1) {
    Api.get(`programServices/deactiveService?id=${id}`)
      .then(() => {
        dispatch(serviceActions.setLoading(false))
        if (actions) {
          actions()
        }
        message.success('Service Deactivated Successfully')
      })
      .catch((error) => {
        console.log('error', error)
        message.error('Something Went Wrong')
        dispatch(serviceActions.setLoading(false))
      })
  } else if (status === 0) {
    Api.get(`programServices/activeService?id=${id}`)
      .then(() => {
        dispatch(serviceActions.setLoading(false))
        if (actions) {
          actions()
        }
        message.success('Service Activated Successfully')
      })
      .catch((error) => {
        console.log('error', error)
        message.error('Something Went Wrong')
        dispatch(serviceActions.setLoading(false))
      })
  }
}
