import React, { useState } from 'react'
import { Card, Input, Tooltip } from 'antd'
import { BsBookmarkHeart, BsBookmarkHeartFill } from 'react-icons/bs'
import { SearchOutlined } from '@ant-design/icons'
import { OrganizationList, FavouriteList } from './components'

function DonorOrganizationsView() {
  const [isFavSelected, setIsFavSelected] = useState(false)
  const [searchText, setSearchText] = useState(null)

  return (
    <Card
      bordered={false}
      className="header-solid mb-24"
      title={
        <div className="t-flex t-flex-wrap t-justify-between">
          <div>
            <h6 className="font-semibold  t-text-2xl">Organizations</h6>
            <p>
              {!isFavSelected
                ? 'All available organizations in your area'
                : 'Your favourite Organizations'}
            </p>
          </div>
          <div className="t-flex t-justify-end t-items-center t-space-x-2 t-mt-4 sm:t-mt-0">
            <div className="header-control t-mt-0">
              <Input
                className="header-search"
                placeholder="Search Organization"
                prefix={<SearchOutlined />}
                onChange={(e) => {
                  setSearchText(e.target.value)
                }}
              />
            </div>
            {isFavSelected ? (
              <Tooltip title="See all organizations">
                <BsBookmarkHeartFill
                  fontSize="1.7rem"
                  className="t-text-primary-100 t-cursor-pointer"
                  onClick={() => {
                    setIsFavSelected(false)
                  }}
                />
              </Tooltip>
            ) : (
              <Tooltip title="See favourite organization">
                <BsBookmarkHeart
                  fontSize="1.7rem"
                  className="t-text-primary-100 t-cursor-pointer"
                  onClick={() => {
                    setIsFavSelected(true)
                  }}
                />
              </Tooltip>
            )}
          </div>
        </div>
      }
    >
      <>
        {!isFavSelected ? (
          <OrganizationList searchText={searchText} />
        ) : (
          <FavouriteList searchText={searchText} />
        )}
      </>
    </Card>
  )
}

export { DonorOrganizationsView }
