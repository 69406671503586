import React, { useState } from 'react'
import { Card, Skeleton, Pagination } from 'antd'
import { useEventSelector } from 'store/eventSlice/eventReducer'
import { config } from 'configs'
import { formatAmount, paginate } from 'utils'
import moment from 'moment'

function Donations() {
  const { loading, eventDetail } = useEventSelector()

  const [page, setPage] = useState(1)

  const donations = paginate(eventDetail?.donations, page)

  return (
    <Card className="t-w-full">
      <Skeleton loading={loading} active paragraph={{ rows: 12 }}>
        <div className="t-h-80">
          {eventDetail?.donations?.length === 0 && (
            <div className="t-flex t-justify-center t-items-center t-h-full t-w-full">
              No Donations
            </div>
          )}
          {donations.map((item, index) => (
            <div
              key={index}
              className={`t-w-full t-rounded-2xl t-h-12 t-p-3 ${
                true ? 't-bg-yellow-100' : 't-bg-red-100'
              } t-text-primary-100 t-mb-2 t-flex t-items-center t-shadow-md t-justify-between`}
            >
              <div className="t-flex t-space-x-2 t-items-center">
                <div className="t-w-8 t-h-8 t-rounded-full t-flex t-items-center t-justify-center t-bg-primary-100 t-p-2 t-shadow-lg t-text-white t-font-bold">
                  {item.DonorName
                    ? item.DonorName.substring(0, 1).toUpperCase()
                    : ''}
                </div>
                <h1 className="t-font-bold t-text-xs md:t-text-sm">
                  {`${item.DonorName} donated via ${item.PaymentMethod}`}
                </h1>
              </div>
              <div className="t-flex t-flex-col">
                <h2 className="t-font-bold t-text-primary-100 t-text-right t-text-xs md:t-text-sm">
                  {formatAmount(item.Amount)}
                </h2>
                <h2 className="t-text-gray-800 t-italic t-text-xs md:t-text-sm t-whitespace-nowrap t-text-right">
                  {moment(moment.utc(item.PaymentDate)).local().fromNow()}
                </h2>
              </div>
            </div>
          ))}
        </div>
        <div className="t-flex t-justify-center t-items-center t-mt-4">
          <Pagination
            showSizeChanger={false}
            defaultPageSize={config.rowsPerPage}
            current={page}
            total={eventDetail?.donations?.length}
            onChange={(current) => setPage(current)}
          />
        </div>
      </Skeleton>
    </Card>
  )
}

export { Donations }
