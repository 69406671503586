/* eslint-disable */
import React, { useEffect, useReducer } from 'react'
import { Route, Switch, withRouter } from 'react-router'
import { Layout, Drawer, Affix } from 'antd'
import { Navigation } from 'components/layouts/main/navigation'
import { HeaderTop } from 'components/layouts/main/header'
import { useHistory } from 'react-router-dom'
import { useZipCode } from 'hooks'
import { config } from 'configs'
import { useAuthSelector } from 'store/authSlice/authReducer'
import routes from 'routes/routes'

const { Header, Content } = Layout
const { Sider } = Layout

const initialState = {
  visible: false,
  placement: 'right',
  currentComponent: '',
  sidebarColor: config?.colors?.primary,
  sidenavType: 'transparent',
  fixed: true,
  name: '',
  subName: '',
  breadcrumb: '',
  collapsed: false,
  activateMouseHover: false,
}

function reducer(state, action) {
  switch (action.type) {
    case 'visible':
      return { ...state, visible: action.payload }
    case 'placement':
      return { ...state, placement: action.payload }
    case 'currentComponent':
      return { ...state, currentComponent: action.payload }
    case 'sidebarColor':
      return { ...state, sidebarColor: action.payload }
    case 'fixed':
      return { ...state, fixed: action.payload }
    case 'name':
      return { ...state, name: action.payload }
    case 'subName':
      return { ...state, subName: action.payload }
    case 'breadcrumb':
      return { ...state, breadcrumb: action.payload }
    case 'collapsed':
      return { ...state, collapsed: action.payload }
    case 'activateMouseHover':
      return { ...state, activateMouseHover: action.payload }
    default:
      return state
  }
}

const Main = withRouter((props) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const { user } = useAuthSelector((state) => state)
  const history = useHistory()
  const { isLoading, isAcccessGranted, isSuccess, message } = useZipCode()

  useEffect(() => {
    if (!user) {
      history.push('/auth')
    }
  }, [history, user])

  useEffect(() => {
    if (user) {
      if (props?.location?.pathname) {
        let page = props.location.pathname.split('/')
        page = page[page.length - 1]
        dispatch({ type: 'currentComponent', payload: page })
      }
    }
  }, [user, props.location.pathname])

  const handleSidebarColor = (color) => {
    dispatch({ type: 'sidebarColor', payload: color })
  }

  const OpenDrawer = () => {
    dispatch({ type: 'visible', payload: !state.visible })
  }

  const handleSidenavType = (type) => {
    dispatch({ type: 'sidenavType', payload: type })
  }

  const handleFixedNavbar = (type) => {
    dispatch({ type: 'fixed', payload: type })
  }

  const toggleCollasped = () => {
    dispatch({ type: 'collapsed', payload: !state.collapsed })
  }

  const menu = routes.map((route, index) =>
    route.component ? (
      <Route
        key={index}
        path={route.path}
        exact={route.exact}
        name={route.name}
        render={(props) => <route.component {...props} key={index} />}
      />
    ) : null,
  )

  return (
    <>
      {isLoading && isAcccessGranted && isSuccess && message && false ? (
        <div>false</div>
      ) : (
        <Layout
          className={`layout-dashboard ant-layout ant-layout-has-sider has-sidebar ${
            state.currentComponent === 'all-projects' ||
            state.currentComponent === 'profile-overview'
              ? 'layout-profile'
              : ''
          } ${state.collapsed ? ' sidebar-minimized' : ''}

         ${state.currentComponent === 'new-product' ? 'layout-profile' : ''}
        ${state.currentComponent === 'rtl' ? 'layout-dashboard-rtl' : ''}
        `.trim()}
        >
          <Drawer
            title={false}
            placement={state.placement === 'right' ? 'left' : 'right'}
            closable={false}
            onClose={() =>
              dispatch({ type: 'visible', payload: !state.visible })
            }
            visible={state.visible}
            key={state.placement === 'right' ? 'left' : 'right'}
            width={250}
            className={`drawer-sidebar ${
              state.currentComponent === 'rtl' ? 'drawer-sidebar-rtl' : ''
            } `}
          >
            <Layout
              className={`layout-dashboard ${
                state.currentComponent === 'rtl' ? 'layout-dashboard-rtl' : ''
              }`}
            >
              <Sider
                trigger={null}
                width={250}
                theme="light"
                className={`sider-primary ant-layout-sider-primary ${
                  state.sidenavType === '#fff' ? 'active-route' : ''
                }`}
                style={{
                  background: state.sidenavType,
                }}
              >
                <Navigation
                  currentSelected={state.currentComponent}
                  handleSidebarColor={handleSidebarColor}
                  collapsed={state.collapsed}
                />
              </Sider>
            </Layout>
          </Drawer>
          <Sider
            breakpoint="lg"
            collapsedWidth="0"
            trigger={null}
            width={250}
            theme="light"
            className={`sider-primary ant-layout-sider-primary ${
              state.sidenavType === '#fff' ? 'active-route' : ''
            }`}
            style={{ background: state.sidenavType }}
          >
            <Navigation
              currentSelected={state.currentComponent}
              handleSidebarColor={handleSidebarColor}
              collapsed={state.collapsed}
            />
          </Sider>
          <Layout id="temp__div">
            {state.fixed ? (
              <Affix>
                <Header
                  style={{ margin: '0px', backgroundColor: '#fff' }}
                  className={`${state.fixed ? 'ant-header-fixed' : ''}`}
                >
                  <HeaderTop
                    onPress={OpenDrawer}
                    breadcrumb={state.breadcrumb}
                    name={state.name}
                    subname={state.subName}
                    handleSidebarColor={handleSidebarColor}
                    handleSidenavType={handleSidenavType}
                    handleFixedNavbar={handleFixedNavbar}
                    toggleCollasped={toggleCollasped}
                  />
                </Header>
              </Affix>
            ) : (
              <Header
                style={{
                  margin: '0px',
                  backgroundColor: '#FAFAFA',
                  boxShadow: '0px 2px 3px #0000001f',
                }}
                className={`${state.fixed ? 'ant-header-fixed' : ''}`}
              >
                <HeaderTop
                  breadcrumb={state.breadcrumb}
                  onPress={OpenDrawer}
                  placement={state.placement}
                  name={state.name}
                  subname={state.subName}
                  handleSidebarColor={handleSidebarColor}
                  handleSidenavType={handleSidenavType}
                  handleFixedNavbar={handleFixedNavbar}
                  toggleCollasped={toggleCollasped}
                />
              </Header>
            )}
            <Content className="content-ant t-mt-3 md:t-mt-3 t-p-1 md:t-p-2">
              <Switch>{menu}</Switch>
            </Content>
          </Layout>
        </Layout>
      )}
    </>
  )
})
export { Main }
