import { createSlice } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'

const initialState = {
  loading: false,
  organizations: [],
  events: [],
  programs: [],
  services: [],
  volunteers: [],
}

export const favouriteSlice = createSlice({
  name: 'favourite',
  initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      const alteredState = { ...state, loading: payload }
      return alteredState
    },
    setOrganizations: (state, { payload }) => {
      const alteredState = { ...state, organizations: payload }
      return alteredState
    },
    setEvents: (state, { payload }) => {
      const alteredState = { ...state, events: payload }
      return alteredState
    },
    setPrograms: (state, { payload }) => {
      const alteredState = { ...state, programs: payload }
      return alteredState
    },
    setServices: (state, { payload }) => {
      const alteredState = { ...state, services: payload }
      return alteredState
    },
    setVolunteers: (state, { payload }) => {
      const alteredState = { ...state, volunteers: payload }
      return alteredState
    },
  },
})

export const useFavouriteSelector = () =>
  useSelector((state) => state.favourite)
export const favouriteActions = favouriteSlice.actions
export const favouriteReducer = favouriteSlice.reducer
