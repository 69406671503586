/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { Skeleton } from 'antd'
import Api from 'api'

function PledgeCommittments({ id }) {
  const [loading, setLoading] = useState()
  const [report, setReport] = useState()

  useEffect(() => {
    setLoading(true)
    Api.get(`adminReports/getPledgersListByEvent?id=${id}`)
      .then((res) => {
        console.log('reasdsads', res)
        setReport(res.results)
        setLoading(false)
      })
      .catch((err) => {
        console.log('Error', err)
        setLoading(false)
      })
    // eslint-disable-next-line
  }, [])

  return (
    <Skeleton loading={loading} paragraph={{ rows: 12 }}>
      {report && report.length > 0 ? (
        <div className="t-space-x-4">
          {report.map((item, index) => (
            <div className="t-space-y-4" key={index}>
              <div className="t-flex t-items-center t-justify-between">
                <p>Name</p>
                {item.name}
              </div>
              <div className="t-flex t-items-center t-justify-between">
                <p>Phone Number</p>
                {item.phoneNumber}
              </div>
              <div className="t-flex t-items-center t-justify-between">
                <p>Email</p>
                {item.email}
              </div>
              <div className="t-flex t-items-center t-justify-between">
                <p>Amount</p>
                {item.amount}
              </div>
              <div className="t-flex t-items-center t-justify-between">
                <p>Pledge Date</p>
                {item.pledgeDate}
              </div>
              <div className="t-flex t-items-center t-justify-between">
                <p>Annual Recurring</p>
                {item.annaulRecurring ? 'Yes' : 'No'}
              </div>
              <div className="t-flex t-items-center t-justify-between">
                <p>Split Payment</p>
                {item.splitPayment ? 'Yes' : 'No'}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div>No Data Found</div>
      )}
    </Skeleton>
  )
}

export default PledgeCommittments
