import React from 'react'
import { Card, Tabs } from 'antd'
import { MyAccount, MyContributions } from './components'

function Account() {
  return (
    <Card className="header-solid" bordered={false}>
      <Tabs
        destroyInactiveTabPane
        animated={false}
        className="sliding-tab"
        defaultActiveKey="1"
      >
        <Tabs.TabPane tab="Account" key="1">
          <MyAccount />
        </Tabs.TabPane>
        <Tabs.TabPane tab="My Contributions" key="2">
          <MyContributions />
        </Tabs.TabPane>
      </Tabs>
    </Card>
  )
}

export { Account }
