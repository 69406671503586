/* eslint-disable */
import React from 'react'
import { withRouter } from 'react-router'
import { menuItems } from 'configs'
import { useSelector } from 'react-redux'
import NavLogo from './navLogo'
import NavContent from './navContent'

const filterRoutesByRole = (mymenu, roles) => {
  let newRoutes = mymenu.map((menu) => ({
    ...menu,
    children: menu.children.filter((child) => {
      if (child.type === 'collapse') {
        return true
      }
      return child.roles.some((role) => roles.includes(role))
    }),
  }))
  newRoutes = newRoutes.map((menu) => ({
    ...menu,
    children: menu.children.map((child) => {
      if (child.type === 'collapse') {
        return {
          ...child,
          children: child.children.filter((inside) =>
            inside.roles.some((role) => roles.includes(role)),
          ),
        }
      }
      return child
    }),
  }))
  return newRoutes
}

const Navigation = withRouter((props) => {
  const user = useSelector((state) => state?.auth?.user)
  const roles = user?.roles || []
  return (
    <>
      <NavLogo
        collapsed={props.collapsed}
        collapseMenu={props.collapseMenu}
        windowWidth={props.windowWidth}
        onToggleNavigation={props.onToggleNavigation}
      />
      <hr />
      <NavContent
        collapsed={props.collapsed}
        navigation={filterRoutesByRole(menuItems.items, roles)}
        handleSidebarColor={props.handleSidebarColor}
        currentSelected={props.currentSelected}
      />
    </>
  )
})

export { Navigation }
