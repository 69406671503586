/* eslint-disable */
import React, { useEffect } from 'react'
import { Icon } from 'components'
import { useHistory } from 'react-router-dom'
import { config } from 'configs'

function Splash({ text, setIsSplash }) {
  const history = useHistory()

  useEffect(() => {
    setTimeout(() => {
      if (setIsSplash) {
        setIsSplash(false)
      } else {
        history.push('/organizations')
      }
    }, config.splash_screen_seconds * 1000)
  })

  return (
    <div className="t-h-screen t-flex t-flex-col t-justify-center t-items-center">
      <Icon src="logo_icon" />
      <p className="t-mt-7 t-text-5xl t-w-251 t-text-center font-palatino">
        {text || 'Welcome'}
      </p>
    </div>
  )
}

export { Splash }
