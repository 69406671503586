import React from 'react'
import { IoIosArrowBack } from 'react-icons/io'
import { useHistory } from 'react-router-dom'
import { useEventSelector } from 'store/eventSlice/eventReducer'
import { MdLocationOn, MdEdit } from 'react-icons/md'
import { HiDuplicate } from 'react-icons/hi'
import { TbTarget } from 'react-icons/tb'
import { formatAmount, formateDateTime } from 'utils'
import { addEvent, updateEventStatus } from 'store/eventSlice/eventActions'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { addFavourite } from 'store/favouriteSlice/favouriteActions'
import { useDispatch } from 'react-redux'
import { config } from 'configs'
import {
  AiFillSchedule,
  AiOutlineHeart,
  AiFillEye,
  AiFillEyeInvisible,
} from 'react-icons/ai'
import {
  Row,
  Col,
  Card,
  Descriptions,
  Button,
  message,
  Typography,
  Tooltip,
} from 'antd'

import sample from 'assets/images/sample/sample1.png'

const { Title, Paragraph } = Typography

function CampaignView() {
  const history = useHistory()
  const dispatch = useDispatch()
  const { event } = useEventSelector()
  const { location, user } = useAuthSelector()

  const positionData = {
    lat: location?.lat,
    lon: location?.lng,
    zipCode: location?.zipcode,
  }

  const duplicateEvent = async () => {
    const newEvent = { ...event }
    delete newEvent?.Id
    try {
      await addEvent(newEvent, user)
      message.success('Event Duplicated Successfully')
      history.push('/campaigns')
    } catch (error) {
      console.log('error', error)
      message.error('Something went wrong')
    }
  }

  const updateEvent = () => {
    dispatch(updateEventStatus(event?.Id, event?.ActiveInd, positionData))
    history.push('/campaigns')
  }

  const startEventControlling = (type) => {
    history.push(`/events/${event?.Id}/control-screen/${type}`)
  }

  const joinEvent = () => {
    history.push(`/events/${event?.Id}/join-event`)
  }

  const handleAddFavourite = async (id) => {
    const payload = {
      ReferenceId: id,
      Username: user?.id,
      Type: 'event',
    }
    dispatch(addFavourite(payload))
    history.push('/events')
  }
  const isCreator = event?.CreatedBy === user.email
  const isPresenter = event?.PresenterEmail === user.email
  const isEventStarted = event?.startEvent
  const isDonor = !isCreator && !isPresenter

  return (
    <>
      <Row gutter={[24, 0]}>
        <Col span={24}>
          <Card
            title={[
              <div className="t-flex t-justify-between">
                <div className="t-flex t-items-center t-space-x-4">
                  <Button
                    type="primary"
                    className="t-rounded-full t-flex t-items-center"
                    onClick={() => history.push('/campaigns')}
                    size="small"
                  >
                    <IoIosArrowBack />
                    Back
                  </Button>
                </div>
                <div className="t-flex t-items-center t-space-x-2 t-cursor-pointer">
                  <Tooltip title="Make Favourite">
                    <AiOutlineHeart
                      fontSize="1.5rem"
                      className="t-cursor-pointer"
                      onClick={() => handleAddFavourite(event.Id)}
                    />
                  </Tooltip>
                  {isCreator && (
                    <>
                      <MdEdit
                        fontSize="1.5rem"
                        onClick={() =>
                          history.push(`/settings/create-event/${event.Id}`)
                        }
                      />
                      <HiDuplicate fontSize="1.5rem" onClick={duplicateEvent} />
                      {event?.ActiveInd === 1 ? (
                        <AiFillEyeInvisible
                          fontSize="1.5rem"
                          onClick={updateEvent}
                        />
                      ) : (
                        <AiFillEye fontSize="1.5rem" onClick={updateEvent} />
                      )}
                    </>
                  )}
                </div>
              </div>,
            ]}
            bordered={false}
            className="header-solid card-product mb-24"
          >
            <Row gutter={[24, 0]} style={{ minHeight: 'calc(100vh - 220px)' }}>
              <Col span={24} lg={12} xl={10} className="col-gallery mb-24">
                <img
                  src={
                    event?.Flyer?.fileName
                      ? config.bucketUrl + event?.Flyer?.fileName
                      : sample
                  }
                  className="t-h-96 t-w-full gallery-img shadow-3 img-contain"
                  alt="card-2"
                />
              </Col>
              <Col span={24} xl={12} lg={{ offset: 1 }} className="t-p-4">
                <div className="t-mb-4">
                  <Title level={1} className="t-text-primary-100">
                    {event?.Title}
                  </Title>
                  <Title level={4} className="t-text-primary-100">
                    {event?.OrganizationName}
                  </Title>
                  <Descriptions colon={false} className="t-my-4">
                    <Descriptions.Item
                      label={
                        <AiFillSchedule
                          fontSize="1.2rem"
                          className="t-text-primary-100"
                        />
                      }
                      span={3}
                    >
                      {formateDateTime(event?.StartDate)}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={
                        <MdLocationOn
                          fontSize="1.2rem"
                          className="t-text-primary-100"
                        />
                      }
                      span={3}
                    >
                      {event?.zipCode}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={
                        <TbTarget
                          fontSize="1.2rem"
                          className="t-text-primary-100"
                        />
                      }
                      span={3}
                    >
                      {formatAmount(event?.TargetAmount)}
                    </Descriptions.Item>
                  </Descriptions>
                  <Title level={4} className="t-text-primary-100">
                    Description
                  </Title>
                  <Paragraph className="t-text-justify">
                    {event?.Details}
                  </Paragraph>
                  {isDonor && isEventStarted && !isCreator && (
                    <div className="t-my-4 t-space-x-2">
                      <Button type="primary" onClick={joinEvent}>
                        Join Event
                      </Button>
                    </div>
                  )}
                  {isCreator && (
                    <div className="t-my-4 t-space-x-2">
                      <Button
                        type="primary"
                        onClick={() => startEventControlling(false)}
                      >
                        Control Screen
                      </Button>
                      <Button
                        type="primary"
                        onClick={() => startEventControlling(true)}
                      >
                        Campaign Screen
                      </Button>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export { CampaignView }
