import React, { useEffect, useState } from 'react'
import {
  Row,
  Switch,
  Form,
  Checkbox,
  Button,
  Col,
  message,
  Typography,
  Input,
} from 'antd'
import { useEventSelector } from 'store/eventSlice/eventReducer'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { addCashDonation } from 'store/paymentSlice/paymentActions'
import { formatAmount } from 'utils'
import * as Yup from 'yup'

const { Title } = Typography
const numberValidation = new RegExp('^[0-9]+$')

const schema = Yup.object().shape({
  askedAmount: Yup.string()
    .required('Required')
    .matches(numberValidation, 'Invalid amount'),
  includeEventCosts: Yup.string().required('Required'),
  agreeToTerms: Yup.string().required('Required'),
})

const cashValidation = {
  async validator({ field }, value) {
    await schema.validateSyncAt(field, { [field]: value })
  },
}

const initialValues = {
  askedAmount: null,
  includeEventCosts: false,
  agreeToTerms: false,
}

function Cash({ refreshEventData }) {
  const [loading, setLoading] = useState(false)
  const { event, joinedEventDetails } = useEventSelector()
  const { user, location } = useAuthSelector()
  const [form] = Form.useForm()

  Form.useWatch('agreeToTerms', form)
  Form.useWatch('askedAmount', form)
  Form.useWatch('includeEventCosts', form)

  useEffect(() => {
    form.setFieldsValue({ askedAmount: event?.CurrentAsk })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event?.CurrentAsk])

  /* eslint-disable */
  const onFinish = async (values) => {
    const askedAmountValue = Number(values.askedAmount)
    const includeEventCostValue = values.includeEventCosts
    const twoPercentage = (2 / 100) * Number(askedAmountValue)
    const totalDonation = includeEventCostValue
      ? askedAmountValue + twoPercentage
      : askedAmountValue

    if (!values.agreeToTerms) {
      return message.error('You must agree to the terms and conditions')
    }
    try {
      setLoading(true)
      const data = {}

      data.eventId = event.Id
      data.EventName = event.Title
      data.fullName = `${user?.firstname} ${user?.lastname}`
      data.donorId = user.id
      data.email = user?.email
      data.DonorPhoneNumber = user.phone

      data.isAttendingOnline = joinedEventDetails?.isRemote
      data.isDonatingAnonymously = joinedEventDetails?.donateAnonymously
      data.table = joinedEventDetails?.tableName || null

      data.zipCode = location?.zipCode

      data.donorId = user?.id
      data.payType = 'Cash'
      data.Status = 'Pending'
      data.transactionType =
        event.EventType === 'Fundraiser' ? 'event' : 'campaign'

      data.splitPayment = values?.splitPledge
      data.splitPaymentNoMonths = values?.splitnoOfMonths
      data.amount = totalDonation
      data.recurringBill = values?.repeatMonths
      data.recurringMonths = values?.repeatnoOfMonths
      data.contributeToEventCost = values?.includeEventCosts

      await addCashDonation(data)
      refreshEventData()
      message.success('Your cash donation is successfully submitted')

      setLoading(false)
    } catch (error) {
      console.log('error', error)
      message.error('Something went wrong')
      setLoading(false)
    }
  }

  const askedAmountValue = Number(form.getFieldValue('askedAmount'))
  const includeEventCostValue = form.getFieldValue('includeEventCosts')

  const twoPercentage = (2 / 100) * Number(askedAmountValue)
  const totalDonation = includeEventCostValue
    ? askedAmountValue + twoPercentage
    : askedAmountValue

  return (
    <div>
      <Form
        form={form}
        layout="vertical"
        key={0}
        onFinish={onFinish}
        name="event"
        initialValues={initialValues}
        scrollToFirstError
      >
        <Row gutter={[24, 0]} className="t-p-3">
          <Col xl={24} md={24} xs={24} sm={24}>
            <Form.Item
              name="askedAmount"
              label="Asked Amount"
              colon={false}
              rules={[cashValidation]}
            >
              <Input disabled={!event?.EditAskedAmount} placeholder="50000" />
            </Form.Item>
          </Col>
          <Col xl={24} md={24} xs={24} sm={24}>
            <Form.Item
              name="includeEventCosts"
              label="Include Event Cost"
              colon={false}
              rules={[cashValidation]}
            >
              <Switch />
            </Form.Item>
          </Col>
          <Col xl={24} md={24} xs={24} sm={24}>
            <Form.Item
              name="agreeToTerms"
              colon={false}
              rules={[cashValidation]}
            >
              <Checkbox
                onChange={(e) =>
                  form.setFieldsValue({
                    agreeToTerms: e.target.checked,
                  })
                }
              >
                <span className="font-bold text-dark hover:t-text-primary-100 t-cursor-pointer">
                  I hereby agree to pay and adhere to Pledge Garden Terms &
                  Conditions
                </span>
              </Checkbox>
            </Form.Item>
          </Col>
          <Col xl={24} md={24} xs={24} sm={24}>
            <Row justify="end">
              <Title level={3}>
                {`Total Donation = ${formatAmount(totalDonation)}`}
              </Title>
            </Row>
          </Col>
          <Col span={24} className="text-right">
            <Button
              loading={loading}
              type="primary"
              className="px-25"
              htmlType="submit"
            >
              Donate Via Cash
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export { Cash }
