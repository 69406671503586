import React, { useEffect, useState } from 'react'
import { Button, Form } from 'antd'
import { config } from 'configs'
import OTPInput, { ResendOTP } from 'otp-input-react'
import { Timer } from './Timer'

function OTPForm({ loading, onSubmit, resendOtp }) {
  const time = new Date()
  time.setSeconds(time.getSeconds() + 30)
  const [form] = Form.useForm()
  const [showResendBtn, setShowResendBtn] = useState(false)

  Form.useWatch('optCode', form)
  const otp = form.getFieldValue('optCode')
  const isValidOtp = !(otp && otp.length === 4)

  useEffect(() => {
    if (!showResendBtn) {
      setTimeout(() => {
        setShowResendBtn(true)
      }, config.splash_screen_seconds * 1000)
    }
  }, [showResendBtn])

  const onFinish = (values) => {
    onSubmit(values)
  }

  const renderButton = (timestamp) => (
    <>
      {!showResendBtn ? (
        <Timer expiryTimestamp={timestamp} />
      ) : (
        <Button
          htmlType="button"
          style={{ marginLeft: '0.5rem' }}
          onClick={() => resendOtp()}
        >
          Resend
        </Button>
      )}
    </>
  )

  return (
    <Form form={form} name="otp" onFinish={onFinish} className="row-col">
      <div className="t-flex t-flex-1 t-justify-center t-items-center t-my-6">
        <Form.Item name="optCode" className="t-mb-0">
          <OTPInput
            autoFocus
            OTPLength={4}
            otpType="number"
            disabled={false}
            inputClassName="t-w-12 t-h-12 t-rounded-md t-border-2 tt-border-black"
          />
        </Form.Item>
        <ResendOTP
          renderButton={() => renderButton(time)}
          renderTime={() => <></>}
        />
      </div>
      <p className="text-right t-pb-4 t-font-semibold">
        * Message And Data Rates May Apply
      </p>
      <Form.Item>
        <Button
          style={{ width: '100%' }}
          type="primary"
          htmlType="submit"
          loading={loading}
          disabled={isValidOtp}
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  )
}

export { OTPForm }
