// import { Dashboard } from "screens/main/dashboard";
import { OrganizationsList } from 'screens/main/organizations'
import { Events } from 'screens/main/events'
import { Programs } from 'screens/main/programs'
import { Services } from 'screens/main/services'
import { Volunteer } from 'screens/main/volunteer'

import { Account } from 'screens/main/settings/account'
import { CreateEvent } from 'screens/main/settings/create-event'
import { CreateProgram } from 'screens/main/settings/create-program'
import { CreateService } from 'screens/main/settings/create-service'
import { CreateVolunteer } from 'screens/main/settings/create-volunteer'
import { MyInvites } from 'screens/main/settings/my-invites'
import { ManageGovDocs } from 'screens/main/settings/manage-gov-docs'
import { ManageOrganization } from 'screens/main/settings/manage-organization'
import { RegisterOrganization } from 'screens/main/settings/register-organization'
import { Payment } from 'screens/main/settings/payment'
import { EventControl } from 'screens/main/events/components/EventControl'
import { EventView } from 'screens/main/events/components/EventView'
import { OrganizationView } from 'screens/main/organizations/donor/components'
import { Campaigns } from 'screens'
import { CampaignView } from 'screens/main/campaigns/components/CampaignView'
import { VolunteerView } from 'screens/main/volunteer/components/VolunteerView'
import { JoinEvent } from 'screens/main/events/components/JoinEvent'
import { ProgramView } from 'screens/main/programs/components/ProgramView'
import { ServiceView } from 'screens/main/services/components/ServiceView'
import { RegisterService } from 'screens/main/services/components/Register'

const routes = [
  // {
  //   path: "/dashboard",
  //   exact: true,
  //   name: "Default",
  //   component: Dashboard,
  // },
  {
    path: '/organizations',
    exact: true,
    name: 'Organizations',
    component: OrganizationsList,
  },
  {
    path: '/organizations/:id',
    exact: true,
    name: 'Organizations',
    component: OrganizationView,
  },
  {
    path: '/events',
    exact: true,
    name: 'Events',
    component: Events,
  },
  {
    path: '/events/:id',
    exact: true,
    name: 'Events',
    component: EventView,
  },
  {
    path: '/events/:id/control-screen/:type',
    exact: true,
    name: 'Event Control Screen',
    component: EventControl,
  },
  {
    path: '/events/:id/join-event',
    exact: true,
    name: 'Join Event',
    component: JoinEvent,
  },
  {
    path: '/campaigns',
    exact: true,
    name: 'Campaigns',
    component: Campaigns,
  },
  {
    path: '/campaigns/:id',
    exact: true,
    name: 'Campaigns',
    component: CampaignView,
  },
  {
    path: '/programs',
    exact: true,
    name: 'Programs',
    component: Programs,
  },
  {
    path: '/programs/:id',
    exact: true,
    name: 'Programs',
    component: ProgramView,
  },
  {
    path: '/services',
    exact: true,
    name: 'Services',
    component: Services,
  },
  {
    path: '/services/:id',
    exact: true,
    name: 'Services',
    component: ServiceView,
  },
  {
    path: '/register-for-service/:type',
    exact: true,
    name: 'Services',
    component: RegisterService,
  },
  {
    path: '/volunteer',
    exact: true,
    name: 'Volunteer',
    component: Volunteer,
  },
  {
    path: '/volunteer/:id',
    exact: true,
    name: 'Volunteer',
    component: VolunteerView,
  },
  {
    path: '/settings/account',
    exact: true,
    name: 'Account',
    component: Account,
  },
  {
    path: '/settings/create-event',
    exact: true,
    name: 'Create Event',
    component: CreateEvent,
  },
  {
    path: '/settings/create-event/:id',
    exact: true,
    name: 'Edit Event',
    component: CreateEvent,
  },
  {
    path: '/settings/invites',
    exact: true,
    name: 'Invites',
    component: MyInvites,
  },
  {
    path: '/settings/create-program',
    exact: true,
    name: 'Create A Program',
    component: CreateProgram,
  },
  {
    path: '/settings/create-program/:id',
    exact: true,
    name: 'Create A Program',
    component: CreateProgram,
  },
  {
    path: '/settings/create-a-service',
    exact: true,
    name: 'Create A Service',
    component: CreateService,
  },
  {
    path: '/settings/create-a-service/:id',
    exact: true,
    name: 'Create A Servie',
    component: CreateService,
  },
  {
    path: '/settings/create-volunteer',
    exact: true,
    name: 'Create Volunteer',
    component: CreateVolunteer,
  },
  {
    path: '/settings/create-volunteer/:id',
    exact: true,
    name: 'Edit Volunteer',
    component: CreateVolunteer,
  },
  {
    path: '/settings/register-organization',
    exact: true,
    name: 'Register Organization',
    component: RegisterOrganization,
  },
  {
    path: '/settings/register-organization/:id',
    exact: true,
    name: 'Edit Organization',
    component: RegisterOrganization,
  },
  {
    path: '/settings/manage-organization',
    exact: true,
    name: 'Manage Organization',
    component: ManageOrganization,
  },
  {
    path: '/settings/manage-payments-accounts',
    exact: true,
    name: 'Manage Payment Accounts',
    component: Payment,
  },
  {
    path: '/settings/manage-gov-issued-docs',
    exact: true,
    name: 'Manage Gov Issued Docs',
    component: ManageGovDocs,
  },
]

export default routes
