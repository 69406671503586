import React from 'react'

const data = [
  {
    title: 'Definitions',
    content: (
      <>
        <p>
          “Event” means an orchestrated occurrence of activities conducted by
          representatives of our Customers (hereinafter “Subscribers”)
        </p>
        <p>
          “Campaign” means a cause, goal, or event for which a Subscriber seeks
          donations through the Services.
        </p>
        <p>
          “Subscriber” means an individual or organization who creates or
          manages an Event, Campaign, and / or coordinate Volunteer
          Opportunities.
        </p>
        <p>
          “Donor” means an individual or organization who donates to an Event or
          Campaign.
        </p>
        <p>
          “User” means any individual or organization that utilizes or consumes
          Services, with or without creating a user account.
        </p>
      </>
    ),
  },
  {
    title: 'Eligibility',
    content: (
      <p>
        To use the Services you must be, and represent and warrant that you are,
        of legal age (18 years of age or older or otherwise of legal age in your
        jurisdiction, or, if you have parental consent, 13 years of age) and
        competent to agree to these Terms. If Pledge Garden has previously
        prohibited you from accessing or using the Services, you are not
        permitted to access or use the Services.
      </p>
    ),
  },
  {
    title: 'Additional Terms and Policies',
    content: (
      <p>
        Please review our Privacy Policy , incorporated herein by reference, for
        information and notices concerning our collection and use of your
        information. Certain areas of and/or products on the Services may have
        different terms and conditions posted, which may require you to agree
        with and accept such additional terms and conditions or policies. If
        there is a conflict between these Services and the terms and conditions
        or policies posted for a specific area or product, the latter take
        precedence with respect to your use of that area or product.
      </p>
    ),
  },
  {
    title: 'Modifications',
    content: (
      <p>
        We may, from time to time, modify these Terms. Please check this page
        periodically for updates. Any changes will be posted on the Services. If
        you do not agree to, or cannot comply with, the modified Terms, you must
        stop using the Services. The updated Terms will take effect after their
        posting and will apply on a going-forward basis, unless otherwise
        provided in a notice to you, and except as provided in the Arbitration
        and Class Action Waiver section of these Terms. Your continued use of
        the Services after any such update constitutes your binding acceptance
        of such changes.
      </p>
    ),
  },
  {
    title: 'Account Registration',
    content: (
      <>
        <p>
          You may be required to create an account to use the Services. You
          agree to provide us with accurate, complete, and current registration
          information about yourself. As applicable, if you are prompted to use
          a user ID or password, it is your responsibility to ensure that your
          user ID password remains confidential and secure. By registering, you
          agree that you are fully responsible for all activities that occur
          under your account. We may assume that any communications we receive
          under your account have been made by you.
        </p>
        <p>
          You are responsible for notifying us at{' '}
          <a href="mailto:support@PledgeGarden.com">support@PledgeGarden.com</a>{' '}
          if you become aware of any unauthorized use of or access to your
          account. You understand and agree that we may require you to provide
          information that may be used to confirm your identity and help ensure
          the security of your account. Pledge Garden will not be liable for any
          loss, damages, liability, expenses, or attorneys’ fees that you may
          incur as a result of someone else using your password or account,
          either with or without your knowledge and/or authorization, and
          regardless of whether you have or have not advised us of such
          unauthorized use. You will be liable for losses, damages, liability,
          expenses, and attorneys’ fees incurred by Pledge Garden or a third
          party due to someone else using your account.
        </p>
      </>
    ),
  },
  {
    title: 'ModificaIntellectual Property and Other Proprietary Rightstions',
    content: (
      <p>
        The Services are owned and operated by Pledge Garden and contain
        materials (including all software, design, text, editorial materials,
        informational text, photographs, illustrations, audio clips, video
        clips, artwork and other graphic materials, and names, logos, trademarks
        and services marks) which are derived in whole or in part from materials
        supplied by Pledge Garden and its partners, as well as other sources,
        and is protected by United States copyright laws, international treaty
        provisions, trademarks, service marks and other intellectual property
        laws. The Services are also protected as a collective work or
        compilation under U.S. copyright and other law and treaties. You agree
        to abide by all applicable copyright and other laws, as well as any
        additional copyright notices or restrictions contained in the Services.
        You acknowledge that the Services have been developed, compiled,
        prepared, revised, selected, and arranged by Pledge Garden and others
        through the application of methods and standards of judgment developed
        and applied through the expenditure of substantial time, effort, and
        money and constitute valuable intellectual property of Pledge Garden and
        such others. You agree to protect the proprietary rights of Pledge
        Garden and all others having rights in the Services during and after the
        term of these Terms and to comply with all reasonable written requests
        made by Pledge Garden or its suppliers and licensors of content or
        otherwise to protect their and others’ contractual, statutory, and
        common law rights in the Services. You agree to notify Pledge Garden
        immediately upon becoming aware of any claim that the Services infringe
        upon any copyright, trademark, or other contractual, statutory, or
        common law rights. All present and future rights in and to trade
        secrets, patents, copyrights, trademarks, service marks, know-how, and
        other proprietary rights of any type under the laws of any governmental
        authority, domestic or foreign, including without limitation rights in
        and to all applications and registrations relating to the Services
        shall, as between you and Pledge Garden, at all times be and remain the
        sole and exclusive property of Pledge Garden.
      </p>
    ),
  },
  {
    title: 'User Content',
    content: (
      <>
        <p>User Content and Submissions</p>
        <p>
          The Services may enable you to submit, post, upload, or otherwise make
          available (collectively, “Post”) content such as Events, Campaigns,
          photographs, questions, ideas, comments, and other content
          (collectively, “User Content”) that may or may not be viewable by
          other users.
        </p>
        <p>Our License to User Content</p>
        <p>
          When you Post User Content on or through the Services, you grant
          Pledge Garden a world-wide, perpetual, irrevocable, royalty-free,
          non-exclusive, and sub-licenseable license to use, copy, distribute,
          reproduce, modify, edit, adapt, publicly perform, publicly display,
          translate, create derivative works from, sell, lease, transmit,
          disassemble, and publish such User Content or feedback, in whole or in
          part, in any format or medium now known or developed in the future,
          including without limitation for promoting and redistributing part or
          all of the Services (and derivative works thereof) in any media
          formats and through any media channels. Notwithstanding the foregoing,
          our use of your personal data shall be governed by the Pledge Garden
          Privacy Policy.
        </p>
        <p>User Content Representations</p>
        <p>
          You acknowledge and agree that all User Content that you Post is your
          sole responsibility. You further agree that you have all required
          rights to Post such User Content without violation of any third-party
          rights. You understand that Pledge Garden does not control, and is not
          responsible for, User Content, and that by using the Services, you may
          be exposed to User Content from other users that is offensive,
          indecent, inaccurate, misleading, or otherwise objectionable. Please
          also note that User Content may contain typographical errors, other
          inadvertent errors, or inaccuracies. You agree that you will
          indemnify, defend, and hold harmless Pledge Garden for all claims
          resulting from User Content you Post. We reserve the right, at our own
          expense, to assume the exclusive defense and control of such disputes,
          and in any event you will cooperate with us in asserting any available
          defenses.
        </p>
        <p>User Content Review</p>
        <p>
          You acknowledge and agree that Pledge Garden and its designees may or
          may not, at Pledge Garden’s discretion, pre-screen User Content Pledge
          Garden its appearance on the Services, but that Pledge Garden has no
          obligation to do so. You further acknowledge and agree that Pledge
          Garden reserves the right (but does not assume the obligation) in its
          sole discretion to reject, move, edit, or remove any User Content that
          is contributed to the Services. Without limiting the foregoing, Pledge
          Garden and its designees shall have the right to remove any User
          Content that violates these Terms or is otherwise objectionable in
          Pledge Garden&apos;s sole discretion. You acknowledge and agree that
          Pledge Garden does not verify, adopt, ratify, or sanction User
          Content, and you agree that you must evaluate and bear all risks
          associated with your use of User Content or your reliance on the
          accuracy, completeness, or usefulness of User Content.
        </p>
      </>
    ),
  },
]

function TermsAndConditions() {
  return (
    <div className="t-flex t-flex-col t-space-y-4 modalHeight t-overflow-scroll">
      <div>
        <p className="t-mb-4">
          <strong>This policy is effective since November 5, 2021.</strong>
        </p>
        <p>
          IMPORTANT NOTICE: THIS AGREEMENT CONTAINS A BINDING ARBITRATION
          PROVISION AND CLASS ACTION WAIVER. IT AFFECTS YOUR LEGAL RIGHTS AS
          DETAILED IN THE ARBITRATION AND CLASS ACTION WAIVER SECTION BELOW.
          PLEASE READ CAREFULLY.
        </p>
      </div>
      <div className="t-space-y-2">
        <p>
          Thank you for visiting Pledge Garden. Pledge Garden is an online donor
          relationship management and crowd-funding platform that supports
          organizations with nurturing a stronger relationship their support
          base of followers, supporters, donors, and volunteers. These Terms of
          Use (“Terms”) govern your access to, use of, and participation in the
          services made available by Pledge Garden, a subsidiary of Trustmarq
          Global Services, Inc. (“Pledge Garden,” “we,” “our,” or “us”),
          including our websites, products, tools, promotions, and any other
          services that reference these Terms (collectively, the “Services”). By
          accessing or otherwise using the Services, you agree to be bound by
          these Terms. If you do not agree to these Terms, you may not access or
          use the Services.
        </p>
        <p>
          All references to “you” or “your,” as applicable, mean the person who
          accesses, uses, and/or participates in the Services in any manner, and
          each of your heirs, assigns, and successors. If you use the Services
          on behalf of an entity, you represent and warrant that you have the
          authority to bind that entity, your acceptance of the Terms will be
          deemed an acceptance by that entity, and “you” and “your” herein shall
          refer to that entity.
        </p>
      </div>
      <div className="t-space-y-4">
        {data.map((item) => (
          <div>
            <p className="t-text-lg t-font-semibold">{item.title}</p>
            {item.content}
          </div>
        ))}
      </div>
    </div>
  )
}

export { TermsAndConditions }
