import React from 'react'
import { Row, Col, Card, Descriptions, Typography, Button, Tooltip } from 'antd'
import { useHistory } from 'react-router-dom'
import { useOrganizationSelector } from 'store/organizationSlice/organizationReducer'
import { IoIosArrowBack } from 'react-icons/io'
import { MdLocationOn } from 'react-icons/md'
import { AiFillPhone, AiOutlineHeart } from 'react-icons/ai'
import { VscTypeHierarchySub } from 'react-icons/vsc'
import { CgWebsite } from 'react-icons/cg'
import { FaCity, FaHome } from 'react-icons/fa'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { useDispatch } from 'react-redux'
import { addFavourite } from 'store/favouriteSlice/favouriteActions'

import sample from 'assets/images/sample/sample1.png'

const { Title, Paragraph } = Typography

function OrganizationView() {
  const dispatch = useDispatch()
  const history = useHistory()
  const { organization } = useOrganizationSelector()
  const { user } = useAuthSelector()

  const handleAddFavourite = async (id) => {
    const payload = {
      ReferenceId: id,
      Username: user?.id,
      Type: 'organization',
    }
    dispatch(addFavourite(payload))
    history.push('/organizations')
  }

  const formatPhoneNumber = (number) =>
    number.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
  return (
    <>
      <Row gutter={[24, 0]}>
        <Col span={24}>
          <Card
            title={[
              <div className="t-flex t-justify-between">
                <div className="t-flex t-items-center t-space-x-4">
                  <Button
                    type="primary"
                    className="t-rounded-full t-flex t-items-center"
                    onClick={() => history.push('/organizations')}
                    size="small"
                  >
                    <IoIosArrowBack />
                    Back
                  </Button>
                </div>
                <Tooltip title="Make Favourite">
                  <AiOutlineHeart
                    fontSize="1.8rem"
                    className="t-cursor-pointer t-text-primary-100"
                    onClick={() => handleAddFavourite(organization.id)}
                  />
                </Tooltip>
              </div>,
            ]}
            bordered={false}
            className="header-solid card-product mb-24"
          >
            <Row gutter={[24, 0]} style={{ minHeight: 'calc(100vh - 200px)' }}>
              <Col span={24} lg={12} xl={10} className="col-gallery mb-24">
                <img
                  src={sample}
                  alt="card-2"
                  className="t-h-36 md:t-h-96 t-w-full t-rounded-lg shadow-3 t-object-cover"
                />
              </Col>
              <Col span={24} xl={12} lg={{ offset: 1 }} className="t-p-4">
                <div className="t-mb-4">
                  <Title level={1} className="t-text-primary-100 t-capitalize">
                    {organization?.Name}
                  </Title>
                  <Paragraph className="t-text-justify t-font-normal">
                    {organization?.Description}
                  </Paragraph>
                  <Descriptions colon={false} className="t-my-4">
                    <Descriptions.Item
                      label={
                        <VscTypeHierarchySub
                          fontSize="1.4rem"
                          className="t-text-primary-100"
                        />
                      }
                      span={3}
                    >
                      {organization?.OrganizationType}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={
                        <AiFillPhone
                          fontSize="1.4rem"
                          className="t-text-primary-100"
                        />
                      }
                      span={3}
                    >
                      {formatPhoneNumber(organization?.PhoneNumber)}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={
                        <CgWebsite
                          fontSize="1.4rem"
                          className="t-text-primary-100"
                        />
                      }
                      span={3}
                    >
                      {organization?.Website}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={
                        <FaHome
                          fontSize="1.4rem"
                          className="t-text-primary-100"
                        />
                      }
                      span={3}
                    >
                      {`${organization?.Address1}, ${organization?.Address2}`}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={
                        <FaCity
                          fontSize="1.4rem"
                          className="t-text-primary-100"
                        />
                      }
                      span={3}
                    >
                      {organization?.City}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={
                        <FaCity
                          fontSize="1.4rem"
                          className="t-text-primary-100"
                        />
                      }
                      span={3}
                    >
                      {organization?.State}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={
                        <MdLocationOn
                          fontSize="1.4rem"
                          className="t-text-primary-100"
                        />
                      }
                      span={3}
                    >
                      {organization?.ZipCode}
                    </Descriptions.Item>
                  </Descriptions>
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export { OrganizationView }
