import { createSlice } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'

const initialState = {
  loading: false,
  volunteer: null,
  volunteers: [],
  inactive: [],
  registeredVolunteers: [],
  feedbackByVolunteers: [],
}

export const volunteerSlice = createSlice({
  name: 'volunteer',
  initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      const alteredState = { ...state, loading: payload }
      return alteredState
    },
    setVolunteer: (state, { payload }) => {
      const alteredState = { ...state, volunteer: payload }
      return alteredState
    },
    setVolunteers: (state, { payload }) => {
      const filteredVolunteers = payload.filter((item) => item.ActiveInd === 1)
      const inactiveVolunteers = payload.filter((item) => item.ActiveInd === 0)
      const alteredState = {
        ...state,
        volunteers: filteredVolunteers,
        inactive: inactiveVolunteers,
      }
      return alteredState
    },
    setRegisteredVolunteers: (state, { payload }) => {
      const alteredState = { ...state, registeredVolunteers: payload }
      return alteredState
    },
    setFeedbackByVolunteers: (state, { payload }) => {
      const alteredState = { ...state, feedbackByVolunteers: payload }
      return alteredState
    },
  },
})

export const useVolunteerSelector = () =>
  useSelector((state) => state.volunteer)
export const volunteerActions = volunteerSlice.actions
export const volunteerReducer = volunteerSlice.reducer
