import React, { useState } from 'react'
import { Modal, Button } from 'antd'
import { MdPreview } from 'react-icons/md'
import { formatAmount } from 'utils'

const data = [
  { name: 'Credit Card - Visa', amount: '2500' },
  { name: 'Credit Card - Mastercard', amount: '15000' },
  { name: 'ACH Debit', amount: '24500' },
  { name: 'Cash', amount: '4000' },
  { name: 'Bank Debit', amount: '15400' },
]
function LastYearPaymentMethodsReport() {
  const [visible, setVisible] = useState(false)
  return (
    <>
      <div>
        <MdPreview
          className="t-text-primary-100"
          fontSize="2rem"
          onClick={() => setVisible(true)}
        />
      </div>
      <Modal
        centered
        keyboard
        title="Last Year Payment Methods Report"
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={[
          <Button key="back" onClick={() => setVisible(false)}>
            Cancel
          </Button>,
          <Button key="submit" type="primary">
            Share
          </Button>,
          <Button key="link" type="primary">
            Email
          </Button>,
        ]}
      >
        <div className="t-w-full t-h-96">
          {data.map((item, index) => (
            <div
              key={index}
              className="t-w-full t-rounded-2xl t-h-12 t-p-3 t-bg-yellow-100 t-text-primary-100 t-mb-2 t-flex t-items-center t-shadow-md t-justify-between"
            >
              <div className="t-flex t-space-x-2 t-items-center">
                {item?.name}
              </div>
              <div className="t-flex t-flex-col">
                <h2 className="t-font-bold t-text-primary-100 t-text-right t-text-xs md:t-text-sm">
                  {`${formatAmount(item?.amount)}`}
                </h2>
              </div>
            </div>
          ))}
        </div>
      </Modal>
    </>
  )
}

export { LastYearPaymentMethodsReport }
