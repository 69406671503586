import axios from 'axios'
import { Auth } from 'aws-amplify'
import { store } from 'store'
import { awsconfig } from 'configs/aws-exports'
import { authActions } from 'store/authSlice/authReducer'

const API = awsconfig.API.endpoints[0]

export const axiosInstance = axios.create({
  baseURL: API.endpoint,
})

const ResponseInterceptor = (response) => {
  store.dispatch(authActions.setApiLoading(false))
  return response.data
}

const RequestInterceptor = async (config) => {
  const apiConfig = config
  store.dispatch(authActions.setApiLoading(true))
  const { auth } = store.getState()
  if (auth.user) {
    const token = (await Auth.currentSession()).getAccessToken().getJwtToken()
    const idtoken = (await Auth.currentSession()).getIdToken().getJwtToken()
    apiConfig.headers.Authorization = `Bearer ${token}`
    apiConfig.headers.idtoken = idtoken
  }
  return apiConfig
}

axiosInstance.interceptors.request.use(RequestInterceptor)

axiosInstance.interceptors.response.use(ResponseInterceptor, (error) =>
  Promise.reject(error),
)

export default axiosInstance
