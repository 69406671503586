import React, { useEffect, useState } from 'react'
import { message, Divider, Skeleton, Form, Row, Col, Input, Button } from 'antd'
import Api from 'api'

function UpdateMarkup({ id, name, onCancel }) {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState()
  const [markup, setMarkup] = useState()

  useEffect(() => {
    setLoading(true)
    Api.get(`admin/subsrciberMarkUpsByOrg/${id}`)
      .then((res) => {
        const data = res.results
        setMarkup(data.Id)
        form.setFieldsValue({
          SMSMarkup: data.SMSMarkup && data.SMSMarkup,
          EmailMarkup: data.EmailMarkup && data.EmailMarkup,
          StripeMarkup: data.StripeMarkup && data.StripeMarkup,
        })
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
        message.error('Something went wrong')
      })
    // eslint-disable-next-line
  }, [id, name])

  const handleSubmit = (payload) => {
    Api.put('/admin/updateSubsrciberMarkUps', payload)
      .then(() => {
        onCancel()
        message.success('Markup Updated Successfully')
      })
      .catch(() => {
        message.error('Something went wrong')
      })
  }

  const onFinish = (values) => {
    const payload = {
      smsMarkup: values.SMSMarkup,
      emailMarkup: values.EmailMarkup,
      stripeMarkup: values.StripeMarkup,
      OrganizationId: id,
    }
    if (markup) payload.Id = markup
    handleSubmit(payload)
  }

  return (
    <Skeleton loading={loading} paragraph={{ rows: 12 }}>
      <div className="t-mt-8">
        <div className="t-text-center">
          <p className="t-text-lg t-font-medium">
            Update Organization Markups For {name}
          </p>
        </div>
        <Divider />
        <Form
          form={form}
          layout="vertical"
          key={0}
          onFinish={onFinish}
          name="organization"
          initialValues={{ SMSMarkup: '', EmailMarkup: '', StripeMarkup: '' }}
          scrollToFirstError
        >
          <Row gutter={[24, 0]}>
            <Col xl={24} md={24} xs={24} sm={24}>
              <Form.Item
                name="SMSMarkup"
                label="SMS Markup"
                colon={false}
                // rules={[programValidation]}
              >
                <Input placeholder="Ente SMS Markup" />
              </Form.Item>
            </Col>
            <Col xl={24} md={24} xs={24} sm={24}>
              <Form.Item
                name="EmailMarkup"
                label="Email Markup"
                colon={false}
                // rules={[programValidation]}
              >
                <Input placeholder="Enter Email Markup" />
              </Form.Item>
            </Col>

            <Col xl={24} md={24} xs={24} sm={24}>
              <Form.Item
                name="StripeMarkup"
                label="Stripe Markup"
                colon={false}
                // rules={[programValidation]}
              >
                <Input placeholder="Enter Stripe Markup" />
              </Form.Item>
            </Col>

            <Col span={24} className="text-left">
              <Button
                loading={loading}
                type="primary"
                className="px-25"
                htmlType="submit"
              >
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </Skeleton>
  )
}

export { UpdateMarkup }
