import React from 'react'
import { Card, Descriptions, Popconfirm, Tooltip } from 'antd'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { AiFillCheckCircle, AiFillCloseCircle } from 'react-icons/ai'
import { config } from 'configs'
import { useDispatch } from 'react-redux'
import { sendEmail } from 'store/authSlice/authActions'

function MyAccount() {
  const { user } = useAuthSelector()
  const dispatch = useDispatch()

  const onConfirm = () => {
    dispatch(sendEmail(user?.email))
  }

  return (
    <Card
      title={[
        <>
          <h4 className="mb-0">My Account</h4>
          <p className="font-regular">Account Information Detail</p>
        </>,
      ]}
      className="t-mb-5"
    >
      <div style={{ minHeight: 'calc(100vh - 320px)' }}>
        <Descriptions
          size="middle"
          labelStyle={{
            fontSize: '1rem',
            fontWeight: 'bold',
            color: config.colors.primary,
          }}
          contentStyle={{
            fontSize: '1rem',
            fontWeight: 'normal',
            color: 'black',
          }}
        >
          <Descriptions.Item label="ID" span={3}>
            {user?.id}
          </Descriptions.Item>
          <Descriptions.Item label="First Name" span={3}>
            {user?.firstname}
          </Descriptions.Item>
          <Descriptions.Item label="Last Name" span={3}>
            {user?.lastname}
          </Descriptions.Item>
          <Descriptions.Item label="Phone Number" span={3}>
            {user?.phone}
          </Descriptions.Item>
          <Descriptions.Item label="Email" span={3}>
            {user?.email}
          </Descriptions.Item>
          <Descriptions.Item label="Email Verified" span={3}>
            {user?.email_verified ? (
              <AiFillCheckCircle
                fontSize="1.5rem"
                className="t-text-green-600"
              />
            ) : (
              <Popconfirm
                title="You want to verify your email？"
                okText="Yes"
                cancelText="No"
                onConfirm={() => onConfirm()}
              >
                <Tooltip title="Click to Verify">
                  <AiFillCloseCircle
                    fontSize="1.5rem"
                    className="t-text-red-600 t-cursor-pointer"
                  />
                </Tooltip>
              </Popconfirm>
            )}
          </Descriptions.Item>
          <Descriptions.Item label="Phone Number Verified" span={3}>
            {user?.phone_number_verified ? (
              <AiFillCheckCircle
                fontSize="1.5rem"
                className="t-text-green-600"
              />
            ) : (
              <AiFillCloseCircle fontSize="1.5rem" className="t-text-red-600" />
            )}
          </Descriptions.Item>
        </Descriptions>
      </div>
    </Card>
  )
}

export { MyAccount }
