import React, { useEffect, useState } from 'react'
import {
  message,
  Skeleton,
  Card,
  Row,
  Col,
  Typography,
  Divider,
  Tabs,
} from 'antd'
import { useParams, useHistory } from 'react-router-dom'
import { eventActions, useEventSelector } from 'store/eventSlice/eventReducer'
import { useDispatch } from 'react-redux'
import {
  getEvent,
  getEventControlScreenDetails,
  getEventDetail,
  startEventApi,
  stopEventApi,
} from 'store/eventSlice/eventActions'
import { AiFillPlayCircle } from 'react-icons/ai'
import { FaStopCircle } from 'react-icons/fa'
import { LoadingOutlined } from '@ant-design/icons'
import { config } from 'configs'
import ReactPlayer from 'react-player'
import QRCode from 'qrcode.react'
import { formatAmount } from 'utils'
import { CheckEntry, Control, Donations, useDonations } from './components'

const { Title, Paragraph } = Typography

function EventControl() {
  const [isLoading, setLoading] = useState(false)
  const { id, type } = useParams()
  const { loading, event, eventDetail } = useEventSelector()
  const history = useHistory()
  const dispatch = useDispatch()
  useDonations(id)

  useEffect(() => {
    if (id && type) {
      dispatch(getEventDetail(id))
      dispatch(getEventControlScreenDetails(id))
    } else {
      message.error('Invalid URL')
      history.push('/events')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const stopEvent = async () => {
    try {
      setLoading(true)
      await stopEventApi(event?.Id)
      const eventDetaila = await getEvent(event?.Id)
      dispatch(eventActions.setEvent(eventDetaila))
      message.success('Event Stop Successfully')
      setLoading(false)
    } catch (error) {
      message.error('Something went wrong')
      setLoading(false)
    }
  }
  const startEvent = async () => {
    if (!event.CurrentAsk) {
      return message.error('Please update the current ask amount')
    }
    try {
      setLoading(true)
      // start Event
      await startEventApi(event?.Id)
      const eventDetailb = await getEvent(event?.Id)
      dispatch(eventActions.setEvent(eventDetailb))
      message.success('Event Started Successfully')
      setLoading(false)
      return null
    } catch (error) {
      message.error('Something went wrong')
      setLoading(false)
      return null
    }
  }

  const isPrivate = type === 'false'

  return (
    <Card className="t-pb-10">
      <Skeleton loading={loading} active paragraph={{ rows: 12 }}>
        <Row>
          <Col xl={18} lg={16} md={12} sm={24} xs={24} className="t-pr-2">
            <Row
              justify="space-between"
              className="t-flex t-items-center t-mb-10"
            >
              <div className="t-flex t-space-x-4 t-items-center">
                <h1 className="t-capitalize t-text-3xl t-font-bold">
                  {event?.Title}
                </h1>
                {event?.startEvent && (
                  <div className="t-space-x-2 t-flex t-items-center t-border-2 t-px-2 t-py-1 t-border-green-400 t-rounded-full">
                    <div className="t-h-3 t-w-3 t-bg-green-400 t-rounded-full" />
                    <h1 className="t-text-primary-100">Live</h1>
                  </div>
                )}
              </div>

              {isLoading ? (
                <LoadingOutlined
                  style={{ fontSize: '2rem' }}
                  className="t-text-green-500"
                />
              ) : (
                <div>
                  {event?.startEvent ? (
                    <div className="t-flex t-items-center t-space-x-4">
                      <h6>Stop Event</h6>
                      <FaStopCircle
                        fontSize="2.5rem"
                        className="t-text-red-400 t-cursor-pointer"
                        onClick={stopEvent}
                      />
                    </div>
                  ) : (
                    <div className="t-flex t-items-center t-space-x-4">
                      <h6>Start Event</h6>
                      <AiFillPlayCircle
                        fontSize="2.5rem"
                        className="t-text-primary-100 t-cursor-pointer"
                        onClick={startEvent}
                      />
                    </div>
                  )}
                </div>
              )}
            </Row>

            <Row span={24} lg={24} xl={24} className="col-gallery mb-24">
              <ReactPlayer
                playing={false}
                width="100%"
                url={event?.YoutubeLink}
                className="t-h-40 md:t-h-96"
              />
            </Row>

            <Row>
              <Title level={4}>Description</Title>
            </Row>
            <Row>
              <Paragraph className="t-capitalize">{event?.Details}</Paragraph>
            </Row>
            <Row>
              <Title level={4}>Organization</Title>
            </Row>
            <Row>
              <Paragraph className="t-capitalize">
                {event?.OrganizationName}
              </Paragraph>
            </Row>
            <Col>
              <Row>
                <Title level={4}>Current Ask</Title>
              </Row>
              <Row>{event?.CurrentAsk ? `$ ${event?.CurrentAsk}` : '$ 0'}</Row>
              <Row>
                <Title level={4}>Goal</Title>
              </Row>
              <Row>{formatAmount(event?.TargetAmount)}</Row>
            </Col>
            {isPrivate && (
              <>
                <Divider />
                <Row className="t-w-full" animated={false}>
                  <Tabs className="t-w-full sliding-tab" defaultActiveKey="1">
                    <Tabs.TabPane tab="Control" key="1">
                      <Control />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Donations" key="2">
                      <Donations />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Check Entry" key="3">
                      <CheckEntry />
                    </Tabs.TabPane>
                  </Tabs>
                </Row>
              </>
            )}
          </Col>
          <Col
            xl={6}
            lg={8}
            md={12}
            sm={24}
            xs={24}
            className="t-bg-secondary-100 t-p-4 t-rounded-lg"
          >
            <Row className="t-flex t-justify-center t-items-center">
              <div className="t-bg-gray-500 t-border-8 t-border-gray-800 t-rounded-full t-h-52 t-w-52 t-flex t-justify-center t-items-center t-flex-col">
                <h6 className="t-font-semibold t-text-md t-text-white">
                  Raised Total
                </h6>
                <h6 className="t-font-bold t-text-3xl t-text-green-500">
                  $ {eventDetail?.status?.Total}
                </h6>
                <h6 className="t-font-semibold t-text-md t-text-white">
                  Remaining
                  <span className="t-text-yellow-500 t-font-bold">
                    {`${
                      event?.TargetAmount - eventDetail?.status?.Total < 0
                        ? formatAmount(0)
                        : formatAmount(
                            event?.TargetAmount - eventDetail?.status?.Total,
                          )
                    }`}
                  </span>
                </h6>
              </div>
            </Row>
            <Divider />
            <Col lassName="t-my-4">
              <Row className="t-w-full t-flex t-justify-between t-items-center t-my-2">
                <div className="t-flex t-items-center t-space-x-2">
                  <div className="t-h-3 t-w-3 t-bg-green-600 t-rounded-full" />
                  <h6 className="t-font-semibold">Donations</h6>
                </div>
                <h6>{eventDetail?.status?.Donations}</h6>
              </Row>
              <Row className="t-w-full t-flex t-justify-between t-items-center t-my-2">
                <div className="t-flex t-items-center t-space-x-2">
                  <div className="t-h-3 t-w-3 t-bg-green-600 t-rounded-full" />
                  <h6 className="t-font-semibold">Pledges</h6>
                </div>
                <h6>{`$ ${eventDetail?.status?.Pledges}`}</h6>
              </Row>
              <Row className="t-w-full t-flex t-justify-between t-items-center t-my-2">
                <div className="t-flex t-items-center t-space-x-2">
                  <div className="t-h-3 t-w-3 t-bg-green-600 t-rounded-full" />
                  <h6 className="t-font-semibold">Credit</h6>
                </div>
                <h6>{`$ ${eventDetail?.status?.Credit}`}</h6>
              </Row>
              <Row className="t-w-full t-flex t-justify-between t-items-center t-my-2">
                <div className="t-flex t-items-center t-space-x-2">
                  <div className="t-h-3 t-w-3 t-bg-green-600 t-rounded-full" />
                  <h6 className="t-font-semibold">Cash</h6>
                </div>
                <h6>{`$ ${eventDetail?.status?.Cash}`}</h6>
              </Row>
              <Row className="t-w-full t-flex t-justify-between t-items-center t-my-2">
                <div className="t-flex t-items-center t-space-x-2">
                  <div className="t-h-3 t-w-3 t-bg-green-600 t-rounded-full" />
                  <h6 className="t-font-semibold">Checks</h6>
                </div>
                <h6>{`$ ${eventDetail?.status?.Checks}`}</h6>
              </Row>
              <Row className="t-w-full t-flex t-justify-between t-items-center t-my-2 ">
                <div className="t-flex t-items-center t-space-x-2">
                  <div className="t-h-3 t-w-3 t-bg-red-600 t-rounded-full" />
                  <h6 className="t-text-red-600 t-font-semibold">Declined</h6>
                </div>
                <h6 className="t-text-red-600">
                  {`$ ${eventDetail?.status?.Rejected}`}
                </h6>
              </Row>
            </Col>
            <Divider />
            <Row className="t-flex t-justify-center t-items-center">
              <QRCode
                size={200}
                bgColor={config.colors.secondary}
                fgColor={config.colors.primary}
                value={`https://www.new-ui.dev.pledgegarden.com/events/${id}/join-event`}
              />
            </Row>
            <Row className="t-flex t-justify-center t-items-center t-font-bold text-2xl t-my-2">
              Scan To Donate
            </Row>
          </Col>
        </Row>
      </Skeleton>
    </Card>
  )
}

export { EventControl }
