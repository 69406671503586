import { createSlice } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'

const initialState = {
  loading: false,
  service: null,
  services: [],
  inactive: [],
}

export const serviceSlice = createSlice({
  name: 'service',
  initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      const alteredState = { ...state, loading: payload }
      return alteredState
    },
    setService: (state, { payload }) => {
      const alteredState = { ...state, service: payload }
      return alteredState
    },
    setServices: (state, { payload }) => {
      const activeServices = payload.filter(
        (item) => item.ActiveInd === 1 && item.ServiceType === 'service',
      )
      const inactiveServices = payload.filter(
        (item) => item.ActiveInd === 0 && item.ServiceType === 'service',
      )
      const alteredState = {
        ...state,
        services: activeServices,
        inactive: inactiveServices,
      }
      return alteredState
    },
  },
})

export const useServiceSelector = () => useSelector((state) => state.service)
export const serviceActions = serviceSlice.actions
export const serviceReducer = serviceSlice.reducer
