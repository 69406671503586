/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { Card, Skeleton, Pagination } from 'antd'
import { CarryOutFilled } from '@ant-design/icons'
import Api from 'api'
import { paginate } from 'utils'
import { config } from 'configs'

function Top50DonorsReport() {
  const [loading, setLoading] = useState()
  const [report, setReport] = useState()
  const [page, setPage] = useState(1)

  useEffect(() => {
    setLoading(true)
    Api.get('adminReports/topDonorList')
      .then((res) => {
        setReport(res.results)
        setLoading(false)
      })
      .catch((err) => {
        console.log('Error', err)
        setLoading(false)
      })
    // eslint-disable-next-line
  }, [])

  return (
    <Card
      id="Top50DonorsReport"
      className="mb-24 header-solid list-settings-sessions"
      bordered={false}
      title={[
        <React.Fragment key={0}>
          <h5 className="font-semibold">Top 50 Donors Report</h5>
        </React.Fragment>,
      ]}
    >
      <Skeleton loading={loading} paragraph={{ rows: 12 }}>
        {report && report.length > 0 ? (
          <>
            {paginate(report, page).map((item, index) => (
              <>
                {index !== 0 && <hr className="gradient-line my-10" />}
                <div className="ant-row-flex ant-row-flex-middle">
                  <div
                    className="text-center ant-col"
                    style={{ minWidth: '40px' }}
                  >
                    <CarryOutFilled
                      className="text-gray-6"
                      style={{ fontSize: '18px' }}
                    />
                  </div>
                  <div className="ant-col pl-15">
                    <p className="mb-0">{item?.DonorName}</p>
                  </div>
                  <div
                    className="ml-auto ant-col ant-col-24 ant-col-md-12"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <span className="ml-5">{item?.DonorPhoneNumber}</span>
                  </div>
                </div>
              </>
            ))}
            <div className="t-flex t-justify-center t-items-center t-mt-4">
              <Pagination
                showSizeChanger={false}
                defaultPageSize={config.rowsPerPage}
                current={page}
                total={report.length}
                onChange={(current) => setPage(current)}
              />
            </div>
          </>
        ) : (
          <div>No Data Found</div>
        )}
      </Skeleton>
    </Card>
  )
}

export default Top50DonorsReport
