import { Button, Col, Form, Input, message, Row } from 'antd'
import React, { useState } from 'react'
import { useAuthSelector } from 'store/authSlice/authReducer'
import Api from 'api'
import { feedbackValidation } from 'validations'

function Feedback({ id, type, close }) {
  const { user } = useAuthSelector()
  const [loading, setLoading] = useState(false)

  const onFinish = (values) => {
    setLoading(true)
    const payload = {
      username: user.id,
      Email: user.email,
      PhoneNumber: user.phone,
      Rating: 5,
      RatingComment: values.RatingComment,
      RefId: id,
      type,
    }
    Api.put('/donor/submit-feedback', payload)
      .then((res) => {
        if (res.statusCode === 208) {
          message.error(res.error)
        } else {
          message.success('Feedback Added Successfully')
          close()
        }
        setLoading(false)
      })
      .catch((err) => {
        console.log('error', err)
        setLoading(false)
        message.error('Something went wrong')
      })
  }

  return (
    <Form
      layout="vertical"
      onFinish={onFinish}
      name="feedback"
      scrollToFirstError
    >
      <Row gutter={[24, 0]}>
        <Col span={24}>
          <Form.Item
            name="RatingComment"
            label="Comment:"
            colon={false}
            rules={[feedbackValidation]}
          >
            <Input placeholder="Enter your comment" />
          </Form.Item>
          <Form.Item>
            <Button
              style={{ width: '100%' }}
              type="primary"
              htmlType="submit"
              loading={loading}
            >
              Submit
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}

export default Feedback
