import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Row, Skeleton, Avatar, Spin } from 'antd'
import { ContactsFilled } from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import { fetchMoreData, searchRows } from 'utils'
import { config } from 'configs'
import { useHistory } from 'react-router-dom'
import {
  useVolunteerSelector,
  volunteerActions,
} from 'store/volunteerSlice/volunteerReducer'
import {
  getVolunteer,
  updateVolunteerStatus,
} from 'store/volunteerSlice/volunteerActions'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { AiFillEye } from 'react-icons/ai'
import InfiniteScroll from 'react-infinite-scroll-component'

function InactiveList({ searchText }) {
  const dispatch = useDispatch()
  const history = useHistory()

  const { user } = useAuthSelector()
  const { loading, inactive } = useVolunteerSelector()

  const filteredInactive = inactive.filter(
    (volunteer) => volunteer?.CreatedBy === user.email,
  )

  const [hasMore, setHasMore] = useState(true)
  const [current, setCurrent] = useState([])

  useEffect(() => {
    setCurrent(filteredInactive.slice(0, config.itemFirstLoad))
    // eslint-disable-next-line
  }, [filteredInactive])

  const fetchData = () => {
    dispatch(getVolunteer())
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line
  }, [])

  const handleVolunteerSelected = (volunteer) => {
    dispatch(volunteerActions.setVolunteer(volunteer))
    history.push(`volunteer/${volunteer.id}`)
  }

  const handleUpdateEvent = (volunteer) => {
    dispatch(
      updateVolunteerStatus(volunteer?.id, volunteer?.ActiveInd, fetchData),
    )
  }

  return (
    <Skeleton loading={loading} active paragraph={{ rows: 18 }}>
      <div
        className="t-flex t-flex-col t-justify-between t-h-full"
        style={{ minHeight: 'calc(100vh - 250px)' }}
      >
        <InfiniteScroll
          dataLength={searchRows(current, searchText).length}
          next={() =>
            fetchMoreData(current, setCurrent, setHasMore, filteredInactive)
          }
          hasMore={hasMore}
          loader={
            <div className="t-flex t-justify-center t-items-center">
              <Spin />
            </div>
          }
        >
          <Row gutter={[24, 24]}>
            {searchRows(current, searchText).length === 0 && (
              <div
                className="t-h-96 t-flex t-justify-center t-items-center t-w-full"
                style={{ minHeight: 'calc(100vh - 300px)' }}
              >
                <div className="t-flex t-flex-col t-items-center t-space-y-2">
                  <ContactsFilled
                    style={{ fontSize: '2rem' }}
                    className="t-text-primary-100"
                  />
                  <p>No Volunteers</p>
                </div>
              </div>
            )}
            {searchRows(current, searchText).map((item, index) => (
              <Col span={24} lg={8} className="mb-24" key={index}>
                <Card
                  bodyStyle={{ paddingTop: '14px' }}
                  bordered={false}
                  className="card-project-2 header-solid"
                  title={[
                    <Row gutter={[24, 24]} key={0}>
                      <Col span={4}>
                        <Avatar
                          src={<ContactsFilled />}
                          style={{
                            width: '25px',
                            height: '25px',
                            lineHeight: '74px',
                            borderRadius: '8px',
                            padding: '16px',
                            backgroundColor: '#103150',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        />
                      </Col>
                      <Col span={16} className="t-overflow-hidden">
                        <h6 className="font-semibold mb-10">
                          {item.Title ? item.Title : 'No Title Found'}
                        </h6>
                      </Col>
                      <Col span={4} className="ml-auto pt-10">
                        <AiFillEye
                          fontSize="1.2rem"
                          className="t-cursor-pointer"
                          onClick={() => handleUpdateEvent(item)}
                        />
                      </Col>
                    </Row>,
                  ]}
                >
                  <p className="t-truncate">
                    {item?.Description
                      ? item?.Description
                      : 'No Desription Found'}
                  </p>
                  <hr />
                  <div className="t-flex t-justify-end t-mt-2">
                    <Button
                      type="link"
                      size="small"
                      className="t-rounded-full"
                      onClick={() => handleVolunteerSelected(item)}
                    >
                      See More
                    </Button>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        </InfiniteScroll>
      </div>
    </Skeleton>
  )
}

export { InactiveList }
