import * as Yup from 'yup'

const schema = Yup.object().shape({
  Date: Yup.string().required('Date is required'),
  MarriageLicenseNumber: Yup.string().required(
    'Marriage License Number is required',
  ),
  CourtMarriageLocation: Yup.string().required(
    'Court Marriage Location is required',
  ),
  BrideName: Yup.string().required('Bride Name is required'),
  GroomName: Yup.string().required('Groom Name is required'),
  WaliWakeelName: Yup.string().required('Wali/Wakeel Name is required'),
})

const marriageValidation = {
  async validator({ field }, value) {
    await schema.validateSyncAt(field, { [field]: value })
  },
}

export { marriageValidation }
