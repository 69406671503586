import React, { useEffect, useState } from 'react'
import {
  Row,
  Switch,
  Form,
  Checkbox,
  Button,
  Col,
  Input,
  Select,
  Typography,
  message,
} from 'antd'
import { useEventSelector } from 'store/eventSlice/eventReducer'
import { loadStripe } from '@stripe/stripe-js'
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js'
import { config } from 'configs'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { donateByStripe, getUserCards } from 'store/paymentSlice/paymentActions'
import { RiErrorWarningFill } from 'react-icons/ri'
import { formatAmount } from 'utils'
import * as Yup from 'yup'

const { Title, Paragraph } = Typography

const numberValidation = new RegExp('^[0-9]+$')

const schema = Yup.object().shape({
  askedAmount: Yup.string()
    .required('Required')
    .matches(numberValidation, 'Invalid amount'),
  includeEventCosts: Yup.string().required('Required'),
  card: Yup.string().required('Required'),
  agreeToTerms: Yup.string().required('Required'),
  splitDonation: Yup.string().required('Required'),
  splitnoOfMonths: Yup.string()
    .typeError('Required')
    .required('Required')
    .matches(numberValidation, 'Invalid amount'),
  repeatMonths: Yup.string().required('Required'),
  repeatnoOfMonths: Yup.string()
    .typeError('Required')
    .required('Required')
    .matches(numberValidation, 'Invalid amount'),
})

const creditCardValidation = {
  async validator({ field }, value) {
    await schema.validateSyncAt(field, { [field]: value })
  },
}

const initialValues = {
  card: 'no-card-selected',
  askedAmount: null,
  includeEventCosts: false,
  agreeToTerms: false,
  splitDonation: false,
  splitnoOfMonths: null,
  repeatMonths: false,
  repeatnoOfMonths: null,
}

function CreditCardForm({ refreshEventData }) {
  const [loading, setLoading] = useState(false)
  const [cards, setCards] = useState([])
  const stripe = useStripe()
  const elements = useElements()
  const { event, subscriberPaymentInfo, joinedEventDetails } =
    useEventSelector()
  const { user, location } = useAuthSelector()
  const [form] = Form.useForm()

  Form.useWatch('card', form)
  Form.useWatch('askedAmount', form)
  Form.useWatch('includeEventCosts', form)
  Form.useWatch('repeatMonths', form)
  Form.useWatch('splitDonation', form)

  const isSplit = form.getFieldValue('splitDonation')
  const isRepeat = form.getFieldValue('repeatMonths')

  const getCustomerCards = async () => {
    setLoading(true)
    try {
      const userCards = await getUserCards(user)
      setCards(userCards)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  useEffect(() => {
    getCustomerCards()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateAskedAmount = () => {
    form.setFieldsValue({ askedAmount: event?.CurrentAsk })
  }

  useEffect(() => {
    updateAskedAmount()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event?.CurrentAsk])

  /* eslint-disable */
  const onFinish = async (values) => {
    const askedAmountValue = Number(values.askedAmount)
    const includeEventCostValue = values.includeEventCosts
    const twoPercentage = (2 / 100) * Number(askedAmountValue)
    const totalDonation = includeEventCostValue
      ? askedAmountValue + twoPercentage
      : askedAmountValue

    const repeatPayment = {
      split: {
        splitDonation: Boolean(values.splitDonation),
        months: values.splitDonation ? values.splitnoOfMonths || null : null,
      },
      repeat: {
        repeatMonths: Boolean(values.repeatMonths),
        months: values.repeatMonths ? values.repeatnoOfMonths || null : null,
      },
    }

    try {
      if (!values.agreeToTerms) {
        return message.error('You must agree to the terms and conditions')
      }

      // checking the allowed split months
      if (repeatPayment.split.splitDonation) {
        if (
          Number(repeatPayment.split.months) > Number(event?.splitPaymentMonths)
        ) {
          return message.error(
            `You can't split the donation for more than ${event?.splitPaymentMonths} months`,
          )
        }
      }

      // checking the allowed repeat months
      if (repeatPayment.repeat.repeatMonths) {
        if (
          Number(repeatPayment.repeat.months) >
          Number(event?.monthlyPaymentMonths)
        ) {
          return message.error(
            `You can't repeat the donation for more than ${event?.monthlyPaymentMonths} months`,
          )
        }
      }

      setLoading(true)
      // if new card is added
      if (values.card === 'no-card-selected') {
        // elements are not loaded yet
        if (elements === null) {
          return
        }
        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: 'card',
          card: elements.getElement(CardElement),
        })
        // if there is an error
        if (error) {
          message.error(error?.message || 'Invalid Card Information')
          setLoading(false)
        } else {
          const data = {
            amount: totalDonation,
            cardToken: paymentMethod.id,
            stripeAccount: subscriberPaymentInfo?.stripe?.stripeId,
            card: null,
            user,
            event,
            joinedEventDetails,
            location,
            includeEventCost: includeEventCostValue,
            commission: subscriberPaymentInfo.markup,
            isBankAccount: false,
            repeatPayment,
          }
          await donateByStripe(data)
          refreshEventData()
          message.success('Your donation is successfully submitted')
          form.resetFields()
          elements.getElement(CardElement).clear()
          getCustomerCards()
          updateAskedAmount()
          setLoading(false)
        }
      } else {
        const data = {
          amount: totalDonation,
          card: values.card,
          cardToken: null,
          stripeAccount: subscriberPaymentInfo?.stripe?.stripeId,
          user,
          event,
          joinedEventDetails,
          location,
          includeEventCost: includeEventCostValue,
          commission: subscriberPaymentInfo.markup,
          isBankAccount: false,
          repeatPayment,
        }
        await donateByStripe(data)
        refreshEventData()
        message.success('Your donation is successfully submitted')
        form.resetFields()
        updateAskedAmount()
        setLoading(false)
      }
    } catch (error) {
      console.log('error', error)
      getCustomerCards()
      message.error(error?.response?.data?.message || 'Something Went Wrong')
      setLoading(false)
    }
  }

  const isNoCard = form.getFieldValue('card') === 'no-card-selected'

  const askedAmountValue = Number(form.getFieldValue('askedAmount'))
  const includeEventCostValue = form.getFieldValue('includeEventCosts')

  const twoPercentage = (2 / 100) * Number(askedAmountValue)
  const totalDonation = includeEventCostValue
    ? askedAmountValue + twoPercentage
    : askedAmountValue

  return (
    <div>
      {subscriberPaymentInfo?.stripe?.isConnected ? (
        <Form
          form={form}
          layout="vertical"
          key={0}
          onFinish={onFinish}
          name="event"
          initialValues={initialValues}
          scrollToFirstError
        >
          <Row gutter={[24, 0]} className="t-p-1">
            <Col xl={24} md={24} xs={24} sm={24}>
              <Form.Item
                name="askedAmount"
                label="Asked Amount"
                colon={false}
                rules={[creditCardValidation]}
              >
                <Input disabled={!event?.EditAskedAmount} placeholder="50000" />
              </Form.Item>
            </Col>
            <Col xl={24} md={24} xs={24} sm={24}>
              <Form.Item
                name="card"
                label="Select Card"
                colon={false}
                rules={[creditCardValidation]}
              >
                <Select placeholder="Select Card" style={{ width: '100%' }}>
                  <Select.Option value="no-card-selected">
                    No Card Selected
                  </Select.Option>
                  {cards.map((item, index) => (
                    <Select.Option
                      value={item.cardId}
                      key={index}
                      className="t-capitalize"
                    >
                      {`${item.brand} - **** **** **** ${item.last4}`}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {isNoCard && (
              <Col xl={24} md={24} xs={24} sm={24} className="t-mb-6">
                <h1 className="t-text-sm t-my-2 t-font-semibold">
                  Enter Card Detail
                </h1>
                <CardElement
                  options={{
                    style: {
                      base: {
                        fontSize: '15px',
                        fontSmoothing: 'antialiased',
                      },
                    },
                  }}
                />
              </Col>
            )}

            {event?.splitPaymentAllowed && (
              <>
                <Col
                  xl={isSplit ? 12 : 24}
                  md={isSplit ? 12 : 24}
                  xs={24}
                  sm={24}
                >
                  <Form.Item
                    name="splitDonation"
                    label="Split Pledge"
                    colon={false}
                    rules={[creditCardValidation]}
                  >
                    <Switch disabled={isRepeat} />
                  </Form.Item>
                </Col>
                {isSplit && (
                  <>
                    <Col
                      xl={isSplit ? 12 : 24}
                      md={isSplit ? 12 : 24}
                      xs={24}
                      sm={24}
                    >
                      <Form.Item
                        name="splitnoOfMonths"
                        label="Number of Months"
                        colon={false}
                        rules={[creditCardValidation]}
                      >
                        <Input
                          placeholder="Number of Months"
                          disabled={isRepeat}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Paragraph className="t-font-bold t-text-xs t-text-yellow-600 t-text-right">
                        Event creator allowed only {event?.splitPaymentMonths}{' '}
                        months
                      </Paragraph>
                    </Col>
                  </>
                )}
              </>
            )}
            {event?.monthlyPaymentAllowed && (
              <>
                <Col
                  xl={isRepeat ? 12 : 24}
                  md={isRepeat ? 12 : 24}
                  xs={24}
                  sm={24}
                >
                  <Form.Item
                    name="repeatMonths"
                    label="Repeat Months"
                    colon={false}
                    rules={[creditCardValidation]}
                  >
                    <Switch disabled={isSplit} />
                  </Form.Item>
                </Col>
                {isRepeat && (
                  <>
                    <Col
                      xl={isRepeat ? 12 : 24}
                      md={isRepeat ? 12 : 24}
                      xs={24}
                      sm={24}
                    >
                      <Form.Item
                        name="repeatnoOfMonths"
                        label="Number of Months"
                        colon={false}
                        rules={[creditCardValidation]}
                      >
                        <Input
                          placeholder="Number of Months"
                          disabled={isSplit}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Paragraph className="t-font-bold t-text-xs t-text-yellow-600 t-text-right">
                        Event creator allowed only {event?.monthlyPaymentMonths}{' '}
                        months
                      </Paragraph>
                    </Col>
                  </>
                )}
              </>
            )}
            <Col xl={24} md={24} xs={24} sm={24}>
              <Form.Item
                name="includeEventCosts"
                label="Include Event Cost"
                colon={false}
                rules={[creditCardValidation]}
              >
                <Switch />
              </Form.Item>
            </Col>
            <Col xl={24} md={24} xs={24} sm={24}>
              <Form.Item
                name="agreeToTerms"
                colon={false}
                rules={[creditCardValidation]}
              >
                <Checkbox
                  onChange={(e) =>
                    form.setFieldsValue({
                      agreeToTerms: e.target.checked,
                    })
                  }
                >
                  <span className="font-bold text-dark hover:t-text-primary-100 t-cursor-pointer t-justify-center">
                    I hereby agree to pay and adhere to Pledge Garden Terms and
                    Conditions
                  </span>
                </Checkbox>
              </Form.Item>
            </Col>
            <Col xl={24} md={24} xs={24} sm={24}>
              <Row justify="end">
                <Title level={3}>
                  {`Total Donation = ${formatAmount(totalDonation)}`}
                </Title>
              </Row>
            </Col>
            <Col span={24} className="text-right">
              <Button
                type="primary"
                className="px-25"
                htmlType="submit"
                loading={loading}
                disabled={!stripe || !elements}
              >
                Donate Via Credit Card
              </Button>
            </Col>
          </Row>
        </Form>
      ) : (
        <div className="t-h-72 t-flex t-items-center t-justify-center t-flex-col">
          <RiErrorWarningFill
            fontSize="2.5rem"
            className="t-text-primary-100"
          />
          <Title level={5}>
            Credit card payments are not configured for this event
          </Title>
          <Paragraph>You can use other payment methods</Paragraph>
        </div>
      )}
    </div>
  )
}

const stripePromise = loadStripe(config.stripe_publish_key)

function StripeProvider({ refreshEventData }) {
  return (
    <Elements stripe={stripePromise}>
      <CreditCardForm refreshEventData={refreshEventData} />
    </Elements>
  )
}

const CreditCard = StripeProvider

export { CreditCard }
