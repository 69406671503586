import React from 'react'
import { Input, Button, Card, Form, Row, Col, Switch } from 'antd'
import { useHistory } from 'react-router-dom'
import { useEventSelector } from 'store/eventSlice/eventReducer'
import * as Yup from 'yup'

const schema = Yup.object().shape({
  isRemote: Yup.string().required('Required'),
  tableName: Yup.string().required('Name is required'),
  donateAnonymously: Yup.string().required('Required'),
})

const beforeJoinFormValidation = {
  async validator({ field }, value) {
    await schema.validateSyncAt(field, { [field]: value })
  },
}

const initialValues = {
  isRemote: false,
  tableName: '',
  donateAnonymously: false,
}

function BeforeJoinForm(props) {
  const history = useHistory()
  const { moveNext } = props
  const [form] = Form.useForm()
  const { event } = useEventSelector()

  Form.useWatch('isRemote', form)

  const onFinish = (values) => {
    moveNext(values)
  }

  const isRemote = form.getFieldValue('isRemote')

  const url = event?.EventType === 'Fundraiser' ? '/events' : '/campaigns'
  return (
    <Card
      className="header-solid"
      bordered={false}
      title={[
        <>
          <h6 className="mb-0">Join Event</h6>
          <p className="font-regular">Enter Details</p>
        </>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        key={0}
        onFinish={onFinish}
        name="organization"
        initialValues={initialValues}
        scrollToFirstError
      >
        <div style={{ minHeight: 'calc(100vh - 220px)' }}>
          <Row gutter={[24, 0]}>
            <Col xl={24} md={24} xs={24} sm={24}>
              <Form.Item
                name="isRemote"
                label="Is Remote"
                colon={false}
                rules={[beforeJoinFormValidation]}
              >
                <Switch />
              </Form.Item>
            </Col>
            {!isRemote && (
              <Col xl={24} md={24} xs={24} sm={24}>
                <Form.Item
                  name="tableName"
                  label="Table number"
                  colon={false}
                  rules={[beforeJoinFormValidation]}
                >
                  <Input placeholder="50000" />
                </Form.Item>
              </Col>
            )}
            <Col xl={24} md={24} xs={24} sm={24}>
              <Form.Item
                name="donateAnonymously"
                label="Donate Anonymously?"
                colon={false}
                rules={[beforeJoinFormValidation]}
              >
                <Switch />
              </Form.Item>
            </Col>
            <Col span={24} className="text-left t-flex t-space-x-4">
              <Button type="primary" className="px-25" htmlType="submit">
                Join Event
              </Button>
              <Button
                onClick={() => history.push(url)}
                className="px-25"
                htmlType="submit"
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </div>
      </Form>
    </Card>
  )
}

export { BeforeJoinForm }
