/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { Card, Skeleton, Pagination } from 'antd'
import { CarryOutFilled } from '@ant-design/icons'
import Api from 'api'

function FYEarningsForecastReport() {
  const [loading, setLoading] = useState()
  const [report, setReport] = useState()
  const [index, setIndex] = useState(1)

  useEffect(() => {
    setLoading(true)
    Api.get('adminReports/fyEarningForcastReport')
      .then((res) => {
        setReport(res.results)
        setLoading(false)
      })
      .catch((err) => {
        console.log('Error', err)
        setLoading(false)
      })
  }, [])

  return (
    <Card
      id="FYEarningsForecastReport"
      className="mb-24 header-solid list-settings-sessions"
      bordered={false}
      title={[
        <React.Fragment key={0}>
          <h5 className="font-semibold">Current Year Forecast</h5>
        </React.Fragment>,
      ]}
    >
      <Skeleton loading={loading} paragraph={{ rows: 12 }}>
        {report && report.length > 0 ? (
          <>
            <div>
              <div className="t-flex t-justify-between t-mb-10">
                <h5 className="font-semibold t-ml-4">
                  {report[index - 1].month}
                </h5>
                <h5 className="font-semibold">$4200</h5>
              </div>
              {[
                'event Pledges',
                'event Recurring',
                'campaigns Pledges',
                'campaigns Recurring',
              ].map((item, indexVal) => (
                <>
                  {indexVal !== 0 && <hr className="gradient-line my-10" />}
                  <div className="ant-row-flex ant-row-flex-middle">
                    <div
                      className="text-center ant-col"
                      style={{ minWidth: '40px' }}
                    >
                      <CarryOutFilled
                        className="text-gray-6"
                        style={{ fontSize: '18px' }}
                      />
                    </div>
                    <div className="ant-col pl-15">
                      <p className="mb-0 t-capitalize">{item}</p>
                    </div>
                    <div
                      className="ml-auto ant-col ant-col-24 ant-col-md-12"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <span className="ml-5">$ 7500</span>
                    </div>
                  </div>
                </>
              ))}
            </div>
            <div className="t-flex t-justify-center t-items-center t-mt-4">
              <Pagination
                showSizeChanger={false}
                defaultPageSize={1}
                current={index}
                total={6}
                onChange={(current) => setIndex(current)}
              />
            </div>
          </>
        ) : (
          <div>No Data Found</div>
        )}
      </Skeleton>
    </Card>
  )
}

export default FYEarningsForecastReport
