import React, { useEffect, useRef, useState } from 'react'
import { useEventSelector } from 'store/eventSlice/eventReducer'
import { RiErrorWarningFill } from 'react-icons/ri'
import { config } from 'configs'
import { addPaypalDonation } from 'store/paymentSlice/paymentActions'
import { useAuthSelector } from 'store/authSlice/authReducer'
import {
  Row,
  Form,
  Checkbox,
  Button,
  Col,
  Typography,
  message,
  Input,
} from 'antd'
import {
  PayPalButtons,
  usePayPalScriptReducer,
  PayPalScriptProvider,
} from '@paypal/react-paypal-js'
import * as Yup from 'yup'

const { Title, Paragraph } = Typography

const numberValidation = new RegExp('^[0-9]+$')

const schema = Yup.object().shape({
  askedAmount: Yup.string()
    .required('Required')
    .matches(numberValidation, 'Invalid amount'),
  agreeToTerms: Yup.string().required('Required'),
})

const paypalValidation = {
  async validator({ field }, value) {
    await schema.validateSyncAt(field, { [field]: value })
  },
}

const initialValues = {
  askedAmount: null,
  agreeToTerms: false,
}

function Paypal({ refreshEventData }) {
  const { event, subscriberPaymentInfo } = useEventSelector()
  const [loading, setLoading] = useState(false)

  const [form] = Form.useForm()

  Form.useWatch('agreeToTerms', form)
  Form.useWatch('askedAmount', form)

  useEffect(() => {
    form.setFieldsValue({ askedAmount: event?.CurrentAsk })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event?.CurrentAsk])

  /* eslint-disable */
  const onFinish = (values) => {
    if (!values.agreeToTerms) {
      return message.error('You must agree to the terms and conditions')
    }
  }

  const isAgreed = !form.getFieldValue('agreeToTerms')

  return (
    <div>
      {subscriberPaymentInfo?.paypal?.isConnected ? (
        <Form
          form={form}
          layout="vertical"
          key={0}
          onFinish={onFinish}
          name="event"
          initialValues={initialValues}
          scrollToFirstError
        >
          <Row gutter={[24, 0]} className="t-p-3">
            <Col xl={24} md={24} xs={24} sm={24}>
              <Form.Item
                name="askedAmount"
                label="Asked Amount"
                colon={false}
                rules={[paypalValidation]}
              >
                <Input disabled={!event?.EditAskedAmount} placeholder="50000" />
              </Form.Item>
            </Col>
            <Col xl={24} md={24} xs={24} sm={24}>
              <Form.Item
                name="agreeToTerms"
                colon={false}
                rules={[paypalValidation]}
              >
                <Checkbox
                  onChange={(e) =>
                    form.setFieldsValue({
                      agreeToTerms: e.target.checked,
                    })
                  }
                >
                  <span className="font-bold text-dark hover:t-text-primary-100 t-cursor-pointer">
                    I hereby agree to pay and adhere to Pledge Garden Terms and
                    Conditions
                  </span>
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={24} className="text-right">
              {loading ? (
                <Button
                  loading={loading}
                  type="primary"
                  className="px-25"
                  htmlType="submit"
                >
                  Donate
                </Button>
              ) : (
                <PaypalProvider
                  disabled={isAgreed}
                  amount={form.getFieldValue('askedAmount')}
                  setLoading={setLoading}
                  refreshEventData={refreshEventData}
                />
              )}
            </Col>
          </Row>
        </Form>
      ) : (
        <div className="t-h-96 t-flex t-items-center t-justify-center t-flex-col">
          <RiErrorWarningFill
            fontSize="2.5rem"
            className="t-text-primary-100"
          />
          <Title level={5}>
            Paypal payments are not configured for this event
          </Title>
          <Paragraph>You can use other payment methods</Paragraph>
        </div>
      )}
    </div>
  )
}

function PaypalProvider(props) {
  const { subscriberPaymentInfo } = useEventSelector()
  return (
    <PayPalScriptProvider
      options={{
        'client-id': config.paypal_client_id,
        'merchant-id': subscriberPaymentInfo?.paypal?.paypalId,
        components: 'buttons',
        currency: 'USD',
      }}
    >
      <PaypalSinglePaymentButton {...props} />
    </PayPalScriptProvider>
  )
}

function PaypalSinglePaymentButton({
  disabled,
  amount,
  setLoading,
  refreshEventData,
}) {
  // const [
  //   { options, isPending }
  // ] =
  usePayPalScriptReducer()
  const { event, joinedEventDetails, subscriberPaymentInfo } =
    useEventSelector()
  const { user, location } = useAuthSelector()
  const comission = (
    (subscriberPaymentInfo.markup * Number(amount)) /
    100
  ).toFixed(2)

  const donationAmount = useRef(0)

  useEffect(() => {
    donationAmount.current = amount
  }, [amount])

  return (
    <PayPalButtons
      fundingSource="paypal"
      style={{ layout: 'vertical', label: 'donate' }}
      disabled={disabled}
      createOrder={(data, actions) =>
        actions.order
          .create({
            purchase_units: [
              {
                amount: {
                  value: Number(donationAmount.current),
                  breakdown: {
                    item_total: {
                      currency_code: 'USD',
                      value: Number(donationAmount.current),
                    },
                  },
                },
                payment_instruction: {
                  disbursement_mode: 'INSTANT',
                  platform_fees: [
                    {
                      amount: {
                        currency_code: 'USD',
                        value: comission,
                      },
                    },
                  ],
                },
                items: [
                  {
                    name: event?.Title,
                    quantity: '1',
                    unit_amount: {
                      currency_code: 'USD',
                      value: Number(donationAmount.current),
                    },
                    category: 'DONATION',
                  },
                ],
              },
            ],
          })
          .then((orderId) => orderId)
      }
      onApprove={(data, actions) =>
        actions.order.capture().then(async (details) => {
          setLoading(true)
          const capturedAmount = details?.purchase_units[0]?.amount?.value
          try {
            const payload = {
              event,
              user,
              amount: capturedAmount,
              location,
              joinedEventDetails,
            }
            await addPaypalDonation(payload)
            refreshEventData()
            message.success('Your donation is successfully submitted')
            setLoading(false)
          } catch (error) {
            message.error(error?.message || 'Something went wrong')
            console.log('onApprove paypal error', error)
            setLoading(false)
          }
        })
      }
    />
  )
}

export { Paypal }
