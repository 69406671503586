import React, { useState } from 'react'
import { Row, Col, Card, Descriptions, Button, Tooltip, Modal } from 'antd'
import { useHistory } from 'react-router-dom'
import { IoIosArrowRoundBack } from 'react-icons/io'
import { MdEdit } from 'react-icons/md'
import { AiFillEye, AiFillEyeInvisible, AiOutlineHeart } from 'react-icons/ai'
import { HiDuplicate } from 'react-icons/hi'
import { DiRequirejs } from 'react-icons/di'
import { VscTypeHierarchySub } from 'react-icons/vsc'
import { FaMoneyBillWave } from 'react-icons/fa'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { useDispatch } from 'react-redux'
import { addFavourite } from 'store/favouriteSlice/favouriteActions'
import { useProgramSelector } from 'store/programSlice/programReducer'
import sample from 'assets/images/sample/sample1.png'
import {
  addProgram,
  updateProgramStatus,
} from 'store/programSlice/programActions'
import { RegisteredVolunteer } from './RegisteredVolunteer'
import { FeedbackByVolunteer } from './FeedbackByVolunteer'
// import Api from "api";
import Register from './Register'

function ProgramView() {
  const dispatch = useDispatch()
  const history = useHistory()
  const { program } = useProgramSelector()
  const { user } = useAuthSelector()
  const [isModalVisible, setIsModalVisible] = useState(false)

  const handleAddFavourite = async (id) => {
    const payload = {
      ReferenceId: id,
      Username: user?.id,
      Type: 'program',
    }
    dispatch(addFavourite(payload))
    history.push('/programs')
  }

  const handleDuplicate = () => {
    const newProgram = { ...program }
    delete newProgram?.id
    dispatch(addProgram(newProgram))
    history.push('/programs')
  }

  const handleUpdate = () => {
    dispatch(
      updateProgramStatus(
        program?.id,
        program?.ActiveInd,
        history.push('/programs'),
      ),
    )
  }

  return (
    <>
      <Row gutter={[24, 0]}>
        <Col span={24}>
          <Card
            title={[
              <div className="t-flex t-justify-between">
                <div className="t-flex t-items-center t-space-x-4">
                  <Button
                    type="primary"
                    className="t-rounded-full t-flex t-items-center"
                    onClick={() => history.push('/programs')}
                    size="small"
                  >
                    <IoIosArrowRoundBack />
                    Back
                  </Button>
                </div>
                <div className="t-flex t-items-center t-space-x-2 t-cursor-pointer">
                  <Tooltip title="Make Favourite">
                    <AiOutlineHeart
                      fontSize="1.8rem"
                      className="t-cursor-pointer"
                      onClick={() => handleAddFavourite(program.id)}
                    />
                  </Tooltip>
                  {program?.CreatedBy === user.email && (
                    <>
                      <Tooltip title="Edit Program">
                        <MdEdit
                          fontSize="1.8rem"
                          onClick={() =>
                            history.push(
                              `/settings/create-program/${program.id}`,
                            )
                          }
                        />
                      </Tooltip>
                      <Tooltip title="Duplicate Program">
                        <HiDuplicate
                          fontSize="1.8rem"
                          onClick={() => handleDuplicate()}
                        />
                      </Tooltip>
                      {program?.ActiveInd === 1 ? (
                        <Tooltip title="Make Inactive">
                          <AiFillEyeInvisible
                            fontSize="1.8rem"
                            onClick={() => handleUpdate()}
                          />
                        </Tooltip>
                      ) : (
                        <Tooltip title="Make Active">
                          <AiFillEye
                            fontSize="1.8rem"
                            onClick={() => handleUpdate()}
                          />
                        </Tooltip>
                      )}
                    </>
                  )}
                </div>
              </div>,
            ]}
            bordered={false}
            className="header-solid card-product mb-24"
          >
            <Row gutter={[24, 0]}>
              <Col span={24} lg={12} xl={10} className="col-gallery mb-24">
                <img
                  src={sample}
                  alt="card-2"
                  className="gallery-img shadow-3 img-contain"
                />
              </Col>
              <Col span={24} xl={12} lg={{ offset: 1 }}>
                <div className="t-mb-4">
                  <h3 className="h3 t-capitalize">{program?.Title}</h3>
                  <h5 className="font-semibold">{program?.Description}</h5>
                  <Descriptions colon={false} className="t-my-4">
                    <Descriptions.Item
                      label={
                        <Tooltip title="Location Type">
                          <VscTypeHierarchySub fontSize="1.5rem" />
                        </Tooltip>
                      }
                      span={3}
                    >
                      {program?.LocationType}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={
                        <Tooltip title="Cost">
                          <FaMoneyBillWave fontSize="1.5rem" />
                        </Tooltip>
                      }
                      span={3}
                    >
                      {program?.Cost}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={
                        <Tooltip title="Requirements">
                          <DiRequirejs fontSize="1.5rem" />
                        </Tooltip>
                      }
                      span={3}
                    >
                      {program?.Requirements}
                    </Descriptions.Item>
                  </Descriptions>
                  <div className="t-my-4 t-space-x-2">
                    <Button
                      type="primary"
                      onClick={() => setIsModalVisible(true)}
                    >
                      Register
                    </Button>
                  </div>
                  <Modal
                    title={`Register for Program - ${program.Title}`}
                    visible={isModalVisible}
                    onCancel={() => setIsModalVisible(false)}
                    footer={null}
                  >
                    <Register close={() => setIsModalVisible(false)} />
                  </Modal>
                </div>
              </Col>
            </Row>
            {program?.CreatedBy === user.email && (
              <Row gutter={[24, 0]} className="mt-24">
                <Col span={24}>
                  <h6 className="t-font-bold t-text-base">Registered User</h6>
                  <RegisteredVolunteer id={program?.id} />
                </Col>
                <Col span={24}>
                  <h6 className="t-font-bold t-text-base">Registered User</h6>
                  <FeedbackByVolunteer id={program?.id} />
                </Col>
              </Row>
            )}
          </Card>
        </Col>
      </Row>
    </>
  )
}

export { ProgramView }
