import React, { useState, useEffect } from 'react'
import {
  Card,
  Form,
  Input,
  Row,
  Col,
  Button,
  Select,
  Switch,
  Upload,
  message,
  Skeleton,
  DatePicker,
} from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { eventValidation } from 'validations'
import { addEvent, getEvent, updateEvent } from 'store/eventSlice/eventActions'
import { useParams, useHistory } from 'react-router-dom'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { getUserOrganization } from 'store/organizationSlice/organizationActions'
import moment from 'moment'
// import ReCAPTCHA from "react-google-recaptcha";

const { TextArea } = Input

const eventTypes = ['Fundraiser', 'Campaign']

const initialValues = {
  Title: '',
  EventType: 'Fundraiser',
  zipCode: '',
  TargetAmount: '',
  YoutubeLink: '',
  SpeakerName: '',
  SpeakerBio: '',
  monthlyPaymentAllowed: false,
  monthlyPaymentMonths: '',
  splitPaymentAllowed: false,
  splitPaymentMonths: '',
  EditAskedAmount: false,
  Details: '',
  Presenter: 'Myself',
  PresenterName: '',
  PresenterPhoneCode: '',
  PresenterMobileNumber: '',
  PresenterEmail: '',
  StartDate: null,
  EndDate: null,

  OrganizationId: '',
  refLink: `${window.location.origin}/presenter/accept`,
  lat: '',
  lon: '',
  startEvent: false,
  isSelfPresent: true,
  Flyer: null,
  Presentation: null,
}

function CreateEvent() {
  const { user, location, organization } = useAuthSelector()
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [fetching, setFetched] = useState(false)
  const [eventDetail, setEvent] = useState(null)
  const params = useParams()
  const history = useHistory()

  Form.useWatch('Presenter', form)
  Form.useWatch('monthlyPaymentAllowed', form)
  Form.useWatch('splitPaymentAllowed', form)
  Form.useWatch('Flyer', form)
  Form.useWatch('Presentation', form)
  Form.useWatch('EventType', form)
  Form.useWatch('zipCode', form)

  useEffect(() => {
    if (organization && organization?.ZipCode) {
      form.setFieldsValue({ zipCode: organization.ZipCode })
    }
    // eslint-disable-next-line
  }, [organization])

  useEffect(() => {
    const getData = async () => {
      setFetched(true)
      if (params.id) {
        const event = await getEvent(params.id)
        setEvent(event)
        if (event) {
          form.setFieldsValue({
            Title: event.Title,
            EventType: event.EventType,
            zipCode: event.zipCode,
            TargetAmount: event.TargetAmount,
            YoutubeLink: event.YoutubeLink,
            SpeakerName: event.SpeakerName,
            SpeakerBio: event.SpeakerBio,
            monthlyPaymentAllowed: event.monthlyPaymentAllowed,
            monthlyPaymentMonths: event.monthlyPaymentMonths,
            splitPaymentAllowed: event.splitPaymentAllowed,
            splitPaymentMonths: event.splitPaymentMonths,
            EditAskedAmount: event.EditAskedAmount,
            Details: event.Details,
            Presenter: event.Presenter,
            PresenterName: event.PresenterName,
            PresenterPhoneCode: event.PresenterPhoneCode,
            PresenterMobileNumber: event.PresenterMobileNumber,
            PresenterEmail: event.PresenterEmail,
            OrganizationId: event.OrganizationId,
            refLink: event.refLink,
            lat: event.lat,
            lon: event.lon,
            startEvent: event.startEvent,
            isSelfPresent: event.isSelfPresent,
            Flyer: event.Flyer,
            Presentation: event.Presentation,
            StartDate: moment(event.StartDate),
            EndDate: event.EndDate ? moment(event.EndDate) : null,
          })
        } else {
          message.error('Invalid Event Id')
          history.push('/settings/create-event/')
        }
      }
      setFetched(false)
    }
    getData()
  }, [params.id, history, form])

  const onFinish = async (values) => {
    setLoading(true)
    try {
      const userOrganization = await getUserOrganization(user.email)
      if (params.id) {
        const data = {
          ...eventDetail,
          ...values,
          EndDate: values.EndDate ? values.EndDate : null,
        }
        await updateEvent(data, user)
        message.success('Event Updated Successfully')
        setLoading(false)
        if (data.EventType === 'Fundraiser') {
          history.push('/events')
        } else {
          history.push('/campaigns')
        }
      } else {
        const data = {
          ...values,
          lat: location.lat,
          lon: location.lng,
          // zipCode: location.zipcode,
          OrganizationId: userOrganization.id,
          OrganizationName: userOrganization.Name,
          EndDate: values.EndDate ? values.EndDate : null,
        }
        await addEvent(data, user)
        message.success('Event Added Successfully')
        setLoading(false)
        form.resetFields()
        if (data.EventType === 'Fundraiser') {
          history.push('/events')
        } else {
          history.push('/campaigns')
        }
      }
    } catch (error) {
      setLoading(false)
      message.error('Something Went Wrong')
    }
  }

  const ismonthlyPaymentAllowed = form.getFieldValue('monthlyPaymentAllowed')
  const issplitPaymentAllowed = form.getFieldValue('splitPaymentAllowed')
  const isNewPresenter = form.getFieldValue('Presenter') === 'NewPresenter'
  const isCampaign = form.getFieldValue('EventType') === 'Campaign'

  const buttonText = loading
    ? params.id
      ? 'Updating Event'
      : 'Creating Event'
    : params.id
    ? 'Update Event'
    : 'Create Event'

  const onCancel = () => {
    if (form.getFieldValue('EventType') === 'Fundraiser') {
      history.push('/events')
    } else {
      history.push('/campaigns')
    }
  }

  return (
    <Card
      className="header-solid"
      bordered={false}
      title={[
        <>
          <h6 className="mb-0 t-text-2xl">
            {params.id ? 'Update Event' : 'Add Event'}
          </h6>
          <p className="font-regular">
            {params.id ? params.id : 'Enter Information'}
          </p>
        </>,
      ]}
    >
      <Skeleton loading={fetching} active>
        <Form
          form={form}
          layout="vertical"
          key={0}
          onFinish={onFinish}
          name="event"
          initialValues={initialValues}
          scrollToFirstError
        >
          <Row gutter={[24, 0]}>
            <Col xl={12} md={12} xs={24} sm={24}>
              <Form.Item
                name="Title"
                label="Title"
                colon={false}
                rules={[eventValidation]}
              >
                <Input placeholder="Event name" />
              </Form.Item>
            </Col>
            <Col xl={12} md={12} xs={24} sm={24}>
              <Form.Item
                name="EventType"
                label="Event Type"
                colon={false}
                rules={[eventValidation]}
              >
                <Select placeholder="Event Type" style={{ width: '100%' }}>
                  {eventTypes.map((item, index) => (
                    <Select.Option value={item} key={index}>
                      {item}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xl={12} md={12} xs={24} sm={24}>
              <Form.Item
                name="zipCode"
                label="Zip code"
                colon={false}
                rules={[eventValidation]}
              >
                <Input placeholder="e.g : 12345" />
              </Form.Item>
            </Col>
            <Col xl={12} md={12} xs={24} sm={24}>
              <Form.Item
                name="TargetAmount"
                label="Target amount"
                colon={false}
                rules={[eventValidation]}
              >
                <Input placeholder="20000" />
              </Form.Item>
            </Col>

            {isCampaign ? (
              <>
                <Col xl={12} md={12} xs={24} sm={24}>
                  <Form.Item
                    name="StartDate"
                    label="Start Date"
                    colon={false}
                    rules={[eventValidation]}
                  >
                    <DatePicker
                      showTime={{ format: 'HH:mm' }}
                      className="t-w-full"
                    />
                  </Form.Item>
                </Col>
                <Col xl={12} md={12} xs={24} sm={24}>
                  <Form.Item
                    name="EndDate"
                    label="End Date"
                    colon={false}
                    rules={[eventValidation]}
                  >
                    <DatePicker
                      showTime={{ format: 'HH:mm' }}
                      className="t-w-full"
                    />
                  </Form.Item>
                </Col>
              </>
            ) : (
              <Col xl={24} md={24} xs={24} sm={24}>
                <Form.Item
                  name="StartDate"
                  label="Start Date"
                  colon={false}
                  rules={[eventValidation]}
                >
                  <DatePicker
                    showTime={{ format: 'HH:mm' }}
                    className="t-w-full"
                  />
                </Form.Item>
              </Col>
            )}

            <Col span={24}>
              <Form.Item
                name="YoutubeLink"
                label="Youtube Link"
                colon={false}
                rules={[eventValidation]}
              >
                <Input placeholder="Youtube Link" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="SpeakerName"
                label="Speaker name"
                colon={false}
                rules={[eventValidation]}
              >
                <Input placeholder="Speaker name" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="SpeakerBio"
                label="Speaker bio"
                colon={false}
                rules={[eventValidation]}
              >
                <TextArea
                  placeholder="Speaker bio"
                  autoSize={{ minRows: 3, maxRows: 5 }}
                />
              </Form.Item>
            </Col>

            <Col xl={12} md={12} xs={24} sm={24}>
              <Form.Item
                name="monthlyPaymentAllowed"
                label="Is Monthly Payment Allowed ?"
                colon={false}
                rules={[eventValidation]}
              >
                <Select
                  placeholder="Monthly Payment Allowed"
                  style={{ width: '100%' }}
                >
                  <Select.Option value>Yes</Select.Option>
                  <Select.Option value={false}>No</Select.Option>
                </Select>
              </Form.Item>
            </Col>

            {ismonthlyPaymentAllowed ? (
              <Col xl={12} md={12} xs={24} sm={24}>
                <Form.Item
                  name="monthlyPaymentMonths"
                  label="Number of Months"
                  colon={false}
                  rules={[eventValidation]}
                >
                  <Input placeholder="Number of Months" />
                </Form.Item>
              </Col>
            ) : (
              <Col xl={12} md={12} xs={24} sm={24} />
            )}
            {!ismonthlyPaymentAllowed && (
              <Col xl={12} md={12} xs={24} sm={24}>
                <Form.Item
                  name="splitPaymentAllowed"
                  label="Is Split Payment Allowed ?"
                  colon={false}
                  rules={[eventValidation]}
                >
                  <Select
                    placeholder="Split Payment Allowed"
                    style={{ width: '100%' }}
                  >
                    <Select.Option value>Yes</Select.Option>
                    <Select.Option value={false}>No</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            )}

            {!ismonthlyPaymentAllowed && issplitPaymentAllowed ? (
              <Col xl={12} md={12} xs={24} sm={24}>
                <Form.Item
                  name="splitPaymentMonths"
                  label="Number of Months"
                  colon={false}
                  rules={[eventValidation]}
                >
                  <Input placeholder="Number of Months" />
                </Form.Item>
              </Col>
            ) : (
              <Col xl={12} md={12} xs={24} sm={24} />
            )}
            <Col span={24}>
              <Form.Item
                name="EditAskedAmount"
                label="Allow User To Edit Amount"
                colon={false}
                rules={[eventValidation]}
              >
                <Switch />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="Details"
                label="Brief Event Description"
                colon={false}
                rules={[eventValidation]}
              >
                <TextArea
                  placeholder="Tell us about the event"
                  autoSize={{ minRows: 3, maxRows: 5 }}
                />
              </Form.Item>
            </Col>
            {!isCampaign && (
              <>
                <Col span={24}>
                  <Form.Item
                    name="Presenter"
                    label="Who Will Present ?"
                    colon={false}
                    rules={[eventValidation]}
                  >
                    <Select placeholder="Presenter" style={{ width: '100%' }}>
                      <Select.Option value="NewPresenter">
                        New Presenter
                      </Select.Option>
                      <Select.Option value="Myself">Myself</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>

                {isNewPresenter && (
                  <>
                    <Col xl={12} md={12} xs={24} sm={24}>
                      <Form.Item
                        name="PresenterPhoneCode"
                        label="Select Country"
                        colon={false}
                        rules={[eventValidation]}
                      >
                        <Select
                          placeholder="Select Country Code"
                          style={{ width: '100%' }}
                        >
                          <Select.Option value="+1">
                            United States/Canada (+1)
                          </Select.Option>
                          <Select.Option value="+54">
                            Argentina (+54)
                          </Select.Option>
                          <Select.Option value="+92">
                            Pakistan (+92)
                          </Select.Option>
                          <Select.Option value="+58">
                            Venezuela, Bolivarian Republic of Venezuela (+58)
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xl={12} md={12} xs={24} sm={24}>
                      <Form.Item
                        name="PresenterMobileNumber"
                        label="Presenter Mobile Number"
                        colon={false}
                        rules={[eventValidation]}
                      >
                        <Input placeholder="92123456789" />
                      </Form.Item>
                    </Col>
                    <Col xl={12} md={12} xs={24} sm={24}>
                      <Form.Item
                        name="PresenterName"
                        label="Presenter Name"
                        colon={false}
                        rules={[eventValidation]}
                      >
                        <Input placeholder="John Micheal" />
                      </Form.Item>
                    </Col>
                    <Col xl={12} md={12} xs={24} sm={24}>
                      <Form.Item
                        name="PresenterEmail"
                        label="Presenter Email"
                        colon={false}
                        rules={[eventValidation]}
                      >
                        <Input placeholder="john.micheal@gmail.com" />
                      </Form.Item>
                    </Col>
                  </>
                )}
              </>
            )}

            <Col xl={12} md={12} xs={24} sm={24}>
              <Form.Item
                name="Flyer"
                label="Flyer Image (Event Banner)"
                colon={false}
                rules={[eventValidation]}
              >
                <Upload
                  customRequest={(e) => form.setFieldsValue({ Flyer: e.file })}
                  maxCount={1}
                  showUploadList={false}
                  accept="image/*"
                >
                  <Button
                    icon={<UploadOutlined />}
                    style={{ marginRight: '1rem' }}
                  >
                    {form.getFieldValue('Flyer') ? 'Change' : 'Click to Upload'}
                  </Button>
                  {form.getFieldValue('Flyer') &&
                    form.getFieldValue('Flyer').name}
                </Upload>
              </Form.Item>
            </Col>
            <Col xl={12} md={12} xs={24} sm={24}>
              <Form.Item
                name="Presentation"
                label="Presentation"
                colon={false}
                rules={[eventValidation]}
              >
                <Upload
                  customRequest={(e) =>
                    form.setFieldsValue({ Presentation: e.file })
                  }
                  maxCount={1}
                  showUploadList={false}
                >
                  <Button
                    icon={<UploadOutlined />}
                    style={{ marginRight: '1rem' }}
                  >
                    {form.getFieldValue('Presentation')
                      ? 'Change'
                      : 'Click to Upload'}
                  </Button>
                  {form.getFieldValue('Presentation') &&
                    form.getFieldValue('Presentation').name}
                </Upload>
              </Form.Item>
            </Col>
            {/* <Col span={24}>
              <Form.Item name="recaptcha" rules={[eventValidation]}>
                <ReCAPTCHA sitekey="6LdSWKgZAAAAAKrrp6GFXldEv0I4vqO3yWrTkuNV" />
              </Form.Item>
            </Col> */}
            <Col span={24} className="text-left t-space-x-2">
              <Button
                loading={loading}
                type="primary"
                className="px-25"
                htmlType="submit"
              >
                {buttonText}
              </Button>
              {params.id && (
                <Button type="primary" className="px-25" onClick={onCancel}>
                  Cancel
                </Button>
              )}
            </Col>
          </Row>
        </Form>
      </Skeleton>
    </Card>
  )
}

export { CreateEvent }
