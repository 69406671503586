import React, { useState } from 'react'
import { Modal, Button, Typography, Skeleton, Pagination } from 'antd'
import { MdPreview } from 'react-icons/md'
import { config } from 'configs'
import { formatAmount, formateDateTime, paginate } from 'utils'
import { useAuthSelector } from 'store/authSlice/authReducer'

const { Title } = Typography

function YTDDonations() {
  const [visible, setVisible] = useState(false)
  const [page, setPage] = useState(1)
  const { loading, ytd } = useAuthSelector()

  const PAGINATED_DONATIONS = paginate(ytd?.indv, page)

  const generatePDFAndEmail = () => {
    // let payload = {
    //   firstName: currentUser?.given_name,
    //   lastName: currentUser?.family_name,
    //   phoneNumber: currentUser?.phone_number,
    //   email: currentUser?.email,
    //   reportName: reportName,
    //   isSendEmail: isEmail,
    //   records: records,
    // };
    // let res = await DataApi.generatePdfandEmail(payload);
    // if(res.data.result){
    //   await DataApi.convertFile(res.data.result,'Report.pdf','application/pdf')
    // }
  }

  return (
    <>
      <div>
        <MdPreview
          className="t-text-primary-100"
          fontSize="2rem"
          onClick={() => setVisible(true)}
        />
      </div>
      <Modal
        centered
        keyboard
        title="YTD Donations"
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={[
          <Button key="back" onClick={() => setVisible(false)}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => generatePDFAndEmail()}
          >
            Download
          </Button>,
          <Button
            key="link"
            type="primary"
            onClick={() => generatePDFAndEmail()}
          >
            Email
          </Button>,
        ]}
      >
        <div className="t-w-full t-h-96">
          <Title level={5}>Donations</Title>
          <Skeleton loading={loading} active paragraph={{ rows: 6 }}>
            <div className="t-h-80 t-mb-4">
              {PAGINATED_DONATIONS && PAGINATED_DONATIONS.length === 0 && (
                <div className="t-flex t-justify-center t-items-center t-h-full t-w-full">
                  No Donations. Be the first to donate.
                </div>
              )}
              {PAGINATED_DONATIONS &&
                PAGINATED_DONATIONS.map((item, index) => (
                  <div
                    key={index}
                    className="t-w-full t-rounded-2xl t-h-12 t-p-3 t-bg-yellow-100 t-text-primary-100 t-mb-2 t-flex t-items-center t-shadow-md t-justify-between"
                  >
                    <div className="t-flex t-space-x-2 t-items-center">
                      {item?.eventName}
                    </div>
                    <div className="t-flex t-flex-col">
                      <h2 className="t-font-bold t-text-primary-100 t-text-right t-text-xs md:t-text-sm">
                        {`${formatAmount(item?.amount)}`}
                      </h2>
                      <h2 className="t-text-gray-800 t-italic t-text-xs md:t-text-sm t-whitespace-nowrap t-text-right">
                        {`${formateDateTime(item?.paymentDate)}`}
                      </h2>
                    </div>
                  </div>
                ))}
            </div>
            <div className="t-flex t-justify-center t-items-center">
              <Pagination
                showSizeChanger={false}
                defaultPageSize={config.rowsPerPage}
                current={page}
                total={ytd?.indv?.length || 0}
                onChange={(current) => setPage(current)}
              />
            </div>
          </Skeleton>
        </div>
      </Modal>
    </>
  )
}

export { YTDDonations }
