import Api from 'api'
import axios from 'axios'
import { config } from 'configs'

export const LinkPaypalAccount = async (email) => {
  try {
    const accessToken = await Api.get('/stripe/paypal-access')
    const body = {
      tracking_id: email,
      partner_config_override: {
        partner_logo_url:
          'https://www.paypalobjects.com/webstatic/mktg/logo/pp_cc_mark_111x69.jpg',
        return_url: config.link_paypal_return_ul,
        return_url_description:
          'the url to return the merchant after the paypal onboarding process.',
        action_renewal_url: 'https://testenterprises.com/renew-exprired-url',
        show_add_credit_card: true,
      },
      operations: [
        {
          operation: 'API_INTEGRATION',
          api_integration_preference: {
            rest_api_integration: {
              integration_method: 'PAYPAL',
              integration_type: 'THIRD_PARTY',
              third_party_details: {
                features: ['PAYMENT', 'REFUND'],
              },
            },
          },
        },
      ],
      products: ['EXPRESS_CHECKOUT'],
      legal_consents: [
        {
          type: 'SHARE_DATA_CONSENT',
          granted: true,
        },
      ],
    }
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
      'PayPal-Partner-Attribution-Id': config.paypal_bncode,
    }
    const apiPath =
      'https://api-m.sandbox.paypal.com/v2/customer/partner-referrals'
    const res = await axios.post(apiPath, body, { headers })
    return res
  } catch (error) {
    console.log('error LinkPaypalAccount =>', error)
    return null
  }
}

export const LinkStripeAccount = async () => {
  const response = await Api.get('/stripe/stripe-payment')
  return response
}

export const UnlinkPaypalAccount = async (id) => {
  const data = { id }
  const response = await Api.put('/stripe/delete-paypal-id', data)
  return response
}

export const UnlinkStripeAccount = async (id, email) => {
  const data = {
    CreatedBy: email,
    Id: id,
  }
  const response = await Api.put('/stripe/delete-stripe-id', data)
  return response
}

export const savePaypalID = async (paypalId, email) => {
  const data = {
    paypalID: paypalId,
    email,
  }
  const response = await Api.put('/stripe/paypal-id', data)
  return response
}

export const saveStripeID = async (stripeId, email) => {
  const data = {
    stripeID: stripeId,
    email,
  }
  const response = await Api.put('/stripe/stripe-id', data)
  return response
}

export const donateByStripe = async (data) => {
  const response = await Api.post('/stripe/donate', data)
  return response
}

export const getUserCards = async (user) => {
  const response = await Api.get(`/stripe/cards?email=${user.email}`)
  return response
}

export const getUserBanks = async (user) => {
  const response = await Api.get(`/stripe/banks?email=${user.email}`)
  return response
}

export const getPlaidToken = async (data) => {
  const response = await Api.post('/stripe/plaid-token', data)
  return response
}

export const getExchangeToken = async (payload) => {
  const response = await Api.post('/stripe/exchangeToken', payload)
  return response
}

export const getBankAccount = async (payload) => {
  /* eslint-disable */
  const { accessToken, account_id, email, user } = payload
  return await Api.post(
    `/stripe/bankaccount?accessToken=${accessToken}&account_id=${account_id}&email=${email}`,
    { user },
  )
}

export const addPledgeDonation = async (data) => {
  const response = await Api.put('/payment/add-pledgeTransaction', data)
  return response
}

export const addCashDonation = async (data) => {
  const response = await Api.put('/payment/add-cashTransaction', data)
  return response
}

export const addPaypalDonation = async (data) => {
  const response = await Api.put('/payment/add-paypalTransaction', data)
  return response
}

export const addECheckDonation = async (data) => {
  const response = await Api.put('/payment/add-checkTransaction', data)
  return response
}

export const subscriberMarkupInfo = async (orgId) => {
  const response = await Api.get(`/admin/subsrciberMarkUpsByOrg/${orgId}`)
  return response
}
