/* eslint-disable */
import React, { useEffect } from 'react'
import { Card, Row, Typography, Col, Divider, Skeleton, message } from 'antd'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import {
  getEventControlScreenDetails,
  getEventDetail,
} from 'store/eventSlice/eventActions'
import { useEventSelector } from 'store/eventSlice/eventReducer'
import ReactPlayer from 'react-player'
import { formatAmount } from 'utils'
import { Donations, PledgeEvent, DonateEvent } from '.'

const { Title, Paragraph } = Typography

function EventDetail() {
  const dispatch = useDispatch()
  const history = useHistory()
  const { id } = useParams()
  const { loading, event, eventDetail } = useEventSelector()

  useEffect(() => {
    if (id) {
      dispatch(getEventDetail(id))
      dispatch(getEventControlScreenDetails(id))
    } else {
      message.error('Invalid URL')
      history.push('/events')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Card
      className="header-solid t-h-[calc(100vh-200px)] md:h-auto"
      bordered={false}
    >
      <Skeleton loading={loading} active paragraph={{ rows: 18 }}>
        <Row span={24} lg={24} xl={24} className="col-gallery mb-10 md:mb-24">
          <ReactPlayer
            playing={false}
            width="100%"
            url={event?.YoutubeLink}
            className="t-h-44 md:t-h-96"
          />
        </Row>
        <Row>
          <p className="t-text-xl md:t-text-3xl t-font-bold t-text-primary-100">
            {event?.Title}
          </p>
        </Row>
        <div className="t-hidden md:t-block">
          <Row>
            <Paragraph className="t-text-justify">{event?.Details}</Paragraph>
          </Row>
          <Row>
            <Title level={4}>Organization</Title>
          </Row>
          <Row>
            <h1>{event?.OrganizationName}</h1>
          </Row>
          <Divider />
        </div>

        <Row className="t-mt-1 md:t-mt-4 t-p-1 md:t-p-4">
          <Col
            xl={8}
            lg={8}
            md={24}
            sm={24}
            xs={24}
            className="t-flex t-flex-row md:t-flex-col t-justify-between"
          >
            <Row>
              <div className="t-text-center">
                <h6 className="t-text-base md:t-text-2xl t-font-bold t-text-green-500">
                  {formatAmount(eventDetail?.status?.Total)}
                </h6>
                <h6 className="t-text-xs md:t-text-md t-font-bold">Raised</h6>
              </div>
            </Row>
            <Row>
              <DonateEvent />
            </Row>
          </Col>
          <Col
            xl={8}
            lg={8}
            md={24}
            sm={24}
            xs={24}
            className="t-flex t-justify-center t-items-center"
          >
            <Row className="t-flex t-justify-center t-items-center">
              <div className="t-bg-gray-500 t-border-8 t-border-gray-800 t-rounded-full t-h-32 t-w-32 md:t-h-52 md:t-w-52 t-flex t-justify-center t-items-center t-flex-col">
                <h6 className="t-font-bold t-text-sm md:t-text-4xl t-text-green-500">
                  {formatAmount(event?.TargetAmount)}
                </h6>
                <h6 className="t-font-semibold t-text-xs md:t-text-md t-text-white">
                  Target Amuont
                </h6>
              </div>
            </Row>
          </Col>
          <Col
            xl={8}
            lg={8}
            md={24}
            sm={24}
            xs={24}
            className="t-flex t-flex-row md:t-flex-col t-justify-between"
          >
            <Row justify="end">
              <div className="t-text-center">
                <h6 className="t-text-base md:t-text-2xl t-font-bold t-text-red-500">
                  {`${
                    event?.TargetAmount - eventDetail?.status?.Total < 0
                      ? formatAmount(0)
                      : formatAmount(
                          event?.TargetAmount - eventDetail?.status?.Total,
                        )
                  }`}
                </h6>
                <h6 className="t-text-xs md:t-text-md t-font-bold">
                  Remaining
                </h6>
              </div>
            </Row>
            <Row justify="end">
              <Row>
                <PledgeEvent />
              </Row>
            </Row>
          </Col>
        </Row>
        <Divider />
        <Row>
          <p className="t-text-md md:t-text-3xl t-font-bold t-text-primary-100">
            Donations{' '}
          </p>
        </Row>
        <Row className="t-w-full">
          <Donations />
        </Row>
      </Skeleton>
    </Card>
  )
}

export { EventDetail }
