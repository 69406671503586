import React, { useState } from 'react'
import { Row, Col, Card, Descriptions, Button, Modal, Tooltip } from 'antd'
import { useHistory } from 'react-router-dom'
import { IoIosArrowRoundBack } from 'react-icons/io'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { useDispatch } from 'react-redux'
import { addFavourite } from 'store/favouriteSlice/favouriteActions'
import { useVolunteerSelector } from 'store/volunteerSlice/volunteerReducer'
import { MdLocationOn, MdEdit } from 'react-icons/md'
import { HiDuplicate } from 'react-icons/hi'
import {
  AiFillSchedule,
  AiOutlineHeart,
  AiFillEye,
  AiFillEyeInvisible,
} from 'react-icons/ai'
import {
  addVolunteer,
  registerForVolunteer,
  updateVolunteerStatus,
} from 'store/volunteerSlice/volunteerActions'
import { formateDateTime } from 'utils'
import sample from 'assets/images/sample/sample1.png'
import { RegisteredVolunteer } from './RegisteredVolunteer'
import { FeedbackByVolunteer } from './FeedbackByVolunteer'
import Feedback from './Feedback'

function VolunteerView() {
  const dispatch = useDispatch()
  const history = useHistory()
  const { loading, volunteer } = useVolunteerSelector()
  const { user } = useAuthSelector()
  const [showFeedbackModal, setShowFeedbackModal] = useState(false)

  const handleAddFavourite = (id) => {
    const payload = {
      ReferenceId: id,
      Username: user?.id,
      Type: 'volunteer',
    }
    dispatch(addFavourite(payload))
    history.push('/volunteer')
  }

  const handleDuplicate = () => {
    const newVolunteer = { ...volunteer }
    delete newVolunteer?.id
    dispatch(addVolunteer(newVolunteer))
    history.push('/volunteer')
  }

  const handleUpdateEvent = () => {
    dispatch(
      updateVolunteerStatus(
        volunteer?.id,
        volunteer?.ActiveInd,
        history.push('/volunteer'),
      ),
    )
  }

  const handleRegister = (id) => {
    const payload = {
      email: user.email,
      phoneNumber: user.phone,
      firstName: user.firstname,
      lastName: user.lastname,
      volunteerId: id,
      username: user.id,
    }
    dispatch(registerForVolunteer(payload, () => setShowFeedbackModal(true)))
  }

  return (
    <Row gutter={[24, 0]}>
      <Col span={24}>
        <Card
          title={[
            <div className="t-flex t-justify-between">
              <div className="t-flex t-items-center t-space-x-4">
                <Button
                  type="primary"
                  className="t-rounded-full t-flex t-items-center"
                  onClick={() => history.push('/volunteer')}
                  size="small"
                >
                  <IoIosArrowRoundBack />
                  Back
                </Button>
              </div>
              <div className="t-flex t-items-center t-space-x-2 t-cursor-pointer">
                <Tooltip title="Make Favorite">
                  <AiOutlineHeart
                    fontSize="1.5rem"
                    className="t-cursor-pointer"
                    onClick={() => handleAddFavourite(volunteer.id)}
                  />
                </Tooltip>
                {volunteer?.CreatedBy === user.email && (
                  <>
                    <Tooltip title="Edit Program">
                      <MdEdit
                        fontSize="1.5rem"
                        onClick={() =>
                          history.push(
                            `/settings/create-volunteer/${volunteer.id}`,
                          )
                        }
                      />
                    </Tooltip>
                    <Tooltip title="Duplicate Program">
                      <HiDuplicate
                        fontSize="1.5rem"
                        onClick={() => handleDuplicate()}
                      />
                    </Tooltip>
                    {volunteer?.ActiveInd === 1 ? (
                      <Tooltip title="Make Inactive">
                        <AiFillEyeInvisible
                          fontSize="1.5rem"
                          onClick={() => handleUpdateEvent()}
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Make Active">
                        <AiFillEye
                          fontSize="1.5rem"
                          onClick={() => handleUpdateEvent()}
                        />
                      </Tooltip>
                    )}
                  </>
                )}
              </div>
            </div>,
          ]}
          bordered={false}
          className="header-solid card-product mb-24"
        >
          <Row gutter={[24, 0]}>
            <Col span={24} lg={12} xl={10} className="col-gallery mb-24">
              <img
                src={sample}
                alt="card-2"
                className="gallery-img shadow-3 img-contain"
              />
            </Col>
            <Col span={24} xl={12} lg={{ offset: 1 }}>
              <div className="t-mb-4">
                <h3 className="h3 t-capitalize">
                  {volunteer.Title ? volunteer.Title : 'No Title Found'}
                </h3>
                <h5 className="font-semibold">{volunteer?.Description}</h5>

                <Descriptions colon={false} className="t-my-4">
                  <Descriptions.Item
                    label={<AiFillSchedule fontSize="1.5rem" />}
                    span={3}
                  >
                    {volunteer.Schedule
                      ? formateDateTime(volunteer.Schedule)
                      : 'No Schedule Found'}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={<MdLocationOn fontSize="1.5rem" />}
                    span={3}
                  >
                    {volunteer.Address
                      ? volunteer.Address
                      : `${null}, ${volunteer?.City}`
                      ? volunteer.City
                      : `${null}, ${volunteer?.LocationState}`
                      ? volunteer.LocationState
                      : `${null}, ${volunteer?.ZipCode}`
                      ? volunteer?.ZipCode
                      : null}
                  </Descriptions.Item>
                </Descriptions>
                <div className="t-my-4 t-space-x-2">
                  <Button
                    loading={loading}
                    type="primary"
                    onClick={() => handleRegister(volunteer.id)}
                  >
                    Register
                  </Button>
                </div>
                <div>
                  <Modal
                    title="Feedback"
                    visible={showFeedbackModal}
                    onCancel={() => setShowFeedbackModal(false)}
                    footer={null}
                  >
                    <Feedback
                      id={volunteer.id}
                      type="Volunteer"
                      close={() => setShowFeedbackModal(false)}
                    />
                  </Modal>
                </div>
              </div>
            </Col>
          </Row>
          {volunteer?.CreatedBy === user.email && (
            <Row gutter={[24, 0]} className="mt-24">
              <Col span={24}>
                <h6 className="t-font-bold t-text-base">Registered User</h6>
                <RegisteredVolunteer id={volunteer?.id} />
              </Col>
              <Col span={24}>
                <h6 className="t-font-bold t-text-base">User Feedback</h6>
                <FeedbackByVolunteer id={volunteer?.id} />
              </Col>
            </Row>
          )}
        </Card>
      </Col>
    </Row>
  )
}

export { VolunteerView }
