import React, { useEffect, useState } from 'react'
import { Skeleton, Table } from 'antd'
import { useDispatch } from 'react-redux'
import { useVolunteerSelector } from 'store/volunteerSlice/volunteerReducer'
import { getRegisterVolunteers } from 'store/volunteerSlice/volunteerActions'

function RegisteredVolunteer({ id }) {
  const dispatch = useDispatch()
  const { registeredVolunteers } = useVolunteerSelector()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    dispatch(getRegisterVolunteers(id, setLoading))
  }, [dispatch, id])

  const columns = [
    {
      title: 'Name',
      dataIndex: 'firstName',
      className: 'ant-table-row-cell-break-word',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      className: 'ant-table-row-cell-break-word',
    },
    {
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
      className: 'ant-table-row-cell-break-word',
    },
  ]

  return (
    <Skeleton loading={loading} active paragraph={{ rows: 12 }}>
      <Table
        tableLayout="auto"
        scroll={{ x: 500 }}
        columns={columns}
        dataSource={registeredVolunteers}
        bordered={false}
        pagination
      />
    </Skeleton>
  )
}

export { RegisteredVolunteer }
