const config = {
  basename: '',
  roles: {
    presenter: 'presenter',
    donor: 'donor',
    subscriber: 'subscriber',
    admin: 'platform_admin',
  },
  colors: {
    primary: '#103150',
    secondary: '#EEEEEE',
    blue: {
      100: '#103150',
    },
    gray: {
      100: '#EEEEEE',
    },
  },
  social_urls: {
    facebook: 'https://www.facebook.com/PledgeGarden/',
    instagram: 'https://www.instagram.com/PledgeGardenUSA/',
    twitter: 'https://twitter.com/PledgeGarden',
    linkledin: 'https://linkledin.com',
  },
  rowsPerPage: 6,
  itemFirstLoad: 12,
  itemPerLoad: 3,
  itemCompleteMessage: 'Yay! You have seen it all',
  splash_screen_seconds: 1,
  reCaptchaKey: '6Ld6NXkgAAAAAMLMVyXtaCAI3W28hgSpE8_fEP10',
  bucketUrl: 'https://pledge-garden-upload-content.s3.amazonaws.com/',
  stripe_publish_key:
    'pk_test_51K6dG5FiwAvE0g0SYBumgZ6qd7jaE5io9eUH5WdJqPIGAR6jvBQ4FXXcJ2pDQq8rP10WRt6Yo19lncRm9Nl4GMw900zTaGbh2t',
  paypal_client_id:
    'AQT7eHd2eAN4jwpjYj9qnPULc261rsy3rYNha1g3XlZ4biwuy2Zb-_achBzDaWB0hdDhqeu1QsVZDdTE',
  paypal_bncode: 'FLAVORsb-vg6yf11024588_MP',

  // deployed endpoints
  endpoint: 'https://api.dev.pledgegarden.com/v1',
  link_paypal_return_ul:
    'https://new-ui.dev.pledgegarden.com/settings/manage-payments-accounts',

  // local endpoints
  // endpoint: "http://localhost:4000/v1",
  // link_paypal_return_ul:
  //   "http://localhost:3000/settings/manage-payments-accounts",
}

export default config
