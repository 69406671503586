import React from 'react'
import { Card, Row, Col, Tabs } from 'antd'
import { MyOrganizations, Reports, ManageDonors, MyEvents } from './components'

function ManageOrganization() {
  return (
    <Card
      className="header-solid"
      bordered={false}
      title={[<h6 className="mb-0">My Organization</h6>]}
    >
      <div style={{ minHeight: 'calc(100vh - 220px)' }}>
        <Row gutter={[24, 0]}>
          <Col span={24}>
            <Tabs className="sliding-tab" defaultActiveKey="1" animated={false}>
              <Tabs.TabPane tab="Organization Detail" key="1">
                <MyOrganizations />
              </Tabs.TabPane>
              <Tabs.TabPane tab="My Donors" key="2">
                <ManageDonors />
              </Tabs.TabPane>
              <Tabs.TabPane tab="My Reports" key="3">
                <Reports />
              </Tabs.TabPane>
              <Tabs.TabPane tab="My Events" key="4">
                <MyEvents />
              </Tabs.TabPane>
            </Tabs>
          </Col>
          <hr className="my-25" />
        </Row>
      </div>
    </Card>
  )
}

export { ManageOrganization }
