import React from 'react'
import { Input, Button, Card, Form, Row, Col, DatePicker, message } from 'antd'
import { useHistory } from 'react-router-dom'
import { useServiceSelector } from 'store/serviceSlice/serviceReducer'
import { funeralValidation, marriageValidation } from 'validations'

function RegisterService() {
  const { loading, service } = useServiceSelector()
  const type = service.ServiceSubtype

  const history = useHistory()
  const [form] = Form.useForm()

  const resetForm = () => {
    form.resetFields()
  }

  const navigation = () => {
    history.push('/services')
  }

  const onFinish = () => {
    if (type === 'Marriage') {
      // console.log('Marriage', values);
    } else {
      // console.log('Funeral', values);
    }
    resetForm()
    navigation()
    message.success(`${type} Request Sent Successfully`)
  }

  return (
    <Card
      className="header-solid"
      bordered={false}
      title={[
        <h6 className="mb-0">
          Register for
          {type} Service
        </h6>,
      ]}
    >
      {type === 'Marriage' && (
        <div className="t-space-y-2 t-mb-8">
          <h6>Application for Nikkah (Islamic Marriage)</h6>
          <p className="font-regular">
            If you are looking for RCM&apos;s Resident Imam to conduct your
            marriage ceremony, please fill in the below form. A representative
            will be in contact with you within three business days. Thanks so
            much for choosing RCM to provide you with this blessed service!
          </p>
          <p>
            <b>NOTE:</b> RCM does not perform the Nikah (Islamic marriage
            ceremony) unless the couple provides proof of a court marriage
          </p>
        </div>
      )}
      {type === 'Marriage' ? (
        <Form
          form={form}
          layout="vertical"
          key={0}
          onFinish={onFinish}
          name="organization"
          scrollToFirstError
        >
          <Row gutter={[24, 0]}>
            <Col xl={12} md={12} xs={24} sm={24}>
              <Form.Item
                name="Date"
                label="Date"
                colon={false}
                rules={[marriageValidation]}
              >
                <DatePicker className="t-w-full" />
              </Form.Item>
            </Col>
            <Col xl={12} md={12} xs={24} sm={24}>
              <Form.Item
                name="MarriageLicenseNumber"
                label="Marriage License Number:"
                colon={false}
                rules={[marriageValidation]}
              >
                <Input placeholder="Enter Title" />
              </Form.Item>
            </Col>
            <Col xl={24} md={24} xs={24} sm={24}>
              <Form.Item
                name="CourtMarriageLocation"
                label="Court Marriage Location:"
                colon={false}
                rules={[marriageValidation]}
              >
                <Input placeholder="Enter Title" />
              </Form.Item>
            </Col>

            <Col xl={12} md={12} xs={24} sm={24}>
              <Form.Item
                name="BrideName"
                label="Bride's Name:"
                colon={false}
                rules={[marriageValidation]}
              >
                <Input placeholder="Enter Title" />
              </Form.Item>
            </Col>
            <Col xl={12} md={12} xs={24} sm={24}>
              <Form.Item
                name="GroomName"
                label="Groom's Name:"
                colon={false}
                rules={[marriageValidation]}
              >
                <Input placeholder="Enter Title" />
              </Form.Item>
            </Col>
            <p className="font-regular t-px-4 t-opacity-70 t-mb-8">
              In order for the Islamic Marriage to take place, the bride needs
              to have a Wali or Wakeel (Islamic legal representative). This is
              usually the father, grandfather, brother, paternal uncle, or
              maternal uncle of the bride. If the bride does not have a Wali or
              Wakeel that can attend, please contact the imam at
              imam@roswellmasjid.org for more information and type in &quot;not
              assigned&quot; in the text—box below.
            </p>

            <Col xl={12} md={12} xs={24} sm={24}>
              <Form.Item
                name="WaliWakeelName"
                label="Wali or Wakeel's Name:"
                colon={false}
                rules={[marriageValidation]}
              >
                <Input placeholder="Enter Title" />
              </Form.Item>
            </Col>
            <Col xl={12} md={12} xs={24} sm={24}>
              <Form.Item
                name="WaliWakeelRelationship"
                label="Wali/Wakeel's Relationship to the bride:"
                colon={false}
                // rules={[marriageValidation]}
              >
                <Input placeholder="Enter Title" />
              </Form.Item>
            </Col>

            <p className="font-regular t-px-4 t-opacity-70 t-mb-8">
              For a marriage to be valid, there needs to be two witnesses
              present. Please include the names of those who will attend the
              ceremony as a witness.
            </p>
            <Col xl={12} md={12} xs={24} sm={24}>
              <Form.Item
                name="Witness1Name: "
                label="Witness #1 Name:"
                colon={false}
                //   rules={[serviceValidation]}
              >
                <Input placeholder="Enter Title" />
              </Form.Item>
            </Col>
            <Col xl={12} md={12} xs={24} sm={24}>
              <Form.Item
                name="Witness2Name: "
                label="Witness #2 Name:"
                colon={false}
                //   rules={[serviceValidation]}
              >
                <Input placeholder="Enter Title" />
              </Form.Item>
            </Col>

            <p className="font-regular t-px-4 t-opacity-70 t-mb-4">
              Describe the dowry that will be given prior to or immediately
              after the marriage ceremony.
            </p>
            <Col xl={24} md={24} xs={24} sm={24}>
              <Form.Item
                name="MahrMuqaddam)"
                label="Mahr (Dowry) to be given immediately (mahr muqaddam):"
                colon={false}
                //   rules={[serviceValidation]}
              >
                <Input.TextArea
                  placeholder="Requirements ..."
                  autoSize={{ minRows: 2, maxRows: 3 }}
                />
              </Form.Item>
            </Col>
            <p className="font-regular t-px-4 t-opacity-70 t-mb-4">
              Describe the dowry, if applicable, to be given at a later time.
            </p>
            <Col xl={24} md={24} xs={24} sm={24}>
              <Form.Item
                name="MahrMuakhar"
                label="Mahr to be deferred (mahr muakhar):"
                colon={false}
                //   rules={[serviceValidation]}
              >
                <Input.TextArea
                  placeholder="Requirements ..."
                  autoSize={{ minRows: 2, maxRows: 3 }}
                />
              </Form.Item>
            </Col>

            <Col span={24} className="text-left">
              <Button
                loading={loading}
                type="primary"
                className="px-25"
                htmlType="submit"
              >
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      ) : (
        <Form
          form={form}
          layout="vertical"
          key={0}
          onFinish={onFinish}
          name="organization"
          scrollToFirstError
        >
          <Row gutter={[24, 0]}>
            <Col xl={12} md={12} xs={24} sm={24}>
              <Form.Item
                name="Name"
                label="Name of Deceased:"
                colon={false}
                rules={[funeralValidation]}
              >
                <Input placeholder="Enter Title" />
              </Form.Item>
            </Col>
            <Col xl={12} md={12} xs={24} sm={24}>
              <Form.Item
                name="DOB"
                label="DOB of deceased:"
                colon={false}
                rules={[funeralValidation]}
              >
                <DatePicker className="t-w-full" />
              </Form.Item>
            </Col>

            <Col xl={12} md={12} xs={24} sm={24}>
              <Form.Item
                name="SSN"
                label="SSN of deceased:"
                colon={false}
                rules={[funeralValidation]}
              >
                <Input placeholder="Enter Title" />
              </Form.Item>
            </Col>
            <Col xl={12} md={12} xs={24} sm={24}>
              <Form.Item
                name="NextKin"
                label="Next of Kin:"
                colon={false}
                //   rules={[serviceValidation]}
              >
                <Input placeholder="Enter Title" />
              </Form.Item>
            </Col>

            <Col xl={12} md={12} xs={24} sm={24}>
              <Form.Item
                name="DateOfDeath"
                label="Date of Death"
                colon={false}
                rules={[funeralValidation]}
              >
                <DatePicker className="t-w-full" />
              </Form.Item>
            </Col>
            <Col xl={12} md={12} xs={24} sm={24}>
              <Form.Item
                name="GraveyardName"
                label="Name of Graveyard for burial:"
                colon={false}
                rules={[funeralValidation]}
              >
                <Input placeholder="Enter Title" />
              </Form.Item>
            </Col>
            <Col xl={24} md={24} xs={24} sm={24}>
              <Form.Item
                name="SpecialInstructions"
                label="Special instructions:"
                colon={false}
                //   rules={[serviceValidation]}
              >
                <Input.TextArea
                  placeholder="Special instructions: ..."
                  autoSize={{ minRows: 2, maxRows: 3 }}
                />
              </Form.Item>
            </Col>

            <Col xl={24} md={24} xs={24} sm={24}>
              <Form.Item
                name="DateOfBurial"
                label="Requested Date of burial"
                colon={false}
                rules={[funeralValidation]}
              >
                <DatePicker className="t-w-full" />
              </Form.Item>
            </Col>

            <Col xl={24} md={24} xs={24} sm={24}>
              <Form.Item
                name="OtherInstructions"
                label="Other instructions:"
                colon={false}
                //   rules={[serviceValidation]}
              >
                <Input.TextArea
                  placeholder="Other instructions: ..."
                  autoSize={{ minRows: 2, maxRows: 3 }}
                />
              </Form.Item>
            </Col>

            <Col span={24} className="text-left">
              <Button
                loading={loading}
                type="primary"
                className="px-25"
                htmlType="submit"
              >
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Card>
  )
}

export { RegisterService }
