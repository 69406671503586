import Api from 'api'
import { uploadFile } from 'store/authSlice/authActions'
import { message } from 'antd'
import { eventActions } from './eventReducer'

export const addEvent = async (data, user) => {
  let updatedData = data
  const flyerFile = await uploadFile(updatedData.Flyer)
  updatedData = {
    ...updatedData,
    Flyer: {
      fileName: flyerFile.fileName,
      fileMimeType: flyerFile.fileMimeType,
    },
  }
  const presentationFile = await uploadFile(updatedData.Presentation)
  updatedData = {
    ...updatedData,
    Presentation: {
      fileName: presentationFile.fileName,
      fileMimeType: presentationFile.fileMimeType,
    },
  }
  updatedData = {
    ...updatedData,
    CreatedBy: user.email,
    refLink: `${window.location.origin}/presenter/accept`,
    Username: user.id,
    isSelfPresent: updatedData.Presenter === 'Myself',
  }
  if (updatedData.isSelfPresent) {
    updatedData = {
      ...updatedData,
      PresenterName: `${user.firstname} ${user.lastname}`,
      PresenterEmail: user.email,
      PresenterPhoneCode: user.phone,
      PresenterMobileNumber: user.phone,
    }
  }
  delete updatedData.recaptcha
  const response = await Api.put('/events', updatedData)
  return response
}

export const updateEvent = async (data, user) => {
  let updatedData = data

  if (!updatedData.Flyer.fileName) {
    const flyerFile = await uploadFile(updatedData.Flyer)
    updatedData = {
      ...updatedData,
      Flyer: {
        fileName: flyerFile.fileName,
        fileMimeType: flyerFile.fileMimeType,
      },
    }
  }

  if (updatedData.Presentation) {
    const presentationFile = await uploadFile(updatedData.Presentation)
    updatedData = {
      ...updatedData,
      Presentation: {
        fileName: presentationFile.fileName,
        fileMimeType: presentationFile.fileMimeType,
      },
    }
  }
  updatedData = {
    ...updatedData,
    CreatedBy: user.email,
    refLink: `${window.location.origin}/presenter/accept`,
    Username: user.id,
    isSelfPresent: updatedData.Presenter === 'Myself',
  }
  const response = await Api.put('/events/updateEvent', updatedData)
  return response
}

export const getNearByEvents = (body) => (dispatch) => {
  dispatch(eventActions.setLoading(true))
  Api.put('/donor/nearBy', body)
    .then((res) => {
      dispatch(eventActions.setEvents(res?.results?.data))
      dispatch(eventActions.setCampaigns(res?.results?.data))
      dispatch(eventActions.setLoading(false))
    })
    .catch((error) => {
      console.log('error getEvents => ', error)
      dispatch(eventActions.setLoading(false))
    })
}

export const updateEventStatus =
  (eventId, status, positionData) => (dispatch) => {
    dispatch(eventActions.setLoading(true))
    if (status === 1) {
      Api.get(`events/deactive?id=${eventId}`)
        .then(() => {
          dispatch(eventActions.setLoading(false))
          message.success('Event Deactivated Successfully')
          dispatch(getNearByEvents(positionData))
        })
        .catch((error) => {
          console.log('error updateEventStatus 1 => ', error)
          message.error('Something Went Wrong')
          dispatch(eventActions.setLoading(false))
        })
    } else if (status === 0) {
      Api.get(`events/active?id=${eventId}`)
        .then(() => {
          dispatch(eventActions.setLoading(false))
          message.success('Event Activated Successfully')
          dispatch(getNearByEvents(positionData))
        })
        .catch((error) => {
          console.log('error updateEventStatus 0 => ', error)
          message.error('Something Went Wrong')
          dispatch(eventActions.setLoading(false))
        })
    }
  }

export const getEventDetail = (id) => (dispatch) => {
  dispatch(eventActions.setLoading(true))
  Api.get(`/events?id=${id}`)
    .then((res) => {
      dispatch(eventActions.setEvent(res.results[0]))
      dispatch(eventActions.setLoading(false))
    })
    .catch((error) => {
      console.log('error getEvent => ', error)
      dispatch(eventActions.setLoading(false))
    })
}

export const getEvent = async (id) => {
  const response = await Api.get(`/events?id=${id}`)
  return response.results && response.results[0]
}

export const getEventControlScreenDetails = (id) => (dispatch) => {
  /* eslint-disable */
  // get Event Status
  const getStatus = (id) => (dispatch) => {
    Api.get(`/presenter/stats?Id=${id}`)
      .then((res) => {
        dispatch(eventActions.setEventStatus(res?.results))
        dispatch(eventActions.setLoading(false))
      })
      .catch((error) => {
        console.log('error getEvent => ', error)
        dispatch(eventActions.setLoading(false))
      })
  }
  // get Table Tasks
  const getTableTasks = (id) => (dispatch) => {
    Api.get(`/payment/table-task?id=${id}`)
      .then((res) => {
        dispatch(eventActions.setEventTableTask(res?.results))
        dispatch(getStatus(id))
      })
      .catch((error) => {
        console.log('error getEvent => ', error)
        dispatch(eventActions.setLoading(false))
      })
  }
  // get all Donations
  // dispatch(eventActions.setLoading(true));
  Api.get(`/presenter/allDonations?Id=${id}`)
    .then((res) => {
      dispatch(eventActions.setEventDonations(res?.results))
      dispatch(getTableTasks(id))
    })
    .catch((error) => {
      console.log('error getEvent => ', error)
      dispatch(eventActions.setLoading(false))
    })
}

export const startEventApi = async (id) => {
  const response = await Api.get(`/events/start?Id=${id}`)
  return response
}

export const stopEventApi = async (id) => {
  const response = await Api.get(`/events/stop?Id=${id}`)
  return response
}

export const submitManualCheckEntry = async (data) => {
  const response = await Api.put('/presenter/submitCheckManualEntry', data)
  return response
}

export const updateAskedAmount = async (data) => {
  const response = await Api.put('/presenter/update-ask', data)
  return response
}

export const getSubscriberPaymentInformation = (email) => (dispatch) => {
  dispatch(eventActions.setLoading(true))
  Api.get(`/stripe/payment-account-info?email=${email}`)
    .then((res) => {
      if (res.results.length === 0 || !res.results[0]?.stripeID) {
        dispatch(
          eventActions.setSubscriberStripeInfo({
            isConnected: false,
            stripeId: null,
          }),
        )
      } else {
        dispatch(
          eventActions.setSubscriberStripeInfo({
            isConnected: true,
            stripeId: res.results[0]?.stripeID,
          }),
        )
      }
      if (res.results.length === 0 || !res.results[0]?.paypalID) {
        dispatch(
          eventActions.setSubscriberPaypalInfo({
            isConnected: false,
            paypalId: null,
          }),
        )
      } else {
        dispatch(
          eventActions.setSubscriberPaypalInfo({
            isConnected: true,
            paypalId: res.results[0]?.paypalID,
          }),
        )
      }
      dispatch(eventActions.setLoading(false))
    })
    .catch((error) => {
      console.log('error getSubscriberPaymentInformation', error)
      dispatch(eventActions.setLoading(false))
    })
}

export const getSubscriberMarkupInfo = (orgId) => (dispatch) => {
  dispatch(eventActions.setLoading(true))
  Api.get(`/admin/subsrciberMarkUpsByOrg/${orgId}`)
    .then((res) => {
      dispatch(
        eventActions.setSubscriberMarkupInfo(
          Number(res?.results?.StripeMarkup) || 2.9,
        ),
      )
      dispatch(eventActions.setLoading(false))
    })
    .catch(() => {
      dispatch(eventActions.setLoading(false))
    })
}

export const acceptEventInvite = (eventId, positionData) => (dispatch) => {
  dispatch(eventActions.setLoading(true))
  Api.get(`/events/accept-presenter?Id=${eventId}`)
    .then(() => {
      message.success('Invite Accepted Successfully')
      dispatch(eventActions.setLoading(false))
      dispatch(getNearByEvents(positionData))
    })
    .catch(() => {
      dispatch(eventActions.setLoading(false))
    })
}

export const rejectEventInvite = (eventId, positionData) => (dispatch) => {
  dispatch(eventActions.setLoading(true))
  Api.get(`/events/reject-presenter?Id=${eventId}`)
    .then(() => {
      message.success('Invite Rejected Successfully')
      dispatch(eventActions.setLoading(false))
      dispatch(getNearByEvents(positionData))
    })
    .catch(() => {
      dispatch(eventActions.setLoading(false))
    })
}
