import React, { useEffect, useState } from 'react'
import { Skeleton, Table } from 'antd'
import { useDispatch } from 'react-redux'
import { useVolunteerSelector } from 'store/volunteerSlice/volunteerReducer'
import { getFeedbackByVolunteers } from 'store/volunteerSlice/volunteerActions'

function FeedbackByVolunteer({ id }) {
  const dispatch = useDispatch()
  const { feedbackByVolunteers } = useVolunteerSelector()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    dispatch(getFeedbackByVolunteers(id, setLoading))
  }, [dispatch, id])

  const columns = [
    {
      title: 'Email',
      dataIndex: 'Email',
      className: 'ant-table-row-cell-break-word',
    },
    {
      title: 'Phone Number',
      dataIndex: 'PhoneNumber',
      className: 'ant-table-row-cell-break-word',
    },
    {
      title: 'Comment',
      dataIndex: 'RatingComment',
      className: 'ant-table-row-cell-break-word',
    },
    {
      title: 'Rating',
      dataIndex: 'Rating',
      className: 'ant-table-row-cell-break-word',
    },
  ]

  return (
    <Skeleton loading={loading} active paragraph={{ rows: 12 }}>
      <Table
        tableLayout="auto"
        scroll={{ x: 500 }}
        columns={columns}
        dataSource={feedbackByVolunteers}
        bordered={false}
        pagination
      />
    </Skeleton>
  )
}

export { FeedbackByVolunteer }
