import React, { useEffect } from 'react'
import { Input, Button, Card, Form, Row, Col, Select } from 'antd'
import { programValidation } from 'validations'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import {
  addProgram,
  getProgramDetail,
  updateProgram,
} from 'store/programSlice/programActions'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { useProgramSelector } from 'store/programSlice/programReducer'

const initialValues = {
  Title: '',
  Description: '',
  Requirements: '',
  Cost: '',
  // LocationType: "",
  Notes: '',
  AllowedRegistration: '',
}

function CreateProgram() {
  const { user, location, organization } = useAuthSelector()
  const { loading } = useProgramSelector()
  const dispatch = useDispatch()
  const history = useHistory()
  const [form] = Form.useForm()
  const { id } = useParams()

  useEffect(() => {
    if (organization && organization?.ZipCode) {
      form.setFieldsValue({ ZipCode: organization.ZipCode })
    }
    // eslint-disable-next-line
  }, [organization])

  Form.useWatch('LocationType', form)
  const isHybrid = form.getFieldValue('LocationType') === 'Hybrid'

  useEffect(() => {
    if (id) {
      dispatch(getProgramDetail(id, form))
    }
    // eslint-disable-next-line
  }, [dispatch, id])

  const resetForm = () => {
    form.resetFields()
  }

  const navigation = () => {
    history.push('/programs')
  }

  const onFinish = (values) => {
    let data
    if (values.LocationType === 'Hybrid') {
      data = {
        ...values,
        ServiceType: 'program',
        lat: location.lat,
        lon: location.lng,
        zipCode: location.zipcode,
        Username: user.id,
      }
    } else {
      data = {
        ...values,
        ServiceType: 'program',
        Username: user.id,
      }
    }
    if (id) {
      dispatch(updateProgram({ id, ...data }, navigation))
    } else {
      dispatch(addProgram(data, resetForm))
    }
  }

  return (
    <Card
      className="header-solid"
      bordered={false}
      title={[
        <>
          <h6 className="mb-0">{id ? 'Update Program' : 'Add Program'}</h6>
          <p className="font-regular">{id || 'Enter Information'}</p>
        </>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        key={0}
        onFinish={onFinish}
        name="organization"
        initialValues={initialValues}
        scrollToFirstError
      >
        <Row gutter={[24, 0]}>
          <Col xl={12} md={12} xs={24} sm={24}>
            <Form.Item
              name="Title"
              label="Title"
              colon={false}
              rules={[programValidation]}
            >
              <Input placeholder="Enter Title" />
            </Form.Item>
          </Col>
          <Col xl={12} md={12} xs={24} sm={24}>
            <Form.Item
              name="LocationType"
              label="Location Type"
              colon={false}
              rules={[programValidation]}
            >
              <Select
                placeholder="Select Location Type"
                style={{ width: '100%' }}
              >
                <Select.Option value="Onsite">Onsite</Select.Option>
                <Select.Option value="Online">Online</Select.Option>
                <Select.Option value="Hybrid">Hybrid</Select.Option>
              </Select>
            </Form.Item>
          </Col>

          {isHybrid && (
            <>
              <Col xl={12} md={12} xs={24} sm={24}>
                <Form.Item
                  name="ZipCode"
                  label="Zip Code"
                  colon={false}
                  rules={[programValidation]}
                >
                  <Input placeholder="Enter Zip Code" />
                </Form.Item>
              </Col>
              <Col xl={12} md={12} xs={24} sm={24}>
                <Form.Item
                  name="City"
                  label="City"
                  colon={false}
                  rules={[programValidation]}
                >
                  <Input placeholder="Enter City" />
                </Form.Item>
              </Col>
              <Col xl={12} md={12} xs={24} sm={24}>
                <Form.Item
                  name="LocationState"
                  label="State"
                  colon={false}
                  rules={[programValidation]}
                >
                  <Input placeholder="Enter State" />
                </Form.Item>
              </Col>
              <Col xl={12} md={12} xs={24} sm={24}>
                <Form.Item
                  name="Address"
                  label="Street Address"
                  colon={false}
                  rules={[programValidation]}
                >
                  <Input placeholder="Enter Address" />
                </Form.Item>
              </Col>
            </>
          )}
          <Col xl={12} md={12} xs={24} sm={24}>
            <Form.Item
              name="Cost"
              label="Programs Dues"
              colon={false}
              rules={[programValidation]}
            >
              <Input placeholder="Enter Programs Dues" type="number" />
            </Form.Item>
          </Col>

          <Col xl={12} md={12} xs={24} sm={24}>
            <Form.Item
              name="AllowedRegistration"
              label="Maximum # of Registrations"
              colon={false}
              rules={[programValidation]}
            >
              <Input placeholder="Enter Allowed Registration" type="number" />
            </Form.Item>
          </Col>
          <Col xl={24} md={24} xs={24} sm={24}>
            <Form.Item
              name="Description"
              label="Description"
              colon={false}
              rules={[programValidation]}
            >
              <Input.TextArea
                placeholder="Description ..."
                autoSize={{ minRows: 2, maxRows: 3 }}
              />
            </Form.Item>
          </Col>

          <Col xl={24} md={24} xs={24} sm={24}>
            <Form.Item
              name="Requirements"
              label="Requirements"
              colon={false}
              rules={[programValidation]}
            >
              <Input.TextArea
                placeholder="Requirements ..."
                autoSize={{ minRows: 2, maxRows: 3 }}
              />
            </Form.Item>
          </Col>
          <Col xl={24} md={24} xs={24} sm={24}>
            <Form.Item
              name="Notes"
              label="Notes"
              colon={false}
              rules={[programValidation]}
            >
              <Input.TextArea
                placeholder="Notes ..."
                autoSize={{ minRows: 2, maxRows: 3 }}
              />
            </Form.Item>
          </Col>
          <Col span={24} className="text-left">
            <Button
              loading={loading}
              type="primary"
              className="px-25"
              htmlType="submit"
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  )
}

export { CreateProgram }
