import React, { useState } from 'react'
import { Card, Input, Tooltip } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import {
  BsBookmarkHeart,
  BsBookmarkHeartFill,
  BsFolderFill,
  BsFolderX,
} from 'react-icons/bs'
import { CampaignList, FavouriteList, InactiveList } from './components'

function Campaigns() {
  const [isFavSelected, setIsFavSelected] = useState(false)
  const [isInctiveSelected, setIsInctiveSelected] = useState(false)
  const [searchText, setSearchText] = useState(null)

  return (
    <Card
      bordered={false}
      className="header-solid mb-24"
      title={
        <div className="t-flex t-flex-wrap t-justify-between">
          <div>
            <h6 className="font-semibold t-text-2xl">Campaigns</h6>
            <p>
              {!isFavSelected
                ? 'All available campaigns in your area'
                : 'Your favourite campaigns'}
            </p>
          </div>
          <div className="t-flex t-justify-end t-items-center t-space-x-2 t-mt-4 sm:t-mt-0">
            <div className="header-control t-mt-0">
              <Input
                className="header-search"
                placeholder="Search Campaign"
                prefix={<SearchOutlined />}
                onChange={(e) => {
                  setSearchText(e.target.value)
                }}
              />
            </div>
            {!isFavSelected && (
              <>
                {isInctiveSelected ? (
                  <Tooltip title="Active Campaigns">
                    <BsFolderFill
                      fontSize="1.9rem"
                      className="t-text-primary-100 t-cursor-pointer"
                      onClick={() => {
                        setIsInctiveSelected(false)
                      }}
                    />
                  </Tooltip>
                ) : (
                  <Tooltip title="Inactive Campaigns">
                    <BsFolderX
                      fontSize="1.9rem"
                      className="t-text-primary-100 t-cursor-pointer"
                      onClick={() => {
                        setIsInctiveSelected(true)
                      }}
                    />
                  </Tooltip>
                )}
              </>
            )}
            {!isInctiveSelected && (
              <>
                {isFavSelected ? (
                  <Tooltip title="Back To All Campaigns">
                    <BsBookmarkHeartFill
                      fontSize="1.7rem"
                      className="t-text-primary-100 t-cursor-pointer"
                      onClick={() => setIsFavSelected(false)}
                    />
                  </Tooltip>
                ) : (
                  <Tooltip title="Favourite Campaigns">
                    <BsBookmarkHeart
                      fontSize="1.7rem"
                      className="t-text-primary-100 t-cursor-pointer"
                      onClick={() => setIsFavSelected(true)}
                    />
                  </Tooltip>
                )}
              </>
            )}
          </div>
        </div>
      }
    >
      <>
        {!isFavSelected && !isInctiveSelected && (
          <CampaignList searchText={searchText} />
        )}
        {isFavSelected && <FavouriteList searchText={searchText} />}
        {isInctiveSelected && <InactiveList searchText={searchText} />}
      </>
    </Card>
  )
}

export { Campaigns }
