import * as Yup from 'yup'

const zipCodeRegex = '^[0-9]{5}(?:-[0-9]{4})?$'

const schema = Yup.object().shape({
  Title: Yup.string().required('Title is required'),
  Description: Yup.string()
    .max(50, 'Description should be less than 50 character')
    .required('Description is required'),
  Schedule: Yup.string(),
  ZipCode: Yup.string()
    .matches(zipCodeRegex, 'Invalid Zipcode e.g 12345')
    .required('Zipcode is required'),
  City: Yup.string().required('City is required'),
  LocationState: Yup.string().required('LocationState is required'),
  Address: Yup.string().required('Address is required'),
  Notes: Yup.string()
    .min(20, 'Notes should be atleast 20 character')
    .required('Notes is required'),
})

const volunteerValidation = {
  async validator({ field }, value) {
    await schema.validateSyncAt(field, { [field]: value })
  },
}

export { volunteerValidation }
