import { combineReducers } from 'redux'
import { authReducer } from 'store/authSlice/authReducer'
import { organizationReducer } from 'store/organizationSlice/organizationReducer'
import { favouriteReducer } from 'store/favouriteSlice/favouriteReducer'
import { eventReducer } from 'store/eventSlice/eventReducer'
import { programReducer } from 'store/programSlice/programReducer'
import { serviceReducer } from 'store/serviceSlice/serviceReducer'
import { volunteerReducer } from 'store/volunteerSlice/volunteerReducer'

const rootReducer = combineReducers({
  auth: authReducer,
  organization: organizationReducer,
  favourite: favouriteReducer,
  event: eventReducer,
  program: programReducer,
  service: serviceReducer,
  volunteer: volunteerReducer,
})

export { rootReducer }
