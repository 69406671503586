import React, { useState } from 'react'
import { config } from 'configs'
import { Switch } from 'antd'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { AdminOrganizationsView } from './admin'
import { DonorOrganizationsView } from './donor'

function OrganizationsList() {
  const [isAdminView, setAdminView] = useState(false)
  const { user } = useAuthSelector()
  const isAdmin = user.roles.includes(config.roles.admin)

  return (
    <>
      {isAdmin && (
        <div className="t-w-full t-flex t-justify-end t-mb-4 t-space-x-2 t-items-center">
          <h1 className="t-font-bold t-text-primary-100">View As Admin</h1>
          <Switch
            title="View As Admin"
            checked={isAdminView}
            onChange={(checked) => setAdminView(checked)}
          />
        </div>
      )}
      {isAdmin && isAdminView ? (
        <AdminOrganizationsView />
      ) : (
        <DonorOrganizationsView />
      )}
    </>
  )
}

export { OrganizationsList }
