import React from 'react'
import { Icon } from 'components/elements'

const navLogo = (props) => {
  const { collapsed } = props

  return (
    <div style={{ textAlign: 'center' }}>
      {collapsed ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Icon src="logo_icon" width={50} height="auto" />
        </div>
      ) : (
        <Icon src="logo_text" width="auto" height={40} />
      )}
    </div>
  )
}

export default navLogo
