/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { Skeleton } from 'antd'
import Api from 'api'

function PerformanceSummary({ id }) {
  const [loading, setLoading] = useState()
  const [report, setReport] = useState()

  useEffect(() => {
    setLoading(true)
    Api.get(`adminReports/getPerformanceSummaryDetailsById/${id}`)
      .then((res) => {
        setReport(res.results)
        console.log('hello', res)
        setLoading(false)
      })
      .catch((err) => {
        console.log('Error', err)
        setLoading(false)
      })
    // eslint-disable-next-line
  }, [])

  return (
    <Skeleton loading={loading} paragraph={{ rows: 12 }}>
      {report ? (
        <div className="t-space-y-4">
          <div className="t-flex t-items-center t-justify-between">
            <p>Goal Amount</p>
            {report.goalAmount}
          </div>
          <div className="t-flex t-items-center t-justify-between">
            <p>Donated Amount</p>
            {report.donatedAmount}
          </div>
          <div className="t-flex t-items-center t-justify-between">
            <p>Pledged Amount</p>
            {report.pledgeAmount}
          </div>
          <div className="t-flex t-items-center t-justify-between">
            <p>Number of Attendees</p>
            {report.noOfAttendies}
          </div>
          <div className="t-flex t-items-center t-justify-between">
            <p>Event Cost</p>
            {report.earningToDate * 0.02}
          </div>
        </div>
      ) : (
        <div>No Data Found</div>
      )}
    </Skeleton>
  )
}

export default PerformanceSummary
