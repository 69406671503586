import React, { useEffect } from 'react'
import { Input, Button, Card, Form, Row, Col, Select } from 'antd'
import { serviceValidation } from 'validations'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { useServiceSelector } from 'store/serviceSlice/serviceReducer'
import {
  addService,
  getServiceDetail,
  updateService,
} from 'store/serviceSlice/serviceActions'

const initialValues = {
  Title: '',
  Description: '',
  Requirements: '',
  Cost: '',
  // LocationType: "",
  Notes: '',
  AllowedRegistration: '',
}

function CreateService() {
  const { user, location } = useAuthSelector()
  const { loading } = useServiceSelector()
  const dispatch = useDispatch()
  const history = useHistory()
  const [form] = Form.useForm()
  const { id } = useParams()

  Form.useWatch('LocationType', form)
  const isHybrid = form.getFieldValue('LocationType') === 'Hybrid'

  useEffect(() => {
    if (id) {
      dispatch(getServiceDetail(id, form))
    }
    // eslint-disable-next-line
  }, [dispatch, id])

  const resetForm = () => {
    form.resetFields()
  }

  const navigation = () => {
    history.push('/services')
  }

  const onFinish = (values) => {
    let data
    if (values.LocationType === 'Hybrid') {
      data = {
        ...values,
        ServiceType: 'service',
        lat: location.lat,
        lon: location.lng,
        zipCode: location.zipcode,
        Username: user.id,
      }
    } else {
      data = {
        ...values,
        ServiceType: 'service',
        Username: user.id,
      }
    }
    if (id) {
      dispatch(updateService({ id, ...data }, navigation))
    } else {
      dispatch(addService(data, resetForm))
    }
  }

  return (
    <Card
      className="header-solid"
      bordered={false}
      title={[
        <>
          <h6 className="mb-0">{id ? 'Update Service' : 'Add Service'}</h6>
          <p className="font-regular">{id || 'Enter Information'}</p>
        </>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        key={0}
        onFinish={onFinish}
        name="organization"
        initialValues={initialValues}
        scrollToFirstError
      >
        <Row gutter={[24, 0]}>
          <Col xl={12} md={12} xs={24} sm={24}>
            <Form.Item
              name="Title"
              label="Title"
              colon={false}
              rules={[serviceValidation]}
            >
              <Input placeholder="Enter Title" />
            </Form.Item>
          </Col>
          <Col xl={12} md={12} xs={24} sm={24}>
            <Form.Item
              name="ServiceSubtype"
              label="Service Type"
              colon={false}
              rules={[serviceValidation]}
            >
              <Select
                placeholder="Select Service Type"
                style={{ width: '100%' }}
              >
                <Select.Option value="Marriage">
                  Use Template - Marriage
                </Select.Option>
                <Select.Option value="Funeral">
                  Use Template - Funeral
                </Select.Option>
                <Select.Option value="Custom">Custom</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xl={12} md={12} xs={24} sm={24}>
            <Form.Item
              name="Cost"
              label="Programs Dues"
              colon={false}
              rules={[serviceValidation]}
            >
              <Input placeholder="Enter Programs Dues" type="number" />
            </Form.Item>
          </Col>
          <Col xl={12} md={12} xs={24} sm={24}>
            <Form.Item
              name="LocationType"
              label="Location Type"
              colon={false}
              rules={[serviceValidation]}
            >
              <Select
                placeholder="Select Location Type"
                style={{ width: '100%' }}
              >
                <Select.Option value="Organization Address">
                  Organization Address
                </Select.Option>
                <Select.Option value="Online">Online</Select.Option>
                <Select.Option value="Hybrid">Hybrid</Select.Option>
              </Select>
            </Form.Item>
          </Col>

          {isHybrid && (
            <>
              <Col xl={12} md={12} xs={24} sm={24}>
                <Form.Item
                  name="ZipCode"
                  label="Zip Code"
                  colon={false}
                  rules={[serviceValidation]}
                >
                  <Input placeholder="Enter Zip Code" />
                </Form.Item>
              </Col>
              <Col xl={12} md={12} xs={24} sm={24}>
                <Form.Item
                  name="City"
                  label="City"
                  colon={false}
                  rules={[serviceValidation]}
                >
                  <Input placeholder="Enter City" />
                </Form.Item>
              </Col>
              <Col xl={12} md={12} xs={24} sm={24}>
                <Form.Item
                  name="LocationState"
                  label="State"
                  colon={false}
                  rules={[serviceValidation]}
                >
                  <Input placeholder="Enter State" />
                </Form.Item>
              </Col>
              <Col xl={12} md={12} xs={24} sm={24}>
                <Form.Item
                  name="Address"
                  label="Address"
                  colon={false}
                  rules={[serviceValidation]}
                >
                  <Input placeholder="Enter Address" />
                </Form.Item>
              </Col>
            </>
          )}

          <Col xl={24} md={24} xs={24} sm={24}>
            <Form.Item
              name="Description"
              label="Description"
              colon={false}
              rules={[serviceValidation]}
            >
              <Input.TextArea
                placeholder="Description ..."
                autoSize={{ minRows: 2, maxRows: 3 }}
              />
            </Form.Item>
          </Col>

          <Col xl={24} md={24} xs={24} sm={24}>
            <Form.Item
              name="Requirements"
              label="Requirements"
              colon={false}
              rules={[serviceValidation]}
            >
              <Input.TextArea
                placeholder="Requirements ..."
                autoSize={{ minRows: 2, maxRows: 3 }}
              />
            </Form.Item>
          </Col>
          <Col xl={24} md={24} xs={24} sm={24}>
            <Form.Item
              name="Notes"
              label="Notes"
              colon={false}
              rules={[serviceValidation]}
            >
              <Input.TextArea
                placeholder="Notes ..."
                autoSize={{ minRows: 2, maxRows: 3 }}
              />
            </Form.Item>
          </Col>
          <Col span={24} className="text-left">
            <Button
              loading={loading}
              type="primary"
              className="px-25"
              htmlType="submit"
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  )
}

export { CreateService }
