import React, { useState } from 'react'
import { Input, Button, Card, Form, Row, Col, Typography, message } from 'antd'
import { BsYoutube } from 'react-icons/bs'
import { RiFilePpt2Fill } from 'react-icons/ri'
import { useParams } from 'react-router-dom'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { getEvent, updateAskedAmount } from 'store/eventSlice/eventActions'
import { useDispatch } from 'react-redux'
import { eventActions, useEventSelector } from 'store/eventSlice/eventReducer'
import * as Yup from 'yup'
import { config } from 'configs'

const { Title } = Typography

const numberValidation = new RegExp('^[0-9]+$')

const schema = Yup.object().shape({
  AskedAmount: Yup.string()
    .required('Asked amount is Required')
    .matches(numberValidation, 'Invalid amount'),
})

const askedAmountValidation = {
  async validator({ field }, value) {
    await schema.validateSyncAt(field, { [field]: value })
  },
}

const initialValues = {}

function Control() {
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()
  const { id } = useParams()
  const { user } = useAuthSelector()
  const { event } = useEventSelector()
  const dispatch = useDispatch()

  const onFinish = async (values) => {
    setLoading(true)
    try {
      const data = {
        Id: id,
        CurrentAsk: values.AskedAmount,
        CreatedBy: user?.email,
      }
      await updateAskedAmount(data)
      const eventDetail = await getEvent(id)
      dispatch(eventActions.setEvent(eventDetail))
      form.resetFields()
      message.success('Current Asked Amount Updated Successfully')
      setLoading(false)
    } catch (error) {
      setLoading(false)
      message.error('Something went wrong')
    }
  }

  const handleInputChange = (value) => {
    const val = value.replace(/\D/g, '')
    form.setFieldsValue({ AskedAmount: val })
  }

  return (
    <Card>
      <Form
        form={form}
        layout="vertical"
        key={0}
        onFinish={onFinish}
        name="organization"
        initialValues={initialValues}
        scrollToFirstError
      >
        <Row gutter={[24, 0]}>
          <Col xl={24} md={24} xs={24} sm={24}>
            <Form.Item
              name="AskedAmount"
              label="Asked Amount"
              colon={false}
              rules={[askedAmountValidation]}
            >
              <Input
                placeholder="50000"
                onChange={(e) => handleInputChange(e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col span={24} className="text-left">
            <Button
              loading={loading}
              type="primary"
              className="px-25"
              htmlType="submit"
            >
              {loading ? 'Updating Asked Amount' : 'Edit Asked Amount'}
            </Button>
          </Col>
        </Row>
      </Form>
      <Col className="t-my-5">
        <Row>
          <Title level={4}>Information</Title>
        </Row>
        <Row className="t-flex t-items-center t-space-x-4">
          <a
            href={event?.YoutubeLink}
            className="t-font-bold t-text-primary-100"
            target="_blank"
            rel="noreferrer"
          >
            <BsYoutube
              fontSize="2rem"
              className="t-text-red-500 t-cursor-pointer"
            />
          </a>
          {event?.Presentation?.fileName && (
            <a
              href={config.bucketUrl + event?.Presentation?.fileName}
              className="t-font-bold t-text-primary-100"
              target="_blank"
              rel="noreferrer"
            >
              <RiFilePpt2Fill
                fontSize="2rem"
                className="t-text-yellow-500 t-cursor-pointer"
              />
            </a>
          )}
        </Row>
        <Row>
          <Title level={4}>Event Link</Title>
        </Row>
        <Row className="t-flex t-items-center t-space-x-4">
          <a
            href={`https://www.new-ui.dev.pledgegarden.com/events/${id}/join-event`}
            className="t-font-bold t-text-primary-100"
            target="_blank"
            rel="noreferrer"
          >
            Click Here
          </a>
        </Row>
      </Col>
    </Card>
  )
}

export { Control }
