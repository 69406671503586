import React, { useEffect, useState } from 'react'
import { Skeleton, Button, Card, Col, Row, Spin } from 'antd'
import { fetchMoreData, searchRows } from 'utils'
import { config } from 'configs'
import { getNearByEvents } from 'store/eventSlice/eventActions'
import { useDispatch } from 'react-redux'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { eventActions, useEventSelector } from 'store/eventSlice/eventReducer'
import { useHistory } from 'react-router-dom'
import { ApiOutlined } from '@ant-design/icons'

import sample from 'assets/images/sample/sample1.png'
import InfiniteScroll from 'react-infinite-scroll-component'

function CampaignList({ searchText }) {
  const dispatch = useDispatch()
  const history = useHistory()

  const { location } = useAuthSelector()
  const positionData = {
    lat: location?.lat,
    lon: location?.lng,
    zipCode: location?.zipcode,
  }
  const { loading, campaigns } = useEventSelector()
  const [hasMore, setHasMore] = useState(true)
  const [current, setCurrent] = useState([])

  useEffect(() => {
    setCurrent(campaigns.slice(0, config.itemFirstLoad))
    // eslint-disable-next-line
  }, [campaigns])

  useEffect(() => {
    dispatch(getNearByEvents(positionData))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleEventSelected = (event) => {
    dispatch(eventActions.setEvent(event))
    history.push(`campaigns/${event.Id}`)
  }

  return (
    <Skeleton loading={loading} active paragraph={{ rows: 18 }}>
      <div
        className="t-flex t-flex-col t-justify-between t-h-full"
        style={{ minHeight: 'calc(100vh - 250px)' }}
      >
        <InfiniteScroll
          dataLength={searchRows(current, searchText).length}
          next={() => fetchMoreData(current, setCurrent, setHasMore, campaigns)}
          hasMore={hasMore}
          loader={
            <div className="t-flex t-justify-center t-items-center">
              <Spin />
            </div>
          }
        >
          <Row gutter={[24, 24]} className="t-h-full">
            {searchRows(current, searchText).length === 0 && (
              <div
                className="t-h-96 t-flex t-justify-center t-items-center t-w-full"
                style={{ minHeight: 'calc(100vh - 300px)' }}
              >
                <div className="t-flex t-flex-col t-items-center t-space-y-2">
                  <ApiOutlined
                    style={{ fontSize: '2rem' }}
                    className="t-text-primary-100"
                  />
                  <p>No campaigns</p>
                </div>
              </div>
            )}
            {searchRows(current, searchText).map((item, index) => (
              <Col span={24} md={8} xl={8} key={index}>
                <Card
                  bordered
                  hoverable
                  cover={
                    <img
                      alt="example"
                      className="t-w-full t-h-48 t-object-cover t-rounded-md"
                      src={
                        item?.Flyer?.fileName
                          ? config.bucketUrl + item?.Flyer?.fileName
                          : sample
                      }
                    />
                  }
                >
                  <h5 className="t-text-2xl t-text-primary-100 t-font-bold">
                    {item?.Title}
                  </h5>
                  <div className="t-flex t-justify-between">
                    <div className="card-tag t-font-thin t-text-primary-100">
                      {item?.OrganizationName}
                    </div>
                  </div>
                  <p className="t-line-clamp-2 t-text-sm">{item?.Details}</p>
                  <div className="t-flex t-justify-end t-mt-2">
                    <Button
                      type="primary"
                      size="small"
                      className="t-rounded-full"
                      onClick={() => handleEventSelected(item)}
                    >
                      See More
                    </Button>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        </InfiniteScroll>
      </div>
    </Skeleton>
  )
}

export { CampaignList }
