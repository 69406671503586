import React from 'react'
import { Row, Col, Card, Descriptions, Button, Tooltip } from 'antd'
import { useHistory } from 'react-router-dom'
import { IoIosArrowBack } from 'react-icons/io'
import { MdEdit } from 'react-icons/md'
import { AiFillEye, AiFillEyeInvisible, AiOutlineHeart } from 'react-icons/ai'
import { HiDuplicate } from 'react-icons/hi'
import { DiRequirejs } from 'react-icons/di'
import { VscTypeHierarchySub } from 'react-icons/vsc'
import { FaMoneyBillWave } from 'react-icons/fa'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { useDispatch } from 'react-redux'
import { addFavourite } from 'store/favouriteSlice/favouriteActions'
import { useServiceSelector } from 'store/serviceSlice/serviceReducer'
import {
  addService,
  updateServiceStatus,
} from 'store/serviceSlice/serviceActions'
import sample from 'assets/images/sample/sample1.png'
import { RegisteredVolunteer } from './RegisteredVolunteer'
import { FeedbackByVolunteer } from './FeedbackByVolunteer'

function ServiceView() {
  const dispatch = useDispatch()
  const history = useHistory()
  const { service } = useServiceSelector()
  const { user } = useAuthSelector()

  const handleAddFavourite = async (id) => {
    const payload = {
      ReferenceId: id,
      Username: user?.id,
      Type: 'service',
    }
    dispatch(addFavourite(payload))
    history.push('/services')
  }

  const handleDuplicate = () => {
    const newService = { ...service }
    delete newService?.id
    dispatch(addService(newService))
    history.push('/services')
  }

  const handleUpdate = () => {
    dispatch(
      updateServiceStatus(
        service?.id,
        service?.ActiveInd,
        history.push('/services'),
      ),
    )
  }

  const handleRegister = () => {
    const type = service?.ServiceSubtype
    if (type === 'Marriage' || type === 'Funeral') {
      history.push(`/register-for-service/${type}`)
    } else {
      // console.log("registered");
    }
  }

  return (
    <Row gutter={[24, 0]}>
      <Col span={24}>
        <Card
          title={[
            <div className="t-flex t-justify-between">
              <div className="t-flex t-items-center t-space-x-4">
                <Button
                  type="primary"
                  className="t-rounded-full t-flex t-items-center"
                  onClick={() => history.push('/services')}
                  size="small"
                >
                  <IoIosArrowBack />
                  Back
                </Button>
              </div>
              <div className="t-flex t-items-center t-space-x-2 t-cursor-pointer">
                <Tooltip title="Make Favorite">
                  <AiOutlineHeart
                    fontSize="1.8rem"
                    className="t-cursor-pointer"
                    onClick={() => handleAddFavourite(service.id)}
                  />
                </Tooltip>
                {service?.CreatedBy === user.email && (
                  <>
                    <Tooltip title="Edit Service">
                      <MdEdit
                        fontSize="1.8rem"
                        onClick={() =>
                          history.push(
                            `/settings/create-a-service/${service.id}`,
                          )
                        }
                      />
                    </Tooltip>
                    <Tooltip title="Duplicate Service">
                      <HiDuplicate
                        fontSize="1.8rem"
                        onClick={() => handleDuplicate()}
                      />
                    </Tooltip>
                    {service?.ActiveInd === 1 ? (
                      <Tooltip title="Make Inactive">
                        <AiFillEyeInvisible
                          fontSize="1.8rem"
                          onClick={() => handleUpdate()}
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Make Active">
                        <AiFillEye
                          fontSize="1.8rem"
                          onClick={() => handleUpdate()}
                        />
                      </Tooltip>
                    )}
                  </>
                )}
              </div>
            </div>,
          ]}
          bordered={false}
          className="header-solid card-product mb-24"
        >
          <Row gutter={[24, 0]}>
            <Col span={24} lg={12} xl={10} className="col-gallery mb-24">
              <img
                src={sample}
                alt="card-2"
                className="gallery-img shadow-3 img-contain"
              />
            </Col>
            <Col span={24} xl={12} lg={{ offset: 1 }}>
              <div className="t-mb-4">
                <h3 className="h3 t-capitalize">{service?.Title}</h3>
                <h5 className="font-semibold">{service?.Description}</h5>
                <Descriptions colon={false} className="t-my-4">
                  <Descriptions.Item
                    label={<VscTypeHierarchySub fontSize="1.5rem" />}
                    span={3}
                  >
                    {service?.LocationType}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={<FaMoneyBillWave fontSize="1.5rem" />}
                    span={3}
                  >
                    {service?.Cost}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={<DiRequirejs fontSize="1.5rem" />}
                    span={3}
                  >
                    {service?.Requirements}
                  </Descriptions.Item>
                </Descriptions>
                <div className="t-my-4 t-space-x-2">
                  <Button type="primary" onClick={() => handleRegister()}>
                    Register
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
          {service?.CreatedBy === user.email && (
            <Row gutter={[24, 0]} className="mt-24">
              <Col span={24}>
                <h6 className="t-font-bold t-text-base">Registered User</h6>
                <RegisteredVolunteer id={service?.id} />
              </Col>
              <Col span={24}>
                <h6 className="t-font-bold t-text-base">Registered User</h6>
                <FeedbackByVolunteer id={service?.id} />
              </Col>
            </Row>
          )}
        </Card>
      </Col>
    </Row>
  )
}

export { ServiceView }
