import { config } from 'configs'
import moment from 'moment'

export const formateDateTime = (date) => {
  const mydate = new Date(date)
  return moment(mydate).format('DD-MM-YYYY hh:mm A')
}

export const paginate = (array, PAGE_NUMBER) =>
  array?.slice(
    (PAGE_NUMBER - 1) * config.rowsPerPage,
    PAGE_NUMBER * config.rowsPerPage,
  )

export const searchRows = (rows, searchText) => {
  if (searchText) {
    return rows?.filter(
      ({ Name, Title }) =>
        Name?.toLowerCase().includes(searchText.toLowerCase()) ||
        Title?.toLowerCase().includes(searchText.toLowerCase()),
    )
  }
  return rows
}

export const formatAmount = (amount) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })

  return formatter.format(amount || 0)
}

export const fetchMoreData = (current, setCurrent, setHasMore, data) => {
  setTimeout(() => {
    if (current.length >= data.length) {
      setHasMore(false)
    } else {
      setCurrent(data.slice(0, current.length + config.itemPerLoad))
    }
  }, 1000)
}
