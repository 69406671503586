import Amplify, { Auth } from 'aws-amplify'
import { awsconfig } from 'configs/aws-exports'
import { message } from 'antd'
import Api from 'api'
import { authActions } from './authReducer'

Amplify.configure(awsconfig)

export const checkUserExist = (phone, goTo) => (dispatch) => {
  dispatch(authActions.setLoading(true))
  Api.get(`/users/check-phone-number?phone=${encodeURIComponent(phone)}`)
    .then(() => {
      dispatch(authActions.setUserPhone(phone))
      goTo('signup')
      dispatch(authActions.setLoading(false))
      return null
    })
    .catch((err) => {
      const data = err?.response?.data?.message
      if (data !== 'Suspended Account') {
        return dispatch(sendOtp(phone, goTo))
      }
      message.error('Account Suspended')
      dispatch(authActions.setLoading(false))
      return null
    })
}

export const sendOtp = (phone, goTo) => (dispatch) => {
  Auth.signIn(phone)
    .then((res) => {
      dispatch(authActions.setTempUser(res))
      dispatch(authActions.setUserPhone(phone))
      if (goTo) {
        goTo('otp')
      }
    })
    .catch((err) => {
      console.log('error sentOtp', err)
    })
    .finally(() => dispatch(authActions.setLoading(false)))
}

export const signIn = (user, code, navigate) => (dispatch) => {
  dispatch(authActions.setLoading(true))
  Auth?.sendCustomChallengeAnswer(user, code)
    .then(() => {
      Auth.currentSession({ bypassCache: true })
        .then((actualUser) => {
          message.success('LoggedIn Successfully')
          dispatch(authActions.setUserPhone(null))
          dispatch(authActions.setTempUser(null))
          dispatch(authActions.setToken(actualUser?.idToken?.jwtToken))
          const roles = actualUser?.accessToken?.payload['cognito:groups']
          if (
            actualUser &&
            actualUser.hasOwnProperty('accessToken') &&
            actualUser.accessToken.hasOwnProperty('payload')
          ) {
            dispatch(setUserDetail(roles, navigate))
          }
        })
        .catch((error) => console.log('error currentSession => ', error))
        .finally(() => dispatch(authActions.setLoading(false)))
    })
    .catch((error) => {
      console.log('error sendCustomChallengeAnswer => ', error)
      message.error('Incorrect OTP code. Click RESEND to recieve a new code.')
    })
    .finally(() => dispatch(authActions.setLoading(false)))
}

export const signUp = (phoneNumber, attributes, goTo) => async (dispatch) => {
  dispatch(authActions.setLoading(true))
  const data = {
    username: phoneNumber,
    password: 'AAAA1234$aaaaa',
    attributes,
  }
  Auth.signUp(data)
    .then(() => {
      dispatch(sendOtp(phoneNumber, goTo))
    })
    .catch((error) => console.log('error signUp => ', error))
    .finally(() => dispatch(authActions.setLoading(false)))
}

export const logout = (action) => async (dispatch) => {
  if (action) {
    action()
  }
  localStorage.clear()
  dispatch(authActions.resetUser(true))
}

export const getUser = async () => {
  const response = await Auth.currentAuthenticatedUser({ bypassCache: true })
  return response
}

export const setUserDetail = (roles, navigate) => (dispatch) => {
  dispatch(authActions.setLoading(true))
  Auth.currentAuthenticatedUser({ bypassCache: true })
    .then((res) => {
      const userDetail = {
        id: res.attributes.sub,
        firstname: res.attributes.given_name,
        lastname: res.attributes.family_name,
        email: res.attributes.email,
        phone: res.attributes.phone_number,
        email_verified: res.attributes.email_verified,
        phone_number_verified: res.attributes.phone_number_verified,
        roles,
      }
      dispatch(authActions.setUser(userDetail))
      dispatch(getOrganization(res.attributes.email))
      if (navigate) {
        navigate('/welcome')
      }
    })
    .finally(() => dispatch(authActions.setLoading(false)))
}

const getOrganization = (email) => (dispatch) => {
  Api.get('/org/all')
    .then((res) => {
      const orgs = res.results.filter(
        (x) => x.CreatedBy === email && !x.deleted,
      )
      const org = orgs[0]
      dispatch(authActions.setOrganization(org))
    })
    .catch((error) => console.log('error', error))
}

export const uploadFile = async (file) => {
  const data = new FormData()
  data.append('photos', file)
  const response = await Api.put('files/upload', data)
  return response
}

export const getLocation = (lat, lng) => async (dispatch) => {
  Api.put('donor/getZipCodeByPosition', { lat, lon: lng })
    .then((res) => {
      dispatch(
        authActions.setLoaction({
          data: {
            zipcode: res?.results?.zip,
            lat,
            lng,
            city: res?.results?.city,
            state: res?.results?.state,
            country: res?.results?.country,
          },
          customLocation: false,
        }),
      )
    })
    .catch((error) =>
      console.log('error put donor/getZipCodeByPosition => ', error),
    )
}

export const getAddressByZipcode = (zipCode) => async (dispatch) => {
  Api.put('donor/addressByZipCode', { zipCode })
    .then((res) => {
      dispatch(
        authActions.setLoaction({
          data: {
            zipcode: res?.results?.zip,
            lat: res?.results?.latitude,
            lng: res?.results?.longitude,
            city: res?.results?.city,
            state: res?.results?.state,
            country: res?.results?.country,
          },
          customLocation: true,
        }),
      )
      message.success('Zicode added successfully')
    })
    .catch((error) => {
      console.log('error put donor/getZipCodeByPosition => ', error)
      message.error('Zipcode not found')
    })
}

export const getPaymentAccounts = (email) => (dispatch) => {
  dispatch(authActions.setLoading(true))
  Api.get(`/stripe/payment-account-info?email=${email}`)
    .then((res) => {
      if (res.results.length === 0 || !res.results[0]?.stripeID) {
        dispatch(
          authActions.setStripeDetail({
            isConnected: false,
            id: null,
            stripeId: null,
          }),
        )
      } else {
        dispatch(
          authActions.setStripeDetail({
            isConnected: true,
            stripeId: res.results[0]?.stripeID,
            id: res.results[0]?.Id,
          }),
        )
      }
      if (res.results.length === 0 || !res.results[0]?.paypalID) {
        dispatch(
          authActions.setPaypalDetail({
            isConnected: false,
            id: null,
            paypalId: null,
          }),
        )
      } else {
        dispatch(
          authActions.setPaypalDetail({
            isConnected: true,
            paypalId: res.results[0]?.paypalID,
            id: res.results[0]?.Id,
          }),
        )
      }
      dispatch(authActions.setLoading(false))
    })
    .catch((error) => {
      console.log('error getPaymentAccounts', error)
      dispatch(authActions.setLoading(false))
    })
}

export const getGovDocs = () => (dispatch) => {
  const codeId = 100001
  dispatch(authActions.setLoading(true))
  Api.get(`/admin/codeList?id=${codeId}`)
    .then((res) => {
      dispatch(authActions.setGovDocs(res.results))
      dispatch(authActions.setLoading(false))
    })
    .catch((error) => {
      console.log('error getGovDocs =>', error)
      dispatch(authActions.setLoading(false))
    })
}

export const addDoc = (data) => (dispatch) => {
  dispatch(authActions.setLoading(true))
  Api.put('admin/addCodes', data)
    .then(() => {
      dispatch(getGovDocs())
    })
    .catch((error) => {
      console.log('error addDoc =>', error)
      dispatch(authActions.setLoading(false))
    })
}

export const updateDocStatus = (codeId, isActivate) => (dispatch) => {
  dispatch(authActions.setLoading(true))
  if (isActivate) {
    Api.get(`/admin/activeCode/${codeId}`)
      .then(() => {
        dispatch(getGovDocs())
      })
      .catch((error) => {
        console.log('error updateDocStatus =>', error)
        dispatch(authActions.setLoading(false))
      })
  } else {
    Api.get(`/admin/disableCode/${codeId}`)
      .then(() => {
        dispatch(getGovDocs())
      })
      .catch((error) => {
        console.log('error updateDocStatus =>', error)
        dispatch(authActions.setLoading(false))
      })
  }
}

export const getUserContributions = () => (dispatch) => {
  dispatch(authActions.setLoading(true))
  Api.get('/adminReports/getDonorContributionList')
    .then(async (res) => {
      dispatch(authActions.setContributions(res?.results))
      const orgResponse = await Api.get(
        '/adminReports/getYTDDonationList?type=org',
      )
      const indvResponse = await Api.get(
        `/adminReports/getYTDDonationList?type=indv`,
      )
      const ytdPledges = await Api.get(`/adminReports/getYTDPledgeList`)

      console.log('ytdPledges', ytdPledges)
      dispatch(authActions.setYTDOrg(orgResponse?.results))
      dispatch(authActions.setYTDItemized(indvResponse?.results))
      dispatch(authActions.setYTDPledges(ytdPledges?.results))
      dispatch(authActions.setLoading(false))
    })
    .catch((error) => {
      console.log('error getUserContributions =>', error)
      dispatch(authActions.setLoading(false))
    })
}

// export const getYTDDonations = (type) => (dispatch) => {
//   Api.get(`/adminReports/getYTDDonationList?type=${type}`)
//     .then((res) => {
//       if (type === "org") {
//         dispatch(authActions.setYTDOrg(res?.results));
//       }
//       if (type === "indv") {
//         dispatch(authActions.setYTDItemized(res?.results));
//       }
//     })
//     .catch((error) => {
//       console.log("error getUserContributions =>", error);
//       dispatch(authActions.setLoading(false));
//     });
// };

export const generatePDFAndEmail = (payload) => (dispatch) => {
  dispatch(authActions.setLoading(true))
  Api.put('/adminReports/generatePdfandEmail', payload)
    .then((res) => {
      console.log('resonse', res)
      dispatch(authActions.setLoading(false))
    })
    .catch((error) => {
      console.log('error getUserContributions =>', error)
      dispatch(authActions.setLoading(false))
    })
}

export const sendEmail = (email) => () => {
  // dispatch(authActions.setLoading(true));
  Api.put(`/users/send-email`, {
    email,
    pointUrl: 'https://api.dev.pledgegarden.com/',
  })
    .then((res) => {
      console.log('res', res)
    })
    .catch((err) => {
      console.log('err', err)
      // dispatch(authActions.setLoading(false));
    })
}
