import Api from 'api'
import { uploadFile } from 'store/authSlice/authActions'
import { message } from 'antd'
import { organizationActions } from './organizationReducer'

export const getCodeList = async () => {
  const codeId = 100001
  const response = await Api.get(`/admin/codeList?id=${codeId}`)
  const activeArray = response.results.filter((item) => item.ActiveInd === 1)
  const codeList = activeArray.map((item) => item.CODE_VALUE)
  return codeList
}

export const registerOrganization = async (data) => {
  let updatedData = data
  if (updatedData.W9Form) {
    const w9FormFile = await uploadFile(updatedData.W9Form)
    updatedData = {
      ...updatedData,
      W9Form: {
        fileName: w9FormFile.fileName,
        fileMimeType: w9FormFile.fileMimeType,
      },
    }
  }
  const identityFile = await uploadFile(updatedData.IdentityFile)
  updatedData = {
    ...updatedData,
    IdentityFile: {
      fileName: identityFile.fileName,
      fileMimeType: identityFile.fileMimeType,
    },
  }
  const resopnse = await Api.put('/org', updatedData)
  return resopnse
}

export const updateOrganizatoin = async (data) => {
  let updatedData = data

  if (!updatedData.IdentityFile.fileName) {
    const identityFile = await uploadFile(updatedData.IdentityFile)
    updatedData = {
      ...updatedData,
      IdentityFile: {
        fileName: identityFile.fileName,
        fileMimeType: identityFile.fileMimeType,
      },
    }
  }

  if (updatedData.W9Form && !updatedData.W9Form.fileName) {
    const w9FormFile = await uploadFile(updatedData.W9Form)
    updatedData = {
      ...updatedData,
      W9Form: {
        fileName: w9FormFile.fileName,
        fileMimeType: w9FormFile.fileMimeType,
      },
    }
  }
  const response = await Api.put('/org/updateOrg', updatedData)
  return response
}

export const getUserOrganization = async (email) => {
  const response = await Api.get('/org/all')
  const orgs = response.results.filter(
    (x) => x.CreatedBy === email && !x.deleted,
  )
  return orgs[0]
}

export const getOrganization = async (id) => {
  const response = await Api.get(`/org?id=${id}`)
  if (response.results.length > 0) {
    return response.results[0]
  }
  return false
}

export const getOrganizations = () => (dispatch) => {
  dispatch(organizationActions.setLoading(true))
  Api.get('/orgList')
    .then((res) => {
      dispatch(organizationActions.setOrganizations(res?.results))
      dispatch(organizationActions.setLoading(false))
    })
    .catch((err) => {
      console.log('Error', err)
      dispatch(organizationActions.setLoading(false))
    })
}

export const getNearbyOrganizationsByZip = (zipCode) => (dispatch) => {
  dispatch(organizationActions.setLoading(true))
  Api.put('/orgList/nearByOrgs', { zipCode })
    .then((res) => {
      dispatch(organizationActions.setOrganizations(res?.results?.data))
      dispatch(organizationActions.setLoading(false))
    })
    .catch((err) => {
      console.log('Error', err)
      dispatch(organizationActions.setLoading(false))
    })
}

export const getAllOrganizations = () => (dispatch) => {
  dispatch(organizationActions.setLoading(true))
  Api.get('/org/all')
    .then((res) => {
      dispatch(organizationActions.setOrganizations(res?.results))
      dispatch(organizationActions.setLoading(false))
    })
    .catch((err) => {
      console.log('Error', err)
      dispatch(organizationActions.setLoading(false))
    })
}

export const updateOrganizationByAdmin = (payload) => (dispatch) => {
  Api.put('/admin/update-org', payload)
    .then(() => {
      message.success('Updated Organization Successfully')
      dispatch(getAllOrganizations())
    })
    .catch((err) => {
      console.log('Error', err)
      dispatch(getAllOrganizations())
      message.success('Updated Organization Successfully')
      // message.error("Something went wrong");
    })
}

// export const getOrganizationsMarkups = (id) => (dispatch) => {
//   dispatch(organizationActions.setLoading(true));
//   Api.get(`admin/subsrciberMarkUpsByOrg/${id}`)
//     .then((res) => {
//       console.log('Res', res)
//       dispatch(organizationActions.setOrganizations(res?.results));
//       dispatch(organizationActions.setLoading(false));
//     })
//     .catch((err) => {
//       console.log('Error', err)
//       dispatch(organizationActions.setLoading(false));
//     })
// }

export const getSmsCost = (id) => () => {
  Api.get(`admin/subsrciberSMSCosting/${id}`)
    .then((res) => {
      console.log('Res', res)
    })
    .catch((err) => {
      console.log('Error', err)
    })
}

export const getEmailCost = (id) => () => {
  Api.get(`admin/subsrciberEmailCosting/${id}`)
    .then((res) => {
      console.log('Res', res)
    })
    .catch((err) => {
      console.log('Error', err)
    })
}
