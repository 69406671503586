import React, { useEffect } from 'react'
import { Card, Col, Tag, Skeleton } from 'antd'
import { MdModeEdit } from 'react-icons/md'
import { getNearByEvents } from 'store/eventSlice/eventActions'
import { useDispatch } from 'react-redux'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { useEventSelector } from 'store/eventSlice/eventReducer'
import { useHistory } from 'react-router-dom'
import { config } from 'configs'
import { BiRefresh } from 'react-icons/bi'

import sample from 'assets/images/sample/sample1.png'

function MyEvents() {
  const dispatch = useDispatch()
  const history = useHistory()
  const { loading, events } = useEventSelector()
  const { user, location } = useAuthSelector()
  const positionData = {
    lat: location?.lat,
    lon: location?.lng,
    zipCode: location?.zipcode,
  }

  const fetchData = () => {
    dispatch(getNearByEvents(positionData))
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const myevents = events.filter(
    (event) =>
      event?.CreatedBy === user.email && event?.EventType === 'Fundraiser',
  )

  return (
    <Card
      title={[
        <div className="t-flex t-justify-between t-items-center">
          <div>
            <h4 className="mb-0">My Events</h4>
            <p className="font-regular">
              Presenter Response To My Event Invites
            </p>
          </div>
          <BiRefresh
            className="t-text-primary-100 t-cursor-pointer"
            fontSize="2.5rem"
            onClick={fetchData}
          />
        </div>,
      ]}
      className="t-mb-5"
    >
      <Skeleton loading={loading} active paragraph={{ rows: 12 }}>
        <Col span={24}>
          {myevents.length === 0 && (
            <Card className="t-h-96 t-w-full t-flex t-justify-center t-items-center">
              <div>No Events</div>
            </Card>
          )}
          {myevents.map((item) => (
            <div className="t-flex t-justify-between t-items-center t-my-2 t-border t-rounded-full t-p-3 t-shadow">
              <div className="t-flex t-flex-col sm:t-flex-row t-space-x-4 t-items-center">
                <img
                  src={
                    item?.Flyer?.fileName
                      ? config.bucketUrl + item?.Flyer?.fileName
                      : sample
                  }
                  className="t-h-8 t-w-12 t-rounded-full gallery-img shadow-3 img-contain"
                  alt="card-2"
                />
                <div className="t-flex t-flex-col t-justify-center">
                  <h1>{item?.Title}</h1>
                  <h1>
                    {`Presenter : ${item?.PresenterName}( ${item?.PresenterEmail} )`}
                  </h1>
                </div>
                <div>
                  {item?.AcceptanceStatus === 'Accepted' && (
                    <Tag color="green" className="font-bold">
                      Accepted
                    </Tag>
                  )}
                  {item?.AcceptanceStatus === 'Rejected' && (
                    <Tag color="red" className="font-bold">
                      Rejected
                    </Tag>
                  )}
                  {(item?.AcceptanceStatus === 'Pending' ||
                    !item?.AcceptanceStatus) && (
                    <Tag color="gray" className="font-bold">
                      Pending
                    </Tag>
                  )}
                </div>
              </div>
              <MdModeEdit
                onClick={() =>
                  history.push(`/settings/create-event/${item?.Id}`)
                }
                fontSize="1.5rem"
                className="t-text-primary-100 t-cursor-pointer"
              />
            </div>
          ))}
        </Col>
      </Skeleton>
    </Card>
  )
}

export { MyEvents }
